import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ListItem = forwardRef(
  (
    {
      onClick,
      onDoubleClick,
      onHover,
      onOut,
      children,
      cta,
      checkbox,
      group,
      done,
      inProgress,
      active,
      cardEditable,
      cardEditableOpen,
      clickable,
      small,
      smallEditable,
      smallEditableOpen,
      editable,
      noMaxHeight,
      extraClassNames,
      draggable,
      draggableDisabled,
      isDragging,
      programs,
      disabled,
      readOnly,
      multiInput,
      withSubList,
      oneLine,
      visible,
      style,
      outfaded,
      withSupertitle,
      refactoring = false,
      dataQa,
      dataIndex
    },
    ref
  ) => {
    const handleClick = e => {
      e.stopPropagation();
      if (onClick) onClick(e);
    };

    const handleDoubleClick = e => {
      e.stopPropagation();
      if (onDoubleClick) onDoubleClick(e);
    };

    const handleHover = e => {
      e.stopPropagation();
      if (onHover) onHover(e);
    };

    const handleOut = e => {
      e.stopPropagation();
      if (onOut) onOut(e);
    };

    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        handleClick(e);
      }
    };

    return refactoring ? (
      <li
        className="r-list-item--wrapping"
        ref={ref}
        style={style}
        data-index={dataIndex}
        // {...rest}
        // TODO @Fabian: is er een reden dat de {...rest} hier wordt doorgegeven? Dit geeft een defaultProps error in de console, enige wat ik vond was data-index die wer doorgegeven.
      >
        <div
          className={classNames(
            'c-list__item o-list-bare__item',
            extraClassNames,
            {
              'c-list__item-cta': cta,
              'c-list__item-checkbox': checkbox,
              'c-list__item-disabled': disabled,
              'c-list__item-read-only': readOnly,
              'c-list__item-group': group,
              'c-list__item-done': done,
              'c-list__item-in-progress': inProgress,
              'c-list__item-active': active,
              'c-list__item-card-editable': cardEditable,
              'c-list__item-card-editable is-open': cardEditableOpen,
              'c-list__item-small': small,
              'c-list__item-small c-list__item-small-editable': smallEditable,
              'c-list__item-small c-list__item-small-editable is-open':
                smallEditableOpen,
              'c-list__item-editable': editable,
              'c-list__item-free-height': noMaxHeight,
              'c-list__item-program': programs,
              'c-list__item-draggable': draggable,
              'c-list__item-not-draggable': draggableDisabled,
              'c-list__item-multiple-inputs': multiInput,
              'c-list__item-with-sub-list': withSubList,
              'c-list__item-one-line': oneLine,
              'c-list__item-clickable': clickable,
              'c-list__item-outfaded': outfaded,
              'c-list__item-with-supertitle': withSupertitle
            }
          )}
          tabIndex={onClick || onDoubleClick ? 0 : -1}
          onClick={e => handleClick(e)}
          onDoubleClick={e => handleDoubleClick(e)}
          onMouseOver={e => handleHover(e)}
          onMouseOut={e => handleOut(e)}
          role={onClick || onDoubleClick ? 'button' : 'listitem'}
          onKeyDown={(onClick || onDoubleClick) && handleKeyDown}
          data-qa={`${dataQa}-li-button`}
        >
          {children}
        </div>
      </li>
    ) : (
      <li
        className={classNames(
          'c-list__item o-list-bare__item',
          extraClassNames,
          {
            'c-list__item-cta': cta,
            'c-list__item-checkbox': checkbox,
            'c-list__item-disabled': disabled,
            'c-list__item-read-only': readOnly,
            'c-list__item-group': group,
            'c-list__item-done': done,
            'c-list__item-in-progress': inProgress,
            'c-list__item-active': active,
            'c-list__item-card-editable': cardEditable,
            'c-list__item-card-editable is-open': cardEditableOpen,
            'c-list__item-small': small,
            'c-list__item-small c-list__item-small-editable': smallEditable,
            'c-list__item-small c-list__item-small-editable is-open':
              smallEditableOpen,
            'c-list__item-editable': editable,
            'c-list__item-free-height': noMaxHeight,
            'c-list__item-program': programs,
            'c-list__item-draggable': draggable,
            'c-list__item-not-draggable': draggableDisabled,
            'c-list__item-multiple-inputs': multiInput,
            'c-list__item-with-sub-list': withSubList,
            'c-list__item-one-line': oneLine,
            'c-list__item-clickable': clickable,
            'c-list__item-outfaded': outfaded,
            'c-list__item-with-supertitle': withSupertitle
          }
        )}
        tabIndex={onClick || onDoubleClick ? 0 : -1}
        onClick={e => handleClick(e)}
        onDoubleClick={e => handleDoubleClick(e)}
        onMouseOver={e => handleHover(e)}
        onMouseOut={e => handleOut(e)}
        role={onClick || onDoubleClick ? 'button' : 'listitem'}
        onKeyDown={(onClick || onDoubleClick) && handleKeyDown}
        data-qa={`${dataQa}-li-button`}
        data-index={dataIndex}
        ref={ref}
        style={style}
        // {...rest}
        // TODO @Fabian: is er een reden dat de {...rest} hier wordt doorgegeven? Dit geeft een defaultProps error in de console
      >
        {children}
      </li>
    );
  }
);

ListItem.propTypes = {
  checkbox: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  clickable: PropTypes.bool
};

export default ListItem;
