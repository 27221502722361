import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { createIntl, FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { downloadHanReports } from 'services/aws/session-query';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';
import slugify from 'slugify';

const MailAllHanSessionsButton = ({ session }) => {
  const intl = useIntl();
  const enIntl = createIntl({
    locale: 'en'
  });
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);

  const downloadHanSessionReports = async sessionId => {
    try {
      const query = await downloadHanReports(sessionId);
      if (query) {
        return query.data.downloadHanReports;
      }
    } catch (error) {
      if (error.message === 'no_reports_available') {
        // @ts-ignore
        uiState.showModal({
          title: intl.formatMessage(
            messages.titleSessionSKReportDownloadFailed
          ),
          message: messages.messageSessionAllReportsDownloadFailedNotAvailable,
          dismissButton: false,
          type: MODAL_TYPES.ALERT
        });
      }
      return false;
    }
  };

  const downloadAllReports = async () => {
    uiState.increasePendingRequest();
    let response = await downloadHanSessionReports(session.id);
    uiState.decreasePendingRequest();

    if (response) {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionAllReportsMailSuccess),
        message: messages.messageSessionAllReportsMailSuccess,
        dismissButton: false,
        type: MODAL_TYPES.ALERT
      });
    }
  };

  console.log('MailAllHanSessionsButton');
  console.log('user is han', user.isHanOrg);
  console.log('is ido', session.isIDo);
  console.log('is ilike', session.isILike);
  console.log('is isRecurring', session.isRecurring);

  if (user.isHanOrg && (session.isIDo || session.isILike)) return <></>;

  if (!user.isHanOrg) {
    return <></>;
  }
  if (session.isRecurring) return <></>;

  console.log('Show MailAllHanSessionsButton');

  return (
    <Button
      menu
      onClick={downloadAllReports}
      dataQa={`${slugify(
        enIntl
          .formatMessage(messages.menuItemSessionMailAllHanReports)
          .toLowerCase()
      )}-menu`}
    >
      <Icon id="send" />
      <FormattedMessage {...messages.menuItemSessionMailAllHanReports} />
    </Button>
  );
};

export default MailAllHanSessionsButton;
