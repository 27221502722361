import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { createIntl, FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { downloadSessionReport } from 'services/aws/session-query';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';
import { pollIsValidUrl } from 'utils/url';
import { openWindowWithLoader } from 'utils/browser';
import { useTestSetStore } from 'containers/pages/sessions/store/TestSetsStore';
import slugify from 'slugify';

const DownloadSessionReportButton = ({
  session,
  testDataId = null,
  options,
  isSession = false
}) => {
  const intl = useIntl();
  const enIntl = createIntl({ locale: 'en' });
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);
  const hasTestSet = useTestSetStore(state => state.hasTestSet);

  const downloadSession = async () => {
    const { benchmark, ...restOptions } = options;
    const data = {
      testDataId,
      options: restOptions
    };
    if (!session.isRehab && !session.isPrevention) {
      data.benchmarkId = benchmark;
    }
    const filenameQuery = await downloadSessionReport(data);
    if (filenameQuery) {
      return filenameQuery.data.downloadSessionReport;
    }
  };

  const downloadReport = async newWindow => {
    const openWindow = newWindow;

    try {
      uiState.increasePendingRequest();
      const url = await downloadSession(testDataId);

      if (url) {
        const response = await pollIsValidUrl(
          url.replace('&', '%2526'), // TODO Temp hack to allow & in url
          25,
          1000
        );

        if (response) {
          openWindow.location = url.replace('&', '%2526');
          openWindow.focus();

          //   setTimeout(() => {
          //     openWindow.close();
          //   }, 500);
        }
      }
    } catch (error) {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionHanReportDownloadFailed),
        message: messages.messageSessionHanReportDownloadFailed,
        dismissButton: false,
        type: MODAL_TYPES.WARNING
      });
    }

    uiState.decreasePendingRequest();
  };

  console.log('DownloadSessionReportButton');
  console.log(
    '(isSession && user.isHylyghtOrg) ||',
    isSession,
    user.isHylyghtOrg
  );
  console.log('user is han || ', user.isHanOrg);
  console.log('( !user is hylyght &&', user.isHylyghtOrg);
  console.log('(is iLike ||', session.isILike);
  console.log('is iDo ||', session.isIDo);
  console.log('is hanSisp ||', session.isHanSisp);
  console.log('(is klusce && isSession)', session.isKlusce, isSession);
  console.log('(is iAm && !isSession)', session.isIAm, !isSession);
  console.log('(is djas && !isSession)', session.isDjas, !isSession);
  console.log(')', session.isDjas, !isSession);
  console.log('! hasTestSet', hasTestSet(session.testSetIds[0]));
  console.log('is recurring', session.isRecurring);

  if (
    (isSession && user.isHylyghtOrg) ||
    user.isHanOrg ||
    (!user.isHylyghtOrg &&
      (session.isILike ||
        session.isIDo ||
        session.isHanSisp ||
        (session.isKlusce && isSession) ||
        (session.isIAm && !isSession) ||
        (session.isDjas && !isSession)))
  )
    return <></>;

  if (isSession && !hasTestSet(session.testSetIds[0])) return <></>;

  if (session.isRecurring) return <></>;

  console.log('Show DownloadSessionReportButton');

  return (
    <Button
      menu
      onClick={() => {
        const newWindow = openWindowWithLoader();
        downloadReport(newWindow);
      }}
      dataQa={`${slugify(enIntl.formatMessage(messages.menuItemSessionDownloadReport).toLowerCase())}-menu`}
    >
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionDownloadReport} />
    </Button>
  );
};

export default DownloadSessionReportButton;
