import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  withRouter,
  generatePath,
  useParams,
  useLocation,
  useHistory
} from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { createIntl, FormattedMessage, useIntl } from 'react-intl';

import {
  ROUTE_SESSIONS,
  ROUTE_SESSIONS_ENTITY_SESSION,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT
} from 'routes/RouteList';
import { Panel, PanelHeader } from 'components/panel';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelSubtitle,
  PanelTitleButton,
  PanelTitleButtonsGroup
} from 'components/panel/panel-title';
import PanelBody from 'components/panel/PanelBody';
import messages from 'messages';
import RemoveSporterFromSessionButton from 'containers/partials/buttons/RemoveSporterFromSessionButton';
import MenuWrapper from 'components/menu/MenuWrapper';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import MailReportButton from 'containers/partials/session-report-buttons/MailReportButton';
import { getAgeFromDobOnSession } from 'utils/date';
import { StoreContext } from 'index';
import { useSessionContext } from 'contexts/SessionContext';
import DownloadSessionReportButton from 'containers/partials/session-report-buttons/DownloadSessionReportButton';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import SporterTabsLegacy from 'containers/pages/sessions/SporterTabsLegacy';
import PersonTabs from 'containers/pages/sessions/PersonTabs';
import DownloadSKReportButton from 'containers/partials/session-report-buttons/DownloadSKReportButton';
import { useTestDataContext } from 'contexts/TestDataContext';
import GenerateMagicLinkButton from 'containers/partials/buttons/GenerateMagicLinkButton';
import GenerateMagicURLModal from 'containers/partials/modals/GenerateMagicURLModal';
import DownloadHanReportButton from 'containers/partials/session-report-buttons/DownloadHanReportButton';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import usePerson from 'hooks/queries/usePerson';
import Loader from 'components/loader/Loader';
import slugify from 'slugify';

const SessionPerson = () => {
  const intl = useIntl();
  const enIntl = createIntl({ locale: 'en' });
  const benchmarkRef = React.useRef(null);
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { entityId, sessionsTab, sessionId, sporterId, testDataId, programId } =
    useParams();
  const { push } = useHistory();
  let location = useLocation();
  const person = useEntityContext(s => s.person);
  const { fetchPerson, loading } = usePerson({ personId: sporterId });
  const { session, error: sessionError, options } = useSessionContext();
  const {
    testData,
    fetchTestData,
    activeDate,
    unsetAllData,
    setNewActiveDate
  } = useTestDataContext();
  const [openGenerateMagicURLModal, setOpenGenerateMagicURLModal] =
    useState(false);

  const active = useRouteMatch([
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT,
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA,
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER
  ]);

  const isSessionRootView = useRouteMatch({
    path: ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
    strict: true,
    exact: true
  });
  const isReportView = useRouteMatch({
    path: [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA
    ],
    strict: true
  });
  const isTestsView = useRouteMatch({
    path: [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA
    ],
    strict: true
  });

  useEffect(() => {
    if (!sporterId) return;
    fetchPerson(sporterId);
  }, [sporterId]);

  useEffect(() => {
    benchmarkRef.current = testData.resultBenchmarkId;
  }, [testData]);

  useEffect(() => {
    if (session?.id !== sessionId) return;

    if (
      (!testDataId && session && (isReportView || isTestsView)) ||
      isSessionRootView
    ) {
      let testData;
      if (session.isRecurring) {
        if (!activeDate) {
          const today = new Date();
          setNewActiveDate(today);
          testData = session.getTestDataByDate(sporterId, today);
        } else {
          testData = session.getTestDataByDate(sporterId, activeDate);
        }
      } else {
        testData = session.getLastTestData(sporterId);
      }
      if (testData?.id) {
        const selectedPath =
          testData.finishedPercentage > 0 && !isTestsView
            ? ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA
            : ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA;

        push(
          generatePath(selectedPath, {
            entityId,
            sessionId,
            sessionsTab,
            sporterId,
            testDataId: testData.id
          })
        );
      } else {
        if (isSessionRootView) {
          push(
            generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS, {
              entityId,
              sessionId,
              sessionsTab,
              sporterId
            })
          );
        } else {
          unsetAllData();
        }
      }
    } else if (testDataId && session && (isReportView || isTestsView)) {
      if (
        !testData.id ||
        testData.id !== testDataId ||
        (benchmarkRef.current && options.benchmark !== benchmarkRef.current)
      ) {
        fetchTestData(session.id, testDataId);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testDataId, session, location, options.benchmark]);

  const isAdviceView = useRouteMatch(
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
  );

  return (
    <>
      <Panel
        active={active.isExact}
        extraClassNames={isAdviceView ? 'u-1-of-1' : ''}
      >
        {loading && <Loader />}
        {person && (
          <>
            {sessionError && <RetryPanel />}
            {session && (
              <Fragment>
                <PanelHeader>
                  <BreadcrumbPath
                    crumbs={[
                      {
                        path: ROUTE_SESSIONS,
                        label: intl.formatMessage(messages.breadcrumbSessions)
                      },
                      {
                        path: generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
                          sessionId,
                          sessionsTab: 'sessions',
                          entityId
                        }),
                        label: intl.formatMessage(messages.breadcrumbSession, {
                          session: session.name
                        })
                      },
                      {
                        path: generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
                          sessionId,
                          sessionsTab: 'sessions',
                          entityId
                        }),
                        label: intl.formatMessage(
                          messages.breadcrumbSessionSporter
                        )
                      }
                    ]}
                  />
                  <PanelTitleWrapper>
                    <PanelTitle>
                      {person.firstname} {person.lastname}
                      {!user.isAthlete() && (
                        <PanelTitleButtonsGroup>
                          <MenuWrapper
                            trigger={
                              <PanelTitleButton
                                dataQa={slugify(
                                  `${person.firstname.toLowerCase()} ${person.lastname.toLowerCase()}`
                                )}
                              />
                            }
                          >
                            {testData && (
                              <>
                                <DownloadSessionReportButton
                                  session={session}
                                  testDataId={testData.id}
                                  options={options}
                                />
                                <DownloadSKReportButton
                                  session={session}
                                  testData={testData}
                                />
                                <DownloadHanReportButton
                                  session={session}
                                  person={person}
                                />
                                <MailReportButton
                                  menu
                                  entityId={entityId}
                                  session={session}
                                  sporter={person}
                                  testDataId={testData?.id}
                                  options={options}
                                />
                                {session.isRecurring && (
                                  <GenerateMagicLinkButton
                                    menu
                                    person={person}
                                    onClick={() =>
                                      setOpenGenerateMagicURLModal(true)
                                    }
                                  />
                                )}
                              </>
                            )}

                            <RemoveSporterFromSessionButton
                              menu
                              rootEntityId={entityId}
                              sessionId={session.id}
                              ids={[sporterId]}
                              onComplete={() =>
                                push(
                                  generatePath(
                                    ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
                                    {
                                      entityId,
                                      sessionsTab,
                                      sessionId: session.id
                                    }
                                  )
                                )
                              }
                              dataQa={`${slugify(enIntl.formatMessage(messages.menuItemSessionRemoveSporter).toLowerCase())}-menu`}
                            >
                              <FormattedMessage
                                {...messages.menuItemSessionRemoveSporter}
                              />
                            </RemoveSporterFromSessionButton>
                          </MenuWrapper>
                        </PanelTitleButtonsGroup>
                      )}
                    </PanelTitle>
                    <PanelSubtitle>
                      {session.startdate && person.birthdate && (
                        <FormattedMessage
                          {...messages.labelSessionSporterAgeAtTestDate}
                          values={{
                            age: getAgeFromDobOnSession(
                              person.birthdate,
                              session.startdate
                            )
                          }}
                        />
                      )}
                    </PanelSubtitle>
                  </PanelTitleWrapper>
                </PanelHeader>
                <PanelBody fullWidth={isAdviceView}>
                  {session?.id && !isSessionRootView && (
                    <PersonTabsView
                      session={session}
                      entityId={entityId}
                      sessionId={sessionId}
                      sporterId={sporterId}
                      programId={programId}
                      testDataId={testData?.id}
                    />
                  )}
                </PanelBody>
              </Fragment>
            )}
          </>
        )}
      </Panel>
      {openGenerateMagicURLModal && (
        <GenerateMagicURLModal
          entityId={entityId}
          person={person}
          destiny={sessionId}
          onClose={() => setOpenGenerateMagicURLModal(false)}
        />
      )}
    </>
  );
};

const PersonTabsView = ({ session, ...props }) => {
  if (session.isTestSetV2) {
    return <PersonTabs {...props} />;
  } else {
    if (session.isIDo) {
      return <PersonTabs {...props} />;
    } else {
      return <SporterTabsLegacy {...props} />;
    }
  }
};

export default withRouter(SessionPerson);
