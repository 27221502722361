import React, { useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper
} from 'components/list/list-item';
import { ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY } from 'routes/RouteList';
import AddScreeningModal from 'containers/pages/rehab/screening/AddScreeningModal';
import { getLocalDateFormat } from 'utils/date';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { TestsProvider } from 'contexts/TestsContext';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function InjuriesScreeningsCard({ entityId, groupId, medical, personInjury }) {
  const { sessionId } = useParams();
  const { push } = useHistory();
  const person = useEntityContext(s => s.person);

  console.log('person', person)

  const [modal, setModal] = useState(false);

  const onScreeningAddClickHandler = () => {
    setModal(true);
  };

  const onScreeningClickHandler = screening => {
    push(
      generatePath(
        ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
        {
          entityId,
          groupId,
          sporterId: person.id,
          medicalId: medical.id,
          injuryId: personInjury.id,
          sessionId: screening.id
        }
      )
    );
  };

  return (
    <>
      <Button
        rounded
        primary
        extraClassNames="u-margin-top-medium o-flex--align-self-end"
        onClick={() => onScreeningAddClickHandler()}
      >
        <Icon id="add-white" />
        <FormattedMessage {...messages.rehabAddScreeningButton} />
      </Button>
      <List>
        <ListBody>
          {personInjury?.screenings?.length > 0 &&
            personInjury.screenings.map(screening => (
              <ListItem
                small
                key={screening.id}
                active={screening.id === sessionId}
                onClick={() => onScreeningClickHandler(screening)}
              >
                <ListItemLabelWrapper>
                  <ListItemLabel label>{screening.name}</ListItemLabel>
                  <ListItemLabel label>
                    {getLocalDateFormat(screening.startdate)}
                  </ListItemLabel>
                </ListItemLabelWrapper>
              </ListItem>
            ))}
        </ListBody>
      </List>
      {modal && (
        <TestsProvider entityId={entityId}>
          <AddScreeningModal
            entityId={entityId}
            personInjury={personInjury}
            onClose={() => setModal(false)}
          />
        </TestsProvider>
      )}
    </>
  );
}

export default InjuriesScreeningsCard;
