import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { createIntl, FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { downloadSkReports } from 'services/aws/session-query';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';
import { useTestSetStore } from 'containers/pages/sessions/store/TestSetsStore';
import slugify from 'slugify';

const MailSKSessionsReportButton = ({ session }) => {
  const intl = useIntl();
  const enIntl = createIntl({
    locale: 'en'
  });
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);
  const hasTestSet = useTestSetStore(state => state.hasTestSet);

  const mailSkSessionReports = async sessionId => {
    try {
      const query = await downloadSkReports(sessionId, true);
      if (query) {
        return query.data.downloadSkReports;
      }
    } catch (error) {
      if (error.message === 'no_reports_available') {
        // @ts-ignore
        uiState.showModal({
          title: intl.formatMessage(
            messages.titleSessionSKReportDownloadFailed
          ),
          message: messages.messageSessionAllReportsDownloadFailedNotAvailable,
          dismissButton: false,
          type: MODAL_TYPES.ALERT
        });
      }
      return false;
    }
  };

  const mailAllReports = async () => {
    try {
      uiState.increasePendingRequest();
      const response = await mailSkSessionReports(session.id);

      if (response) {
        // @ts-ignore
        uiState.showModal({
          title: intl.formatMessage(messages.titleSessionAllReportsMailSuccess),
          message: messages.messageSessionAllReportsMailSuccess,
          dismissButton: false,
          type: MODAL_TYPES.ALERT
        });
      }
    } catch (error) {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionHanReportMailFailed),
        message: messages.messageSessionHanReportMailFailed,
        dismissButton: false,
        type: MODAL_TYPES.WARNING
      });
    }

    uiState.decreasePendingRequest();
  };

  console.log('MailSKSessionsReportButton');
  console.log('user is han', user.isHanOrg);
  console.log('is ilike', session.isILike);
  console.log('is ido', session.isIDo);
  console.log('is hanSisp', session.isHanSisp);
  console.log('is iAm', session.isIAm);
  console.log('is djas', session.isDjas);
  console.log('isRecurring', session.isRecurring);

  if (
    !(
      session.isILike ||
      session.isIDo ||
      session.isHanSisp ||
      session.isIAm ||
      session.isDjas
    )
  )
    return <></>;

  if (!hasTestSet(session.testSetIds[0])) return <></>;

  if (session.isRecurring) return <></>;

  console.log('Show MailSKSessionsReportButton');

  return (
    <Button
      menu
      onClick={async () => {
        await mailAllReports();
      }}
      dataQa={`${slugify(
        enIntl
          .formatMessage(messages.menuItemSessionMailAllHanReports)
          .toLowerCase()
      )}-menu`}
    >
      <Icon id="send" />
      <FormattedMessage {...messages.menuItemSessionMailAllHanReports} />
    </Button>
  );
};

export default MailSKSessionsReportButton;
