import { defineMessages } from 'react-intl';

// 1 - './containers/pages/settings/testsets/messages.js'
export default defineMessages({
  breadcrumbTestsets: {
    id: 'testsets.breadcrumb.testsets',
    description: 'breadcrumb Testsets',
    defaultMessage: 'Testsets'
  },
  testSetsTitle: {
    id: 'testsets.title.testsets',
    description: 'title Testsets',
    defaultMessage: 'Test sets'
  },
  testSetsSubtitle: {
    id: 'testsets.title.testsets-subtitle',
    description: 'subtitle Testsets',
    defaultMessage: 'Create and edit test set'
  },
  testSetsAddTestsetButton: {
    id: 'testsets.button.add',
    description: 'button Add Testset',
    defaultMessage: 'Add test set'
  },
  testSetsTestsetSubtitle: {
    id: 'testsets.title.testset-subtitle',
    description: 'subtitle Testset',
    defaultMessage: 'Tests for {name}'
  },
  testSetsTestsetTabTests: {
    id: 'testsets.testset.tab.tests',
    description: 'tab Testset Tests',
    defaultMessage: 'Tests'
  },
  testSetsTestsetMenuItemEditName: {
    id: 'testsets.testset.menu-item.edit-name',
    description: 'menu item Testset Edit Name',
    defaultMessage: 'Edit test set name'
  },
  testSetsTestsetMenuItemEditTests: {
    id: 'testsets.testset.menu-item.edit-tests',
    description: 'menu item Testset Edit Tests',
    defaultMessage: 'Edit test set tests'
  },
  testSetsTestsetMenuItemRemove: {
    id: 'testsets.testset.menu-item.remove',
    description: 'menu item Testset Remove',
    defaultMessage: 'Remove test set'
  },
  testSetsTestsetMenuItemCopy: {
    id: 'testsets.testset.menu-item.copy',
    description: 'menu item Testset Copy',
    defaultMessage: 'Copy test set'
  },
  testSetsTestsButtonSave: {
    id: 'testsets.testset.button.save',
    description: 'button Testset Save',
    defaultMessage: 'Save'
  },
  testSetsTestsButtonCancel: {
    id: 'testsets.testset.button.cancel',
    description: 'button Testset Cancel',
    defaultMessage: 'Cancel'
  },
  testSetsTestsSaveSuccess: {
    id: 'testsets.testset.success.save',
    description: 'Success Testset Save',
    defaultMessage: 'TestSet successfully saved'
  },
  testSetsTestsSaveError: {
    id: 'testsets.testset.error.save',
    description: 'Error Testset Save',
    defaultMessage: 'Hmm something went wrong, try it again later.'
  },

  // 2 - './containers/pages/settings/organisations/messages.js'
  breadcrumbOrganisations: {
    id: 'organisations.breadcrumb.organisations',
    description: 'breadcrumb Organisations',
    defaultMessage: 'Organisations'
  },
  organisationsTitle: {
    id: 'organisations.title.organisations',
    description: 'title Organisations',
    defaultMessage: 'Organisations'
  },
  organisationsSubtitle: {
    id: 'organisations.title.organisations-subtitle',
    description: 'subtitle Organisations',
    defaultMessage: 'Create and manage organisations'
  },
  organisationsAddOrganisationButton: {
    id: 'organisations.button.add',
    description: 'button Organisation Add ',
    defaultMessage: 'Add organisation'
  },
  organisationsTitleOrganisationDetails: {
    id: 'organisations.organisation.title.organisation-details',
    description: 'title Organisation Details',
    defaultMessage: 'Organisation details'
  },
  organisationsTitleOrganisationContactDetails: {
    id: 'organisations.organisation.title.contact-details',
    description: 'title Organisation Contact Details',
    defaultMessage: 'Contact person'
  },
  organisationsAddTitle: {
    id: 'organisations.title.add-organisation',
    description: 'title Organisation Add',
    defaultMessage: 'New organisation'
  },
  organisationsAddSubTitle: {
    id: 'organisations.title.add-organisation-subtitle',
    description: 'subtitle Organisation Add',
    defaultMessage: 'Global settings about the organisation'
  },
  organisationsMenuItemRemoveOrganisation: {
    id: 'organisations.organisation.menu-item.remove',
    description: 'menu item Organisation Remove',
    defaultMessage: 'Remove organisation'
  },
  organisationsNameLabel: {
    id: 'organisations.organisation.label.name',
    description: 'label Organisation Name',
    defaultMessage: 'Organisation name'
  },
  organisationsAddressLabel: {
    id: 'organisations.organisation.label.address',
    description: 'label Organisation Address',
    defaultMessage: 'Organisation address'
  },
  organisationsAddress2Label: {
    id: 'organisations.organisation.label.address2',
    description: 'label Organisation Address',
    defaultMessage: 'Address (cont...)'
  },
  organisationsAddress1Placeholder: {
    id: 'organisations.organisation.placeholder.address1',
    description: 'placeholder Organisation Address 1',
    defaultMessage: 'Street address, nr., ...'
  },
  organisationsAddress2Placeholder: {
    id: 'organisations.organisation.placeholder.address2',
    description: 'placeholder Organisation Address 2',
    defaultMessage: 'Unit, building, floor, ...'
  },
  organisationsTypeLabel: {
    id: 'organisations.organisation.label.contact-type',
    description: 'label Organisation Contact Type',
    defaultMessage: 'Type'
  },
  organisationsLocationLabel: {
    id: 'organisations.organisation.label.location',
    description: 'label Organisation Location',
    defaultMessage: 'Location'
  },
  organisationsCountryLabel: {
    id: 'organisations.organisation.label.country',
    description: 'label Organisation Country',
    defaultMessage: 'Country'
  },
  organisationsOrganisationTypeLabel: {
    id: 'organisations.organisation.label.organisation-type',
    description: 'label Organisation Type',
    defaultMessage: 'Organization Type'
  },
  organisationsOrganisationTypeOtherLabel: {
    id: 'organisations.organisation.label.organisation-type-other',
    description: 'label Organisation Type other',
    defaultMessage: 'Organization Type other'
  },
  organisationsZipcodeLabel: {
    id: 'organisations.organisation.label.zipcode',
    description: 'label Organisation Zipcode',
    defaultMessage: 'Zipcode'
  },
  organisationNotesLabel: {
    id: 'organisations.organisation.label.notes',
    description: 'label Organisation Notes',
    defaultMessage: 'Notes'
  },
  organisationContactTypeOtherValue: {
    id: 'organisations.organisation.label.contact-type-other',
    description: 'option Contact Type Other Value',
    defaultMessage: 'Other'
  },
  organisationContactTypeSportService: {
    id: 'organisations.organisation.label.contact-type-sportservice',
    description: 'option Contact Type Sportservice',
    defaultMessage: 'Sport service'
  },
  organisationContactTypeTeacher: {
    id: 'organisations.organisation.label.contact-type-teacher',
    description: 'option Contact Type Teacher',
    defaultMessage: 'Teacher'
  },
  organisationsLogoLabel: {
    id: 'organisations.organisation.label.logo',
    description: 'label Organisation Logo',
    defaultMessage: 'Logo'
  },
  organisationsAddLogoPlaceholder: {
    id: 'organisations.organisation.placeholder.add-logo',
    description: 'placeholder Add Logo',
    defaultMessage: 'Add a Logo'
  },

  organisationOrganisationTypeOtherValue: {
    id: 'organisations.organisation.value.organisation-type-other',
    description: 'option Organisation Type Other Value',
    defaultMessage: 'Other'
  },
  organisationOrganisationTypeCity: {
    id: 'organisations.organisation.label.organisation-type-city',
    description: 'option Organisation Type City',
    defaultMessage: 'City'
  },
  organisationOrganisationTypeCountry: {
    id: 'organisations.organisation.label.organisation-type-country',
    description: 'option Organisation Country',
    defaultMessage: 'Country'
  },
  organisationOrganisationTypeElementary: {
    id: 'organisations.organisation.label.organisation-type-elementary-school',
    description: 'option Organisation Type Elementary School',
    defaultMessage: 'Elementary'
  },
  organisationOrganisationTypeHigh: {
    id: 'organisations.organisation.label.organisation-type-high-school',
    description: 'option Organisation Type High School',
    defaultMessage: 'High'
  },
  organisationOrganisationTypeCollege: {
    id: 'organisations.organisation.label.organisation-type-college-school',
    description: 'option Organisation Type College School',
    defaultMessage: 'College'
  },
  notificationOrganisationUpdated: {
    id: 'organisations.notification.organisation-updated',
    description: 'notification Organisation Updated',
    defaultMessage: 'Organisation updated'
  },
  notificationOrganisationAdded: {
    id: 'organisations.notification.organisation-added',
    description: 'notification Organisation Added',
    defaultMessage: 'Organisation added'
  },

  // 3 - './containers/pages/settings/benchmarks/messages.js'
  breadcrumbBenchmarks: {
    id: 'benchmarks.breadcrumb.benchmarks',
    description: 'breadcrumb Benchmarks',
    defaultMessage: 'Benchmarks'
  },
  benchmarksTitle: {
    id: 'benchmarks.title.benchmarks',
    description: 'title Benchmarks',
    defaultMessage: 'Benchmarks'
  },
  benchmarksSubtitle: {
    id: 'benchmarks.title.benchmarks-subtitle',
    description: 'subtitle Benchmarks',
    defaultMessage: 'Create and edit benchmarks'
  },
  benchmarksAddBenchmarkButton: {
    id: 'benchmarks.button.add',
    description: 'button Benchmarks Add',
    defaultMessage: 'Add benchmarks'
  },
  benchmarksBenchmarkSubtitle: {
    id: 'benchmarks.title.benchmark-subtitle',
    description: 'subtitle Benchmarks',
    defaultMessage: 'Benchmarks for {name}'
  },
  benchmarksBenchmarkTabBenchmarks: {
    id: 'benchmarks.tab.benchmarks',
    description: 'tab Benchmarks',
    defaultMessage: 'Benchmarks'
  },
  benchmarksBenchmarkTestSubtitle: {
    id: 'benchmarks.title.benchmark-test-subtitle',
    description: 'subtitle Benchmark Test',
    defaultMessage: 'Benchmarks for {name}'
  },
  benchmarksBenchmarkMenuItemEdit: {
    id: 'benchmarks.benchmark.menu-item.edit',
    description: 'menu item Benchmark Edit',
    defaultMessage: 'Edit benchmark'
  },
  benchmarksBenchmarkMenuItemEditData: {
    id: 'benchmarks.benchmark.menu-item.edit-data',
    description: 'menu item Benchmark Edit Data',
    defaultMessage: 'Edit benchmark data'
  },
  benchmarksBenchmarkMenuItemCreateData: {
    id: 'benchmarks.benchmark.menu-item.create-data',
    description: 'menu item Benchmark Create Data',
    defaultMessage: 'Create benchmark data'
  },
  benchmarksBenchmarkMenuItemResetBenchmarkData: {
    id: 'benchmarks.benchmark.menu-item.reset-data',
    description: 'menu item Benchmark Reset Data',
    defaultMessage: 'Reset benchmark data'
  },
  benchmarksBenchmarkMenuItemImportBenchmarkData: {
    id: 'benchmarks.benchmark.menu-item.import-data',
    description: 'menu item Benchmark Import Data',
    defaultMessage: 'Import benchmark data'
  },
  benchmarksBenchmarkMenuItemExportBenchmarkData: {
    id: 'benchmarks.benchmark.menu-item.export-data',
    description: 'menu item Benchmark Export Data',
    defaultMessage: 'Export benchmark data'
  },
  benchmarksBenchmarkMenuItemEditName: {
    id: 'benchmarks.benchmark.menu-item.edit-name',
    description: 'menu item Benchmark Edit Name',
    defaultMessage: 'Edit benchmark name'
  },
  benchmarksBenchmarkMenuItemRemove: {
    id: 'benchmarks.benchmark.menu-item.remove',
    description: 'menu item Benchmark Remove',
    defaultMessage: 'Remove benchmark'
  },
  benchmarksBenchmarkMenuItemPublish: {
    id: 'benchmarks.benchmark.menu-item.publish',
    description: 'menu item Benchmark Publish',
    defaultMessage: 'Publish benchmark'
  },
  benchmarksBenchmarkMenuItemUnPublish: {
    id: 'benchmarks.benchmark.menu-item.unpublish',
    description: 'menu item Benchmark Unpublish',
    defaultMessage: 'Unpublish benchmark'
  },
  benchmarksBenchmarkMenuItemCopy: {
    id: 'benchmarks.benchmark.menu-item.copy',
    description: 'menu item Benchmark Copy',
    defaultMessage: 'Copy benchmark'
  },
  benchmarksBenchmarkButtonSave: {
    id: 'benchmarks.benchmark.button.save',
    description: 'button Benchmark Save',
    defaultMessage: 'Save'
  },
  benchmarksBenchmarkButtonCancel: {
    id: 'benchmarks.benchmark.button.cancel',
    description: 'button Benchmark Cancel',
    defaultMessage: 'Cancel'
  },
  benchmarksBenchmarkTestTableAgeGroup: {
    id: 'benchmarks.benchmark.test-table.label.age-group',
    description: 'label Benchmark Test Table Age Group',
    defaultMessage: 'Age group'
  },
  benchmarksBenchmarkTestTableMin: {
    id: 'benchmarks.benchmark.test-table.label.minimum',
    description: 'label Benchmark Test Table Minimum',
    defaultMessage: 'Min'
  },
  benchmarksBenchmarkTestTableMax: {
    id: 'benchmarks.benchmark.test-table.label.maximum',
    description: 'label Benchmark Test Table Maximum',
    defaultMessage: 'Max'
  },
  benchmarksBenchmarkTestTableSd: {
    id: 'benchmarks.benchmark.test-table.label.standard-deviation',
    description: 'label Benchmark Test Table Standard Deviation',
    defaultMessage: 'SD'
  },
  benchmarksBenchmarkTestTableAv: {
    id: 'benchmarks.benchmark.test-table.label.average',
    description: 'label Benchmark Test Table Average',
    defaultMessage: 'AV'
  },
  benchmarksAverageLabelWithValue: {
    id: 'benchmarks.benchmark.test-table.label.average-with-value',
    description: 'label "Average" on table',
    defaultMessage: 'Avg: {value}'
  },
  benchmarksBenchmarkTestErrorInValidAge: {
    id: 'benchmarks.benchmark.test.error.invalid-age',
    description: 'error Benchmark Test Table Ages Invalid',
    defaultMessage: 'Ages are invalid.'
  },
  benchmarksBenchmarkTestErrorOverlappingAge: {
    id: 'benchmarks.benchmark.test.error.overlapping-age',
    description: 'error Benchmark Test Table Ages Overlapping',
    defaultMessage: 'Ages are overlapping with other row(s).'
  },
  benchmarksBenchmarkTestDefaultBenchmarkInfo: {
    id: 'benchmarks.benchmark.test.message.default',
    description: 'message Benchmark Default',
    defaultMessage:
      'There is no custom benchmark data for this test. The default benchmark is active for this test. In above menu (as admin) you can create a custom table for this testitem.'
  },
  benchmarksBenchmarkDraft: {
    id: 'benchmarks.benchmark.list-item.draft',
    description: 'listitem Benchmark Draft',
    defaultMessage: 'Draft'
  },
  labelBenchmarkAge: {
    id: 'benchmarks.benchmark.label.age',
    description: 'label Benchmark Age (table cell)',
    defaultMessage: 'Age'
  },
  labelUnit: {
    id: 'benchmarks.benchmark.label.unit',
    description: 'label Number of Units e.g. 65 kg',
    defaultMessage:
      '{count} {unit, select, yr {yr} kg {kg} cm {cm} mins {mins} other {{unit}}}'
  },
  globalUnitsMinutes: {
    id: 'global.units.minutes',
    description:
      'label for the word "mins", as short for minutes, following the value/number of minutes',
    defaultMessage: '{minutes} mins'
  },
  titleBenchmarkMaturityEstimations: {
    id: 'benchmarks.title.benchmark-maturity',
    description: 'title Benchmark Maturity Estimations',
    defaultMessage: 'Maturity Estimations'
  },
  // 4 - './containers/pages/settings/users/messages.js'
  breadcrumbUsers: {
    id: 'users.breadcrumb.users',
    description: 'breadcrumb Users',
    defaultMessage: 'Users'
  },
  titleUsers: {
    id: 'users.title.users',
    description: 'title Users',
    defaultMessage: 'Users'
  },
  subtitleUsers: {
    id: 'users.title.users-subtitle',
    description: 'subtitle Users',
    defaultMessage: 'Create and manage users'
  },
  buttonUsersAddUser: {
    id: 'users.button.add',
    description: 'button User Add',
    defaultMessage: 'Add user'
  },
  titleUserPersonalDetails: {
    id: 'users.title.personal-details',
    description: 'title User Personal Details',
    defaultMessage: 'Personal details'
  },
  labelUserEmail: {
    id: 'users.user.label.email',
    description: 'label User Email',
    defaultMessage: 'E-mail Address'
  },
  labelUserFirstname: {
    id: 'users.user.label.firstname',
    description: 'label User Firstname',
    defaultMessage: 'Firstname'
  },
  labelUserLastname: {
    id: 'users.user.label.lastname',
    description: 'label User Lastname',
    defaultMessage: 'Lastname'
  },
  labelUserLanguage: {
    id: 'users.user.label.language',
    description: 'label User Language',
    defaultMessage: 'Language'
  },
  labelUserResetPassword: {
    id: 'users.user.label.reset-password',
    description: 'label User Reset Password',
    defaultMessage: 'Reset password'
  },
  buttonUserResetPassword: {
    id: 'users.user.button.reset-password',
    description: 'button Reset Password',
    defaultMessage: 'Request new password'
  },
  messageUserEmailReset: {
    id: 'users.user.message.email-reset',
    description: 'message User Email Reset',
    defaultMessage: 'A reset email is sent'
  },
  labelUserRemoveLogin: {
    id: 'users.user.label.remove-login',
    description: 'label User Remove login',
    defaultMessage: 'Deactivate Account'
  },
  buttonUserRemoveLogin: {
    id: 'users.user.button.remove-login',
    description: 'button Reset Password',
    defaultMessage: 'Remove login'
  },
  messageUserLoginRemoved: {
    id: 'users.user.message.login-removed',
    description: 'message User Login removed',
    defaultMessage: 'The login of the user is removed'
  },
  labelUserInvite: {
    id: 'users.user.label.invite',
    description: 'label User Invite',
    defaultMessage: 'Invite user'
  },
  buttonUserInvite: {
    id: 'users.user.button.invite',
    description: 'button User Invite',
    defaultMessage: 'Send invitation'
  },
  messageUserInvited: {
    id: 'users.user.message.invited',
    description: 'message User Invited',
    defaultMessage: 'The user is invited'
  },
  messageUsersInvited: {
    id: 'users.users.message.invited',
    description: 'message Users Invited',
    defaultMessage: 'The users are invited'
  },
  buttonUserSave: {
    id: 'users.user.button.save',
    description: 'button User Save',
    defaultMessage: 'Save'
  },
  tabUserGlobal: {
    id: 'users.user.tab.global',
    description: 'tab User Global',
    defaultMessage: 'Global'
  },
  tabUserPermissions: {
    id: 'users.user.tab.permissions',
    description: 'tab User Permissions',
    defaultMessage: 'Permissions'
  },
  optionUserRoleAdmin: {
    id: 'users.user.label.role-admin',
    description: 'option User Role Admin',
    defaultMessage: 'Manage users'
  },
  optionUserRoleSubAdmin: {
    id: 'users.user.label.role-sub-admin',
    description: 'option User Role SubAdmin',
    defaultMessage: 'Subadmin'
  },
  optionUserRoleTester: {
    id: 'users.user.label.role-tester',
    description: 'option User Role Tester',
    defaultMessage: 'Take tests'
  },
  optionUserRoleNone: {
    id: 'users.user.label.role-none',
    description: 'option User Role None',
    defaultMessage: 'None'
  },
  menuItemUserRemoveUser: {
    id: 'users.user.menu-item.remove-user',
    description: 'menu item User Remove',
    defaultMessage: 'Remove user'
  },
  titleUserConfirmRemovePermission: {
    id: 'users.user.message.remove-permission-title',
    description: 'title User Confirm Remove Permission',
    defaultMessage: 'Remove permission'
  },
  messageUserConfirmRemovePermission: {
    id: 'users.user.message.remove-permission-message',
    description: 'subtitle User Confirm Remove Permission',
    defaultMessage: 'Are you sure you want to delete this permission?'
  },
  buttonUserSavePermission: {
    id: 'users.user.permission.button.save',
    description: 'button User Permission Save',
    defaultMessage: 'Save'
  },
  buttonUserDiscardPermission: {
    id: 'users.user.permission.button.discard',
    description: 'button User Permission Discard',
    defaultMessage: 'Discard'
  },
  labelUserPermissionIs: {
    id: 'users.user.permission.label.is',
    description: 'label User Permission Is',
    defaultMessage: 'is'
  },
  labelUserPermissionFor: {
    id: 'users.user.permission.label.for',
    description: 'label User Permission For',
    defaultMessage: 'for'
  },

  // 5 - './containers/pages/settings/global/messages.js'
  subtitleGlobalSettings: {
    id: 'settings.global-settings.subtitle',
    description: 'subtitle Organisation Global Settings',
    defaultMessage: 'Global settings about your organisation'
  },

  // 6 - './containers/pages/settings/messages.js'
  breadcrumbSettings: {
    id: 'settings.breadcrumb.settings',
    description: 'breadcrumb Settings',
    defaultMessage: 'Settings'
  },
  settingsTitle: {
    id: 'settings.title.settings',
    description: 'title Settings',
    defaultMessage: 'Settings'
  },
  settingsUserListItem: {
    id: 'settings.list-item.users',
    description: 'listItem Settings Users',
    defaultMessage: 'Users'
  },
  settingsOrganisationsListItem: {
    id: 'settings.list-item.organisations',
    description: 'listItem Settings Organisations',
    defaultMessage: 'Organisations'
  },
  settingsBenchmarksListItem: {
    id: 'settings.list-item.benchmarks',
    description: 'listItem Settings Benchmarks',
    defaultMessage: 'Benchmarks'
  },
  settingsTestSetsListItem: {
    id: 'settings.list-item.testsets',
    description: 'listItem Settings Testsets',
    defaultMessage: 'Test sets'
  },
  settingsGlobalListItem: {
    id: 'settings.list-item.global',
    description: 'listItem Settings Global',
    defaultMessage: 'Global settings'
  },
  usersEmptyPanel: {
    id: 'settings.message.empty-panel',
    description: 'message Users Empty Panel',
    defaultMessage:
      'Please select one of the options on the left to view the details...'
  },
  noAccessEmptyPanel: {
    id: 'global.message.empty-panel',
    description: 'message Empty Panel',
    defaultMessage:
      'Please select another page on the left to continue using the application...'
  },
  personAdded: {
    id: 'athletes.athlete.message.athlete-added',
    description: 'message Athlete Added',
    defaultMessage: 'The person is added'
  },
  personUpdated: {
    id: 'athletes.athlete.message.athlete-updated',
    description: 'message Athlete Updated',
    defaultMessage: 'The person is updated'
  },

  // 7 - './containers/pages/prevention/messages.js'
  breadcrumbEditPrevention: {
    id: 'prevention.breadcrumb.edit-prevention',
    description: 'breadcrumb Prevention Edit',
    defaultMessage: 'Edit prevention'
  },
  breadcrumbEditTemplate: {
    id: 'prevention.breadcrumb.edit-template',
    description: 'breadcrumb Prevention Template Edit',
    defaultMessage: 'Edit template'
  },
  testListEmpty: {
    id: 'prevention.message.empty-test-list',
    description: 'message Prevention Empty Test List',
    defaultMessage: "Filter results don't match any test"
  },
  preventionMenuItemRemoveTemplate: {
    id: 'prevention.menu-item.remove-template',
    description: 'menu item Prevention Remove Template',
    defaultMessage: 'Remove template'
  },
  preventionMenuItemUseTemplate: {
    id: 'prevention.menu-item.use-template',
    description:
      'Menu item on Prevention-Edit, It creates a new session using the same test(set)s',
    defaultMessage: 'Create a new Session with the same tests'
  },
  preventionTalentButton: {
    id: 'prevention.button.talent',
    description: 'button Prevention Talent',
    defaultMessage: 'Custom'
  },
  preventionButton: {
    id: 'prevention.button.prevention',
    description: 'button Prevention',
    defaultMessage: 'Prevention'
  },
  preventionModalAddTitle: {
    id: 'prevention.title.add-prevention',
    description: 'title Prevention Add',
    defaultMessage:
      'Add a <span>{type}</span> prevention session {sessionExists, select, true {{isTemplate, select, true {from the template} other {from the session}} <span>{session}</span>} other {}}'
  },
  preventionModalAddNewTitle: {
    id: 'prevention.title.add-new-prevention',
    description: 'title Add New Prevention Session',
    defaultMessage: 'Add a new prevention session'
  },
  preventionTabGlobal: {
    id: 'prevention.tab.global',
    description: 'tab Prevention Global',
    defaultMessage: 'Global'
  },
  preventionTabSporters: {
    id: 'prevention.tab.athletes',
    description: 'tab Prevention Athletes',
    defaultMessage: 'Athletes'
  },
  preventionAddExtraTestsButton: {
    id: 'prevention.button.add-tests',
    description: 'button Prevention Add Tests',
    defaultMessage: 'Add extra tests'
  },
  preventionAddExtraTestsModalTitle: {
    id: 'prevention.add-tests.title',
    description: 'title Prevention Add Tests',
    defaultMessage: 'Add extra tests'
  },
  preventionTemplateEditSessionTitle: {
    id: 'prevention.template.title.edit-session',
    description: 'title Prevention Template Edit Session',
    defaultMessage: 'Edit {testSetExists, select, true {{testSet}} other {}} '
  },
  preventionTemplateTabGlobal: {
    id: 'prevention.template.tab.global',
    description: 'tab Prevention Template Global',
    defaultMessage: 'Global'
  },
  preventionTypeSelect: {
    id: 'prevention.label.prevention-type',
    description: 'select Prevention Type',
    defaultMessage: 'Type'
  },
  preventionTypeSelectPlaceholder: {
    id: 'prevention.placeholder.prevention-type',
    description: 'select Prevention Type placeholder',
    defaultMessage: 'Select a type'
  },
  preventionBasicOption: {
    id: 'prevention.label.prevention-type-basic-scan',
    description: 'option Prevention Type Basic Scan',
    defaultMessage: 'Basic Scan'
  },
  preventionFullOption: {
    id: 'prevention.label.prevention-type-full-scan',
    description: 'option Prevention Type Full Scan',
    defaultMessage: 'Full Scan'
  },
  sportSelectNoTypeMessage: {
    id: 'prevention.message.sport.no-type',
    description: 'Message shown when no prevention type is selected',
    defaultMessage: 'Select a type first'
  },
  sportSelect: {
    id: 'prevention.label.sport',
    description: 'select Sport',
    defaultMessage: 'Sport'
  },
  sportSelectPlaceholder: {
    id: 'prevention.Placeholder.sport',
    description: 'select Sport Placeholder',
    defaultMessage: 'Select a sport'
  },
  yearSelect: {
    id: 'athletes.groups.label.year',
    description: 'label for year input',
    defaultMessage: 'Year'
  },
  yearPerGroupSelect: {
    id: 'athletes.groups.label.year-groups',
    description: 'label for year select',
    defaultMessage: 'Filter groups by year'
  },
  preventionBenchmarkSelect: {
    id: 'prevention.label.benchmark',
    description: 'select Prevention Benchmark',
    defaultMessage: 'Benchmark'
  },
  preventionBenchmarkValue: {
    id: 'prevention.label.benchmark-value',
    description: 'value Prevention Benchmark',
    defaultMessage: 'HyLyght base'
  },
  preventionSelectedTestsTitle: {
    id: 'prevention.title.selected-tests',
    description: 'title Prevention Selected Tests',
    defaultMessage: 'Selected Tests'
  },
  preventionUnselectedTestsTitle: {
    id: 'prevention.title.unselected-tests',
    description: 'title Prevention Unselected Tests',
    defaultMessage: 'Unselected Tests'
  },
  preventionEstimatedTimeLabel: {
    id: 'prevention.label.estimated-time',
    description: 'label Prevention Estimated Time',
    defaultMessage: 'Estimated Time'
  },
  preventionSelectAllTestsButton: {
    id: 'prevention.button.select-all-tests',
    description: 'button Prevention Select All Tests',
    defaultMessage: 'Select all tests'
  },
  preventionUnselectAllTestsButton: {
    id: 'prevention.button.unselect-all-tests',
    description: 'button Prevention Unselect All Tests',
    defaultMessage: 'Unselect all tests'
  },
  preventionInjuriesTitle: {
    id: 'prevention.title.injuries',
    description: 'title Prevention Injuries',
    defaultMessage: 'Injuries'
  },
  preventionListInjuriesTitle: {
    id: 'prevention.title.list-injuries',
    description: 'title Prevention List Injuries',
    defaultMessage: 'Specific Screenings for {sport}'
  },
  preventionTrainingProgramTitle: {
    id: 'prevention.title.training-program',
    description: 'title Prevention Training Program',
    defaultMessage: 'Training Program'
  },
  preventionTrainingFilter: {
    id: 'prevention.title.training-filter',
    description: 'title Prevention Training Filter',
    defaultMessage: 'Filter'
  },
  preventionTrainingSelectExercises: {
    id: 'prevention.training.label.select-exercises',
    description: 'select Prevention Training Exercises',
    defaultMessage: 'Select exercises by'
  },
  preventionTrainingResultsOption: {
    id: 'prevention.training.label.select-exercises-results',
    description: 'option Prevention Training Results',
    defaultMessage: 'Results'
  },
  preventionTrainingExercisesOption: {
    id: 'prevention.training.label.select-exercises-exercises',
    description: 'option Prevention Training Exercises',
    defaultMessage: 'Exercises'
  },
  titlePreventionDetailExtras: {
    id: 'prevention.title.extras',
    description: 'title Prevention Detail Extras',
    defaultMessage: 'Type Session, Sport & Benchmark'
  },
  // 8 - './containers/pages/auth/messages.js'
  loginTitle: {
    id: 'login.title.login',
    description: 'title Login',
    defaultMessage: 'Welcome,'
  },
  loginMessageSportamundi: {
    id: 'login.message.login',
    description: 'message Login',
    defaultMessage: 'Login to your SportaMundi account...'
  },
  loginMessageHylyght: {
    id: 'login.message.hylyght',
    description: 'message Login Hylyght',
    defaultMessage: 'Login to your Hylyght account...'
  },
  loginMessageFundis: {
    id: 'login.message.fundis',
    description: 'message Login Fundis',
    defaultMessage: 'Login to your Fundis account...'
  },
  loginMessage: {
    id: 'login.message.default',
    description: 'message Login default',
    defaultMessage: 'Login to your account...'
  },
  loginUsername: {
    id: 'login.label.email',
    description: 'label Login Email',
    defaultMessage: 'E-mail address'
  },
  loginPassword: {
    id: 'login.label.password',
    description: 'label Login Password',
    defaultMessage: 'Password'
  },
  loginForgotPasswordButton: {
    id: 'login.button.forgot-password',
    description: 'button Login Forgot Password',
    defaultMessage: 'Forgot password?'
  },
  loginForgotPasswordTitle: {
    id: 'login.title.forgot-password',
    description: 'title Login Forgot Password',
    defaultMessage: 'Forgot your password?!'
  },
  loginForgotPasswordSubTitle: {
    id: 'login.title.forgot-password-subtitle',
    description: 'subtitle Login Forgot Password',
    defaultMessage: 'Receive a e-mail for password reset'
  },
  loginForgotPasswordSendEmail: {
    id: 'login.title.forgot-password-message',
    description: 'message Login Forgot Password Email Sent',
    defaultMessage: 'E-mail sent to: {email}'
  },
  loginForgotPasswordMessage: {
    id: 'login.title.forgot-passowrd-long-message',
    description: 'message Login Forgot Passord',
    defaultMessage:
      "You should have received a new (temporary) password in your mailbox. When you log in for the first time, we will ask you to change it: keep in mind that a strong password will keep your organization's data safe."
  },
  loginCancelButton: {
    id: 'login.button.cancel',
    description: 'button Login Cancel',
    defaultMessage: 'Cancel'
  },
  loginResetPasswordButton: {
    id: 'login.button.reset-password',
    description: 'button Login Reset Password',
    defaultMessage: 'Reset password'
  },
  loginSubmitButton: {
    id: 'login.button.submit',
    description: 'button Login Submit',
    defaultMessage: 'Login'
  },
  signupTitle: {
    id: 'login.title.signup',
    description: 'title Signup',
    defaultMessage: 'Hi {name}'
  },
  signupMessage: {
    id: 'login.message.signup',
    description: 'message Signup',
    defaultMessage: 'Create a new password to log in to your account'
  },
  signupPasswordLabel: {
    id: 'login.signup.label.password',
    description: 'label Signup Password',
    defaultMessage: 'Password'
  },
  signupPasswordRepeatLabel: {
    id: 'login.signup.label.repeat-password',
    description: 'label Signup Confirm Password',
    defaultMessage: 'Comfirm password'
  },
  signupSubmitButton: {
    id: 'login.signup.button.submit',
    description: 'button Signup Submit',
    defaultMessage: 'Create'
  },
  validPassword: {
    id: 'login.message.valid-password',
    description: 'message Signup Password Valid',
    defaultMessage: 'Password is valid!'
  },
  errorPasswordNotEqual: {
    id: 'login.error.password-not-equal',
    description: 'error Signup Password Not Equal',
    defaultMessage: 'Password is not equal.'
  },
  errorPasswordEmpty: {
    id: 'login.error.password-empty',
    description: 'error Signup Password Not Filled In',
    defaultMessage: 'Password is not filled in.'
  },
  errorPasswordTooShort: {
    id: 'login.error.password-too-short',
    description: 'error Signup Password Too Short',
    defaultMessage: 'Password is too short.'
  },
  errorPasswordNoAlphabeticalChar: {
    id: 'login.error.password-no-alphabetical-character',
    description: 'error Signup Password at least 1 alphabetical character',
    defaultMessage: 'Password must contain at least 1 alphabetical character.'
  },
  errorPasswordNoNumericalChar: {
    id: 'login.error.password-no-numerical-character',
    description: 'error Signup Password at least 1 numerical character',
    defaultMessage: 'Password must contain at least 1 numerical character.'
  },
  errorSomethingWentWrong: {
    id: 'login.error.something-went-wrong',
    description: 'error Something Went Wrong',
    defaultMessage: 'Something went wrong!'
  },
  errorNoAccessTitle: {
    id: 'login.error.no-access.title',
    description: 'title error Login No Access',
    defaultMessage: 'Something went wrong!'
  },
  errorNoAccessMessage: {
    id: 'login.error.no-access.message',
    description: 'subtitle error Login No Access',
    defaultMessage: "You don't have access, Please contact the Administrator"
  },

  // 9 - './containers/pages/growthprediction/messages.js'
  growthPredictionBackButton: {
    id: 'growth-prediction.button.back',
    description: 'button Growth Prediction Back',
    defaultMessage: 'Back'
  },
  growthPredictionCancelButton: {
    id: 'growth-prediction.button.cancel',
    description: 'button Growth Prediction Cancel',
    defaultMessage: 'Cancel'
  },
  growthPredictionViewButton: {
    id: 'growth-prediction.button.view',
    description: 'button Growth Prediction View',
    defaultMessage: 'View'
  },
  growthPredictionListItemSportersCount: {
    id: 'growth-prediction.list-item.athletes-count',
    description: 'title Growth Prediction Athletes Count',
    defaultMessage: '{count} athletes'
  },
  growthPredictionSubChoiceModalTitle: {
    id: 'growth-prediction.title.subchoice',
    description: 'title Growth Prediction SubChoice',
    defaultMessage: 'Make a selection'
  },
  growthPredictionSubGroupChoiceModalSubtitle: {
    id: 'growth-prediction.title.subchoice-subtitle-group',
    description: 'subtitle Growth Prediction Select Group',
    defaultMessage: "Select a group to view it's statistics."
  },
  growthPredictionSubSporterChoiceModalSubtitle: {
    id: 'growth-prediction.title.subchoice-subtitle-athlete',
    description: 'subtitle Growth Prediction Select Athletes',
    defaultMessage: "Select a athlete to view it's statistics."
  },
  growthPredictionSubSessionChoiceModalSubtitle: {
    id: 'growth-prediction.title.subchoice-subtitle-session',
    description: 'subtitle Growth Prediction Select Session',
    defaultMessage: "Select a session to view it's statistics."
  },
  growthPredictionChoiceModalTitle: {
    id: 'growth-prediction.title.choice',
    description: 'title Growth Prediction Choice',
    defaultMessage: 'Make a selection'
  },
  growthPredictionChoiceModalSubTitle: {
    id: 'growth-prediction.title.choice-subtitle',
    description: 'subtitle Growth Prediction Choice',
    defaultMessage: 'What would you like to see?'
  },
  growthPredictionChoiceModalGroupsTitleButton: {
    id: 'growth-prediction.title.choice-groups-title',
    description: 'button Growth Prediction Choice Groups Title',
    defaultMessage: 'Groups'
  },
  growthPredictionChoiceModalGroupsDescriptionButton: {
    id: 'growth-prediction.title.choice-groups-description',
    description: 'button Growth Prediction Choice Groups Description',
    defaultMessage: 'See statistics for groups of athletes'
  },
  growthPredictionChoiceModalSportersTitleButton: {
    id: 'growth-prediction.title.choice-athletes-title',
    description: 'button Growth Prediction Choice Athletes Title',
    defaultMessage: 'Athletes'
  },
  growthPredictionChoiceModalSportersDescriptionButton: {
    id: 'growth-prediction.title.choice-athletes-description',
    description: 'button Growth Prediction Choice Athletes Description',
    defaultMessage: 'See statistics for individual athletes'
  },
  growthPredictionChoiceModalSessionsTitleButton: {
    id: 'growth-prediction.title.choice-sessions-title',
    description: 'button Growth Prediction Choice Session Title',
    defaultMessage: 'Sessions'
  },
  growthPredictionChoiceModalSessionsDescriptionButton: {
    id: 'growth-prediction.title.choice-sessions-description',
    description: 'button Growth Prediction Choice Session Description',
    defaultMessage: 'See statistics for different sessions'
  },
  growthPredictionBreadcrumbGroups: {
    id: 'growth-prediction.breadcrumb.groups',
    description: 'breadcrumb Growth Prediction Groups',
    defaultMessage: 'Groups'
  },
  growthPredictionGroupPageSubtitle: {
    id: 'growth-prediction.title.group-page-subtitle',
    description: 'subtitle Growth Prediction Group',
    defaultMessage: 'All statistics for {name}'
  },
  growthPredictionSettingsPackage: {
    id: 'growth-prediction.settings.label.package',
    description: 'label Growth Prediction Settings Package',
    defaultMessage: 'Package'
  },
  growthPredictionSettingsType: {
    id: 'growth-prediction.settings.label.type',
    description: 'label Growth Prediction Settings Type',
    defaultMessage: 'Type'
  },
  growthPredictionSettingsTypeAverage: {
    id: 'growth-prediction.settings.label.type-average',
    description: 'option Growth Prediction Type Average',
    defaultMessage: 'Average'
  },
  growthPredictionAlertMessageEmptyGroup: {
    id: 'growth-prediction.alert.message.empty-group',
    description: 'message Growth Prediction Empty Group',
    defaultMessage:
      'It seems like you have selected an empty group, please select a group with athletes or add athletes to this group through the groups page.'
  },
  growthPredictionAlertButtonGoToSporters: {
    id: 'growth-prediction.alert.button.go-to-athletes',
    description: 'button Growth Prediction Go to Athletes',
    defaultMessage: 'Go to athletes'
  },
  growthPredictionAlertButtonGoBack: {
    id: 'growth-prediction.alert.button.go-back',
    description: 'button Growth Prediction Back',
    defaultMessage: 'Back'
  },
  growthPredictionMenuItemExportGrowthPrediction: {
    id: 'growth-prediction.menu-item.export-growth-prediction',
    description: 'menu item Growth Prediction Export GrowthPrediction',
    defaultMessage: 'Export GrowthPrediction'
  },
  growthPredictionYear: {
    id: 'growth-prediction.label.year',
    description: 'label Growth Prediction Year',
    defaultMessage: 'year'
  },
  growthPredictionHeightVelocity: {
    id: 'growth-prediction.label.prediction-height-velocity',
    description: 'label Growth Prediction Height Velocity',
    defaultMessage: 'Height velocity'
  },
  growthPredictionWeightVelocity: {
    id: 'growth-prediction.label.prediction-weight-velocity',
    description: 'label Growth Prediction Weight Velocity',
    defaultMessage: 'Weight velocity'
  },
  growthPredictionAdultHeight: {
    id: 'growth-prediction.label.prediction-adult-height',
    description: 'label Growth Prediction Adult Height',
    defaultMessage: '% Predicted Adult Height (K-R)'
  },
  growthPredictionTableWarning: {
    id: 'growth-prediction.message.growth-prediction-old',
    description: 'warning Growth Prediction Old Date',
    defaultMessage: 'The data is older than 10 weeks.'
  },
  // 10 - './containers/pages/sessions/messages.js'
  sessionsEmptyPanel: {
    id: 'sessions.message.empty-panel',
    description: 'message Sessions Empty Panel',
    defaultMessage:
      'Please select one of the options on the left to view the details...'
  },
  breadcrumbSessions: {
    id: 'sessions.breadcrumb.sessions',
    description: 'breadcrumb Sessions',
    defaultMessage: 'Sessions'
  },
  breadcrumbSessionSporter: {
    id: 'sessions.breadcrumb.session-athlete',
    description: 'breadcrumb Sessions Athlete',
    defaultMessage: 'Athlete'
  },
  breadcrumbSession: {
    id: 'sessions.breadcrumb.session',
    description: 'breadcrumb Session',
    defaultMessage: 'Session: {session}'
  },
  breadcrumbSessionReport: {
    id: 'sessions.breadcrumb.session-report',
    description: 'breadcrumb Session Report',
    defaultMessage: 'Report: {session}'
  },
  titleSessions: {
    id: 'sessions.title.sessions',
    description: 'title Sessions',
    defaultMessage: 'Sessions'
  },
  subtitleSessions: {
    id: 'sessions.title.sessions-subtitle',
    description: 'subtitle Sessions',
    defaultMessage: `All available sessions within {name}`
  },
  subtitleAthleteSessions: {
    id: 'sessions.subtitle.athlete',
    description: 'subtitle Athlete Sessions',
    defaultMessage: `All available sessions and medical data for your account`
  },
  subtitleSapSession: {
    id: 'sessions.title.sap-session-subtitle',
    description: 'subtitle Sap Session for Person',
    defaultMessage: '{person} sap'
  },
  tabSessionsSessions: {
    id: 'sessions.tab.sessions',
    description: 'tab Sessions Sessions',
    defaultMessage: 'Sessions'
  },
  tabSessionsTemplates: {
    id: 'sessions.tab.templates',
    description: 'tab Sessions Templates',
    defaultMessage: 'Templates'
  },
  buttonSessionsAddSession: {
    id: 'sessions.button.add-session',
    description: 'button Session Add Session',
    defaultMessage: 'New Session'
  },
  buttonSessionsAddTemplate: {
    id: 'sessions.button.add-template',
    description: 'button Session Add Template',
    defaultMessage: 'New Template'
  },
  tabSessionDetail: {
    id: 'sessions.session.tab.detail',
    description: 'tab Session Detail',
    defaultMessage: 'Details'
  },
  tabSessionGlobal: {
    id: 'sessions.session.tab.global',
    description: 'tab Session Global',
    defaultMessage: 'Global'
  },
  tabSessionSporters: {
    id: 'sessions.session.tab.athletes',
    description: 'tab Session Athletes',
    defaultMessage: 'Athletes'
  },
  tabSessionSporterScan: {
    id: 'sessions.session.athlete.tab.scan',
    description: 'tab Session Athlete Scan',
    defaultMessage: 'Scan'
  },
  tabSessionSporterReport: {
    id: 'sessions.session.athlete.tab.report',
    description: 'tab Session Athlete Report',
    defaultMessage: 'Report'
  },
  tabSessionSporterTests: {
    id: 'sessions.session.athlete.tab.tests',
    description: 'tab Session Athlete Testdata',
    defaultMessage: 'Data'
  },
  tabSessionSporterAdvice: {
    id: 'sessions.session.athlete.tab.advice',
    description: 'tab Session Athlete Advice',
    defaultMessage: 'Progam'
  },
  badgeSessionSporterAdvice: {
    id: 'sessions.session.athlete.badge.advice',
    description: 'badge Session Athlete Advice',
    defaultMessage: 'NEW'
  },
  badgeSessionEnded: {
    id: 'sessions.session.badge.ended',
    description: 'badge Session finalized',
    defaultMessage: 'Ended'
  },
  titleSessionsNewSession: {
    id: 'sessions.title.new-session',
    description: 'title Session Add Session',
    defaultMessage: 'New session'
  },
  buttonSessionAddSporters: {
    id: 'sessions.session.button.add-athletes',
    description: 'button Session Add Athletes',
    defaultMessage: 'Add athletes'
  },
  labelSessionNoTests: {
    id: 'sessions.session.label.no-tests-completed',
    description: 'label Session No Tests completed',
    defaultMessage: 'No tests completed yet...'
  },
  labelSessionNoSporters: {
    id: 'sessions.session.label.no-athletes',
    description: 'label Session No Athletes added yet',
    defaultMessage: 'No athletes added yet...'
  },
  labelSessionInProgress: {
    id: 'sessions.session.label.session-in-progress',
    description: 'label Session In Progress',
    defaultMessage: 'Session in progress: {percentage}%'
  },
  labelSessionPleaseAddSporters: {
    id: 'sessions.session.label.add-athletes',
    description: 'label Session Add Athletes',
    defaultMessage: `Please add athletes to this session in the tab 'Athletes'`
  },
  sublabelSessionNoSporters: {
    id: 'sessions.session.label.no-athletes-sub-label',
    description: 'sublabel No Athletes added yet',
    defaultMessage: `When added then you'll find them in the Athlete's tab`
  },
  buttonSessionStartSession: {
    id: 'sessions.session.button.start-session',
    description: 'button Session Start Session',
    defaultMessage: 'Start session'
  },
  buttonSessionContinueSession: {
    id: 'sessions.session.button.continue-session',
    description: 'button Session Continue Session',
    defaultMessage: 'Continue session'
  },
  menuItemSessionOpenTestApp: {
    id: 'sessions.session.menu-item.open-test-app',
    description: 'menu item Session Open Test App',
    defaultMessage: 'Open test app'
  },
  menuItemSessionCopyUrl: {
    id: 'sessions.session.menu-item.copy-url',
    description: 'menu item Session Copy Url',
    defaultMessage: 'Copy url...'
  },
  menuItemSessionFinishSession: {
    id: 'sessions.session.menu-item.finish-session',
    description: 'menu item Session Finish Session',
    defaultMessage: 'Finish session...'
  },
  menuItemSessionRemoveSporters: {
    id: 'sessions.session.menu-item.remove-athletes',
    description: 'menu item Session Remove Athletes from Session',
    defaultMessage: 'Remove selected athletes from session'
  },
  menuItemSessionRemoveSporter: {
    id: 'sessions.session.menu-item.remove-athlete',
    description: 'menu item Session Remove Athlete from Session',
    defaultMessage: 'Remove athlete from session'
  },
  menuItemSessionRemoveSession: {
    id: 'sessions.session.menu-item.remove-session',
    description: 'menu item Session Remove Session',
    defaultMessage: 'Remove session'
  },
  titleSessionDetails: {
    id: 'sessions.title.session-details',
    description: 'title Session Details',
    defaultMessage: 'Session details'
  },
  labelSessionName: {
    id: 'sessions.session.label.name',
    description: 'label Session Name',
    defaultMessage: 'Session name'
  },
  labelSessionDate: {
    id: 'sessions.session.label.date',
    description: 'label Session Date',
    defaultMessage: 'Date'
  },
  labelSessionLocation: {
    id: 'sessions.session.label.location',
    description: 'label Session Location',
    defaultMessage: 'Location'
  },
  labelSessionBenchmark: {
    id: 'sessions.session.label.benchmark',
    description: 'label Session Benchmark',
    defaultMessage: 'Benchmark'
  },
  labelSessionNotes: {
    id: 'sessions.session.label.notes',
    description: 'label Session Notes',
    defaultMessage: 'Notes'
  },
  labelSessionTestSetType: {
    id: 'sessions.session.label.testset-type',
    description: 'label Session Testset Type',
    defaultMessage: 'test set type'
  },
  optionSessionSelectTestSetType: {
    id: 'sessions.session.label.testset-type-select',
    description: 'option Session Select Testset Type',
    defaultMessage: 'Select a test set type'
  },
  labelSessionScanner: {
    id: 'sessions.session.label.scanner',
    description: 'label Session Scanner',
    defaultMessage: 'Scanner'
  },
  titleSessionAssignUsers: {
    id: 'sessions.session.title.assign-users',
    description: 'title Session Assign Users',
    defaultMessage: 'Add athletes'
  },
  labelSessionSporterAge: {
    id: 'sessions.session.label.athlete-age',
    description: 'label Session Athlete Age',
    defaultMessage: '{age} years'
  },
  labelSessionSporterAgeAtTestDate: {
    id: 'sessions.session.label.athlete-age-at-testdate',
    description: 'label Session Athlete Age at testdate',
    defaultMessage: 'Age at test date: {age} years'
  },
  menuItemSessionExportSession: {
    id: 'sessions.session.menu-item.export-session',
    description: 'menu item Session Export Session',
    defaultMessage: 'Export session'
  },
  menuItemSessionExportSessions: {
    id: 'sessions.session.menu-item.export-sessions',
    description: 'menu item Session Export Sessions',
    defaultMessage: 'Export sessions'
  },
  menuItemSessionCopyUrls: {
    id: 'sessions.session.menu-item.copy-test-urls',
    description: 'menu item Session Copy URLs',
    defaultMessage: 'Copy Test URLs...'
  },
  menuItemSessionOverviewCopyUrl: {
    id: 'sessions.session.menu-item.copy-session-url',
    description: 'menu item Session Overview Copy URL',
    defaultMessage: 'Copy session URL...'
  },
  menuItemSessionDownloadAllReports: {
    id: 'sessions.session.menu-item.download-all-reports',
    description: 'menu item Session Download All Reports',
    defaultMessage: 'Download all reports'
  },
  menuItemSessionDownloadForm: {
    id: 'sessions.session.menu-item.download-form',
    description: 'menu item Session Download Form',
    defaultMessage: 'Download form'
  },
  menuItemSessionDownloadReport: {
    id: 'sessions.session.menu-item.download-report',
    description: 'menu item Session Download Report',
    defaultMessage: 'Download report'
  },
  menuItemSessionDownloadHanReport: {
    id: 'sessions.session.menu-item.download-han-report',
    description: 'menu item Session Download HAN Report',
    defaultMessage: 'Download orientation report'
  },
  menuItemSessionMailAllHanReports: {
    id: 'sessions.session.menu-item.mail-all-han-reports',
    description: 'menu item Session Mail all HAN Reports',
    defaultMessage: 'Mail me all orientation reports'
  },
  menuItemSessionMailReport: {
    id: 'sessions.session.menu-item.mail-report',
    description: 'menu item Session Mail Report',
    defaultMessage: 'Mail report'
  },
  menuItemSessionMailReportDisabledEmail: {
    id: 'sessions.session.menu-item.mail-report-disabled-no-email',
    description: 'menu item Session Mail Report disabled when no email',
    defaultMessage: 'Mail report (active when person has an email)'
  },
  menuItemSessionMailReportDisabledTestData: {
    id: 'sessions.session.menu-item.mail-report-disabled-no-testdata',
    description: 'menu item Session Mail Report disabled when no testdata',
    defaultMessage: 'Mail report (active when there is test data)'
  },
  menuItemSessionMailAllReports: {
    id: 'sessions.session.menu-item.mail-all-reports',
    description: 'menu item Session Mail All Reports',
    defaultMessage: 'Mail reports'
  },
  menuItemSessionMailAllReportsToPersons: {
    id: 'sessions.session.menu-item.mail-all-reports-to-persons',
    description: 'menu item Session Mail All Reports to persons',
    defaultMessage: 'Mail reports to persons'
  },
  titleSessionAllReportsMailSuccess: {
    id: 'sessions.session.message.all-reports-mailed.title',
    description: 'title Session All Reports Mailed',
    defaultMessage: 'Zip download sent!'
  },
  messageSessionAllReportsMailSuccess: {
    id: 'sessions.session.message.all-reports-mailed.message',
    description: 'message Session All Reports Mailed',
    defaultMessage: 'Zip download is successfully sent to your mailbox.'
  },
  messageSessionAllReportsDownloadFailed: {
    id: 'sessions.session.message.all-reports-download-failed.message',
    description: 'message Session All Reports Download Failed',
    defaultMessage: 'Try it again later.'
  },
  messageSessionAllReportsDownloadFailedNotAvailable: {
    id: 'sessions.session.message.all-reports-download-failed.message-not-available',
    description:
      'message Session All Reports Download Failed when there are no reports available',
    defaultMessage: 'There are no reports available yet.'
  },
  titleSessionSKReportDownloadFailed: {
    id: 'sessions.session.message.report-download-failed.title',
    description: 'title Session SportKompas Report Download Failed',
    defaultMessage: 'Download report failed'
  },
  messageSessionSKReportDownloadFailed: {
    id: 'sessions.session.message.report-download-failed.message',
    description: 'message Session SportKompas Report Download Failed',
    defaultMessage: 'Try it again later.'
  },
  titleSessionHanReportDownloadFailed: {
    id: 'sessions.session.message.han-report-download-failed.title',
    description: 'title Session HAN Report Download Failed',
    defaultMessage: 'Download report failed'
  },
  messageSessionHanReportDownloadFailed: {
    id: 'sessions.session.message.han-report-download-failed.message',
    description: 'message Session HAN Report Download Failed',
    defaultMessage: 'Try it again later.'
  },
  titleSessionHanReportDownloadGradeFailed: {
    id: 'sessions.session.message.han-report-download-grade-failed.title',
    description: 'title Session HAN Report Download Grade Failed',
    defaultMessage: 'Download rapport failed'
  },
  messageSessionHanReportDownloadGradeFailed: {
    id: 'sessions.session.message.han-report-download-grade-failed.message',
    description: 'message Session HAN Report Download Grade Failed',
    defaultMessage: 'Try it again later.'
  },
  titleSessionFormDownloadFailed: {
    id: 'sessions.session.message.session-form-download-failed.title',
    description: 'title Session Form Download Failed',
    defaultMessage: 'Form download failed'
  },
  messageSessionFormDownloadFailed: {
    id: 'sessions.session.message.session-form-download-failed.message',
    description: 'message Session Form Download Failed',
    defaultMessage: 'Try it again later.'
  },
  messageSessionSaved: {
    id: 'sessions.session.message.session-saved',
    description: 'Message Session saved',
    defaultMessage: 'Session is saved'
  },
  messageTestdataSaved: {
    id: 'sessions.session.message.testdata-saved',
    description: 'Message Testdata saved',
    defaultMessage: 'Test data is saved'
  },
  messageTestdataSaveError: {
    id: 'sessions.session.message.testdata-save-error',
    description: 'Message when Testdata is not saved',
    defaultMessage: 'There was an error. Test data is not saved.'
  },

  // 11 - './containers/pages/exercises/messages.js'
  breadcrumbOverview: {
    id: 'exercises.breadcrumb.overview',
    description: 'breadcrumb Exercises Overview',
    defaultMessage: 'Overview'
  },
  breadcrumbExercises: {
    id: 'exercises.breadcrumb.exercises',
    description: 'breadcrumb Exercises',
    defaultMessage: 'Exercises'
  },
  breadcrumbExercise: {
    id: 'exercises.breadcrumb.exercise',
    description: 'breadcrumb Exercise',
    defaultMessage: 'Exercise'
  },
  breadcrumbTemplates: {
    id: 'exercises.breadcrumb.templates',
    description: 'breadcrumb Templates',
    defaultMessage: 'Templates'
  },
  breadcrumbTemplate: {
    id: 'exercises.breadcrumb.template',
    description: 'breadcrumb Template',
    defaultMessage: 'Template'
  },
  breadcrumbPrograms: {
    id: 'exercises.breadcrumb.programs',
    description: 'breadcrumb Programs',
    defaultMessage: 'Programs'
  },
  breadcrumbProgram: {
    id: 'exercises.breadcrumb.program',
    description: 'breadcrumb Program',
    defaultMessage: 'Program'
  },
  buttonCancel: {
    id: 'global.button.cancel',
    description: 'Button Cancel',
    defaultMessage: 'Cancel'
  },
  buttonSave: {
    id: 'exercises.button.save',
    description: 'button Save',
    defaultMessage: 'Save'
  },
  buttonBack: {
    id: 'global.button.back',
    description: 'button Back',
    defaultMessage: 'Back'
  },
  buttonClose: {
    id: 'global.button.close',
    description: 'button Close',
    defaultMessage: 'Close'
  },
  buttonSubmit: {
    id: 'exercises.button.submit',
    description: 'button Submit',
    defaultMessage: 'Submit'
  },
  buttonView: {
    id: 'exercises.button.view',
    description: 'button View',
    defaultMessage: 'View'
  },
  buttonPreview: {
    id: 'exercises.button.preview',
    description: 'button Preview',
    defaultMessage: 'Preview'
  },
  buttonRemove: {
    id: 'exercises.button.remove',
    description: 'button Remove',
    defaultMessage: 'Remove'
  },
  labelTooltipRemove: {
    id: 'exercises.label.tooltip-remove',
    description: 'label tooltip Remove',
    defaultMessage: 'Remove'
  },
  buttonAddCue: {
    id: 'exercises.button.add-cue',
    description: 'button Add Cue',
    defaultMessage: 'Add a Cue'
  },
  buttonAddUser: {
    id: 'exercises.button.add-user',
    description: 'button Add User',
    defaultMessage: 'Add User'
  },
  buttonShowAllFeedback: {
    id: 'exercises.button.show-all-feedback',
    description: 'button Show All Feedback',
    defaultMessage: 'Show all feedback ({count})'
  },
  buttonShowLessFeedback: {
    id: 'exercises.button.show-less-feedback',
    description: 'button Show Less Feedback',
    defaultMessage: 'Show less feedback'
  },
  tabFeedback: {
    id: 'exercises.tab.feedback',
    description: 'tab Feedback',
    defaultMessage: 'Feedback'
  },
  subtitleFeedback: {
    id: 'exercises.title.feedback-subtitle',
    description: 'subtitle Feedback',
    defaultMessage:
      '{count, plural, =1 {# feedback item} other {# feedback items}}'
  },
  buttonAddFeedback: {
    id: 'exercises.button.add-feedback',
    description: 'button Add Feedback',
    defaultMessage: 'Add new Feedback'
  },
  tabExercises: {
    id: 'exercises.tab.exercises',
    description: 'tab Exercises',
    defaultMessage: 'Exercises'
  },
  details: {
    id: 'exercises.program.tab.details',
    description: 'tab Program Details',
    defaultMessage: 'Details'
  },
  athletesTab: {
    id: 'exercises.program.tab.athletes',
    description: 'tab Program Athletes',
    defaultMessage: 'Athletes'
  },
  programs: {
    id: 'exercises.title.programs',
    description: 'title Programs',
    defaultMessage: 'Programs'
  },
  programsSubtitle: {
    id: 'exercises.title.programs-subtitle',
    description: 'subtitle Programs',
    defaultMessage: 'All available programs within {name}'
  },
  programDetailSelectAthletes: {
    id: 'exercises.program-detail.title.select-athletes',
    description: 'title Program Detail Athletes',
    defaultMessage: 'Select Athletes'
  },
  programDetailSelectExercises: {
    id: 'exercises.program-detail.title.select-exercises',
    description: 'title Program Detail Exercises',
    defaultMessage: 'Select Exercises'
  },
  programDetailTitle: {
    id: 'exercises.program-detail.title.program-details',
    description: 'title Program Details',
    defaultMessage: 'Program details'
  },
  programLabelName: {
    id: 'exercises.program.label.name',
    description: 'label Program Name',
    defaultMessage: 'Program Name'
  },
  programPlaceholderName: {
    id: 'exercises.program.label.name-placeholder',
    description: 'placeholder Program Name',
    defaultMessage: 'Name'
  },
  programLabelStartDate: {
    id: 'exercises.program.label.start-date',
    description: 'label Program Start Date',
    defaultMessage: 'Starting Date'
  },
  programPlaceholderStartDate: {
    id: 'exercises.program.label.start-date-placeholder',
    description: 'placeholder Program Start Date',
    defaultMessage: 'Start Date'
  },
  programLabelWeeks: {
    id: 'exercises.program.label.weeks',
    description: 'label Program Weeks',
    defaultMessage: '# Weeks'
  },
  programPlaceholderWeeks: {
    id: 'exercises.program.label.weeks-placeholder',
    description: 'placeholder Program Weeks',
    defaultMessage: 'Weeks'
  },
  programLabelFeedback: {
    id: 'exercises.program.label.feedback',
    description: 'label Program Feedback',
    defaultMessage: 'Feedback'
  },
  programLabelTimesDone: {
    id: 'exercises.program.label.times-done',
    description: 'label Program Times Done',
    defaultMessage:
      '{timesDone, plural, one {<b>1</b> time} other {<b>{timesDone}</b> times}} done'
  },
  programDetailAthletesTitle: {
    id: 'exercises.program-detail.title.athletes',
    description: 'title Program Detail Athletes',
    defaultMessage: 'Scheduled Athletes'
  },
  programAthletesEmpty: {
    id: 'exercises.program.message.athletes-empty',
    description: 'label Program Athletes Empty',
    defaultMessage: 'Please select an athlete to check their feedback'
  },
  programAthletesEmptyFeedback: {
    id: 'exercises.program.message.athletes-empty-feedback',
    description: 'label Program Athletes Empty Feedback',
    defaultMessage: "The athlete didn't add any feedback yet"
  },
  programDetailAthletesEmpty: {
    id: 'exercises.program-detail.message.athletes-empty',
    description: 'label Program Detail Athletes Empty',
    defaultMessage: 'Please add one or more athletes to your program...'
  },
  programSaved: {
    id: 'exercises.program.message.saved',
    description: 'message Program Saved',
    defaultMessage: 'Program is saved'
  },
  exercisesTitle: {
    id: 'exercises.title.exercises',
    description: 'title Exercises',
    defaultMessage: 'Exercises'
  },
  exerciseSubtitleAll: {
    id: 'exercises.subtitle.all-exercises',
    description:
      'Subtitle for the panel Exercises, when the tab All Exercises is selected',
    defaultMessage: 'All available exercises'
  },
  exerciseSubtitleMine: {
    id: 'exercises.subtitle.my-exercises',
    description:
      'Subtitle for the panel Exercises, when the tab My Exercises is selected',
    defaultMessage: 'The exercises you added yourself'
  },
  exercisesDetail: {
    id: 'exercises.detail.exercises',
    description: 'title Exercises detail',
    defaultMessage: 'Detail'
  },
  templates: {
    id: 'exercises.title.templates',
    description: 'title Templates',
    defaultMessage: 'Templates'
  },
  templatesDetail: {
    id: 'exercises.title.template-details',
    description: 'title Template Details',
    defaultMessage: 'Template details'
  },
  templateCreated: {
    id: 'exercises.template.message.added',
    description: 'message Template Added',
    defaultMessage: 'Template is created'
  },
  templateSaved: {
    id: 'exercises.template.message.saved',
    description: 'message Template Saved',
    defaultMessage: 'Template is saved'
  },
  templateTabExercises: {
    id: 'exercises.template.tab.exercises',
    description: 'tab Template Exercises',
    defaultMessage: 'Exercises'
  },
  exercisesAddExerciseButton: {
    id: 'exercises.button.add-exercise',
    description: 'button Exercises Add Exercise',
    defaultMessage: 'Create Exercise'
  },
  exercisesAddExercisesButton: {
    id: 'exercises.button.add-exercises',
    description: 'button Exercises Add Exercises',
    defaultMessage: 'Add Exercises'
  },
  exercisesAddTemplateButton: {
    id: 'exercises.button.add-template',
    description: 'button Exercises Add Template',
    defaultMessage: 'Add Template'
  },
  exercisesAddProgramButton: {
    id: 'exercises.button.add-program',
    description: 'button Exercises Add Program',
    defaultMessage: 'Add Program'
  },
  exercisesAddProgramTitle: {
    id: 'exercises.title.add-program',
    description: 'title Exercises Add Program',
    defaultMessage:
      'Add a new Program {fromTemplate, select, true { from template} other {}}'
  },
  exercisesEmptyPanel: {
    id: 'exercises.message.empty-panel',
    description: 'message Exercises Empty Panel',
    defaultMessage:
      'Please select one of the options on the left to view the details...'
  },
  exercisesVideoTooltipMessage: {
    id: 'exercises.message.video-tooltip',
    description: 'message tooltip Exercises Video',
    defaultMessage: "Click (on the list item) to see the exercise's details."
  },
  exercisesEmptyPanelInThis: {
    id: 'exercises.message.empty-panel-in-this',
    description: 'message Exercises Empty in This',
    defaultMessage: 'Please add one or more exercises to "{this}"'
  },
  exercisesAddExerciseTitle: {
    id: 'exercises.title.add-exercise',
    description: 'title Exercises Add Exercise',
    defaultMessage: 'New exercise'
  },
  exerciseTab: {
    id: 'exercises.tab.exercise',
    description: 'Exercise tab',
    defaultMessage: 'Exercise info'
  },
  exerciseInfoTab: {
    id: 'exercises.tab.exerciseInfo',
    description: 'Exercise info tab',
    defaultMessage: 'Exercise info'
  },
  exercisesTabMyExercises: {
    id: 'exercises.tab.my-exercises',
    description: 'tab My Exercises',
    defaultMessage: 'My Exercises'
  },
  exercisesTabAllExercises: {
    id: 'exercises.tab.all-exercises',
    description: 'tab All Exercises',
    defaultMessage: 'All Exercises'
  },
  exercisesRemoveExerciseButton: {
    id: 'exercises.button.remove-exercise',
    description: 'button Exercise Remove',
    defaultMessage: 'Remove exercise'
  },
  exercisesInTemplate: {
    id: 'exercises.add-program.title.exercises-in-template',
    description: 'title Exercises in Template',
    defaultMessage: 'Exercises in Template'
  },
  addTemplateTitle: {
    id: 'exercises.title.add-template',
    description: 'title Template Add',
    defaultMessage:
      'Add a new Template {fromProgram, select, true { from program} other {}}'
  },
  templateNameLabel: {
    id: 'exercises.template.label.name',
    description: 'label Template Name',
    defaultMessage: 'Template Name'
  },
  templateNamePlaceholder: {
    id: 'exercises.template.label.name-placeholder',
    description: 'placeholder Template Name',
    defaultMessage: 'Add a template name'
  },
  templateEmptyExercises: {
    id: 'exercises.template.message.empty-exercises',
    description: 'message Template Empty Exercises',
    defaultMessage: 'Please add some exercises first...'
  },
  templateEmptyExercisesAndCantAdd: {
    id: 'exercises.template.message.empty-exercises-cant-add',
    description:
      "message Template Empty Exercises when user can't add Exercises",
    defaultMessage: 'There are no exercises in this collection, yet...'
  },
  exercisesSaveTemplateAsProgramButton: {
    id: 'exercises.template.button.save-template-as-program',
    description: 'button Exercises Save as Program',
    defaultMessage: 'Save as Program'
  },
  exercisesEditTemplateButton: {
    id: 'exercises.template.button.edit-template',
    description: 'button Exercises Edit Template',
    defaultMessage: 'Edit template'
  },
  sortTemplateButton: {
    id: 'exercises.template.sort.button.enable',
    description: 'Label for sort button for template menu',
    defaultMessage: 'Rearrange order'
  },
  saveSortTemplateButton: {
    id: 'exercises.template.sort.button.save',
    description: 'Label for sort submit button for template',
    defaultMessage: 'Submit sorting'
  },
  exercisesProgramsButtonShowAll: {
    id: 'exercises.programs.button.show-all',
    description: 'button Exercises Show All Programs',
    defaultMessage: 'Show all programs'
  },
  exercisesProgramsButtonShowLess: {
    id: 'exercises.programs.button.show-less',
    description: 'button Exercises Show Less Programs',
    defaultMessage: 'Show less programs'
  },
  exercisesLabelLevelSubtitle: {
    id: 'exercises.title.level-subtitle',
    description: 'subtitle Exercises Level',
    defaultMessage: 'Level {level}'
  },
  exercisesAssignUserSearchPlaceholder: {
    id: 'exercises.assign-user.label.search-placeholder',
    description: 'placeholder Exercises Assign User Search',
    defaultMessage: 'Search athletes'
  },
  exercisesAssignGroupsLabel: {
    id: 'exercises.label.assign-groups',
    description: 'label Exercises Assign Groups',
    defaultMessage: 'Groups'
  },
  exercisesAssignSportersLabel: {
    id: 'exercises.label.assign-athletes',
    description: 'label Exercises Assign Athletes',
    defaultMessage: 'Athletes'
  },
  exercisesAssignUserButtonBack: {
    id: 'exercises.assign-user.button.back',
    description: 'button Exercises Assign User Back',
    defaultMessage: 'Back'
  },
  exercisesProgramStatusDateEnded: {
    id: 'exercises.message.progam-status-date-ended',
    description: 'label Exercises Program Status Date Ended',
    defaultMessage: 'Ended'
  },
  exercisesProgramStatusDateInProgress: {
    id: 'exercises.message.progam-status-date-in-progress',
    description: 'label Exercises Program Status Date In Progress',
    defaultMessage: 'Ends in {time}'
  },
  exercisesProgramSelectTemplatesLabel: {
    id: 'exercises.program.label.select-templates',
    description: 'label Exercises Program Select Template',
    defaultMessage: 'Select Template'
  },
  exercisesProgramTagsLabel: {
    id: 'exercises.program.label.program-tags',
    description: 'label Exercises Program Filter Tags',
    defaultMessage: 'Filter by Tags'
  },
  exercisesProgramFilterNameLabel: {
    id: 'exercises.program.label.filter-name',
    description: 'label Exercises Program Filter Name',
    defaultMessage: 'Filter by name'
  },
  exercisesProgramTagsNoOption: {
    id: 'exercises.program.label.program-tags-no-more-tags',
    description: 'option Exercises Program NoTags',
    defaultMessage: 'There are no more tags'
  },
  exercisesProgramTagsPlaceHolder: {
    id: 'exercises.program.label.program-tags-placeholder',
    description: 'placeholder Exercises Program Select Tags',
    defaultMessage: 'select one or more tags'
  },
  exercisesRemoveProgramButton: {
    id: 'exercises.button.remove-program',
    description: 'button Exercises Program Remove',
    defaultMessage: 'Remove program'
  },
  exercisesCreateTemplateButton: {
    id: 'exercises.button.create-template',
    description: 'button Exercises Create Template',
    defaultMessage: 'Create template'
  },
  buttonCreateProgram: {
    id: 'exercises.button.create-program',
    description: 'button Exercises Create Program',
    defaultMessage: 'Create program'
  },
  titleNewProgram: {
    id: 'exercises.title.new-program-title',
    description: 'title Exercises New Program for Person',
    defaultMessage: 'New program {person}'
  },
  exercisesProgramSubTitle: {
    id: 'exercises.title.program-subtitle',
    description: 'subtitle Exercises Program',
    defaultMessage:
      'Start date: {startdate} - duration: {duration, plural, one {1 week} other {{duration} weeks}}'
  },
  exercisesListEmpty: {
    id: 'exercises.message.empty-exercises-list',
    description: 'message Exercises List Empty',
    defaultMessage:
      "Filter results don't match any exercise or there are any exercises added yet"
  },
  exercisesListEmptyResult: {
    id: 'exercises.message.empty-exercises-list-result',
    description: 'message Exercises List Empty filter result',
    defaultMessage: "Filter results don't match any exercise"
  },
  templateListEmpty: {
    id: 'exercises.message.empty-template-list',
    description: 'message Template List Empty',
    defaultMessage:
      "Filter results don't match any template or no templates are available"
  },

  // 12 - './containers/pages/benchmarks/messages.js'
  benchmarksBackButton: {
    id: 'benchmarks.button.back',
    description: 'button Benchmarks Back',
    defaultMessage: 'Back'
  },
  benchmarksHelpLabel: {
    id: 'benchmarks.label.help',
    description: 'label Benchmarks Help',
    defaultMessage: 'What do the colours mean?'
  },
  benchmarksHelpValue1Label: {
    id: 'benchmarks.label.help-value-1',
    description: 'label Benchmarks Help Value 1',
    defaultMessage: 'Very low'
  },
  benchmarksHelpValue2Label: {
    id: 'benchmarks.label.help-value-2',
    description: 'label Benchmarks Help Value 2',
    defaultMessage: 'Low'
  },
  benchmarksHelpValue3Label: {
    id: 'benchmarks.label.help-value-3',
    description: 'label Benchmarks Help Value 3',
    defaultMessage: 'Normal'
  },
  benchmarksHelpValue4Label: {
    id: 'benchmarks.label.help-value-4',
    description: 'label Benchmarks Help Value 4',
    defaultMessage: 'High'
  },
  benchmarksHelpValue5Label: {
    id: 'benchmarks.label.help-value-5',
    description: 'label Benchmarks Help Value 5',
    defaultMessage: 'Very high'
  },
  benchmarksCancelButton: {
    id: 'global.button.cancel',
    description: 'Button Cancel',
    defaultMessage: 'Cancel'
  },
  benchmarksViewButton: {
    id: 'benchmarks.button.view',
    description: 'button Benchmarks View',
    defaultMessage: 'View'
  },
  benchmarksListItemSportersCount: {
    id: 'benchmarks.list-item.athletes-count',
    description: 'list item Benchmarks Athletes Count',
    defaultMessage: '{count} athletes'
  },
  benchmarksSubChoiceModalTitle: {
    id: 'benchmarks.title.subchoice',
    description: 'title Benchmarks SubChoice',
    defaultMessage: 'Make a selection'
  },
  benchmarksSubGroupChoiceModalSubtitle: {
    id: 'benchmarks.title.subchoice-subtitle-group',
    description: 'subtitle Benchmarks SubGroup',
    defaultMessage: "Select a group to view it's statistics."
  },
  benchmarksSubSporterChoiceModalSubtitle: {
    id: 'benchmarks.title.subchoice-subtitle-athlete',
    description: 'subtitle Benchmarks SubSporter',
    defaultMessage: "Select a athlete to view it's statistics."
  },
  benchmarksSubSessionChoiceModalSubtitle: {
    id: 'benchmarks.title.subchoice-subtitle-session',
    description: 'subtitle Benchmarks SubSession',
    defaultMessage: "Select a session to view it's statistics."
  },
  benchmarksChoiceModalTitle: {
    id: 'benchmarks.title.choice',
    description: 'title Benchmarks Choice',
    defaultMessage: 'Make a selection'
  },
  benchmarksChoiceModalSubTitle: {
    id: 'benchmarks.title.choice-subtitle',
    description: 'subtitle Benchmarks Choice',
    defaultMessage: 'What would you like to see?'
  },
  benchmarksChoiceModalGroupsTitleButton: {
    id: 'benchmarks.title.choice-group-title',
    description: 'button Benchmarks Choice Groups',
    defaultMessage: 'Groups'
  },
  benchmarksChoiceModalGroupsDescriptionButton: {
    id: 'benchmarks.title.choice-group-description',
    description: 'button Benchmarks Choice Groups Description',
    defaultMessage: 'See statistics for groups of athletes'
  },
  benchmarksChoiceModalSportersTitleButton: {
    id: 'benchmarks.title.choice-athletes-title',
    description: 'button Benchmarks Choice Athletes',
    defaultMessage: 'Athletes'
  },
  benchmarksChoiceModalSportersDescriptionButton: {
    id: 'benchmarks.title.choice-athletes-description',
    description: 'button Benchmarks Choice Athletes Description',
    defaultMessage: 'See statistics for individual athletes'
  },
  benchmarksChoiceModalSessionsTitleButton: {
    id: 'benchmarks.title.choice-sessions-title',
    description: 'button Benchmarks Choice Sessions',
    defaultMessage: 'Sessions'
  },
  benchmarksChoiceModalSessionsDescriptionButton: {
    id: 'benchmarks.title.choice-sessions-description',
    description: 'button Benchmarks Choice Sessions Description',
    defaultMessage: 'See statistics for different sessions'
  },
  benchmarksBreadcrumbGroups: {
    id: 'benchmarks.breadcrumb.groups',
    description: 'breadcrumb Benchmarks Groups',
    defaultMessage: 'Groups'
  },
  benchmarksGroupPageSubtitle: {
    id: 'benchmarks.title.group-page-subtitle',
    description: 'subtitle Benchmarks Group',
    defaultMessage: 'All statistics for {name}'
  },
  benchmarksBreadcrumbSessions: {
    id: 'benchmarks.breadcrumb.sessions',
    description: 'breadcrumb Benchmarks Sessions',
    defaultMessage: 'Sessions'
  },
  benchmarksSporterPageSubtitle: {
    id: 'benchmarks.title.athlete-page-subtitle',
    description: 'subtitle Benchmarks Athlete',
    defaultMessage: 'All statistics for {name}'
  },
  benchmarksSessionPageSubtitle: {
    id: 'benchmarks.title.session-page-subtitle',
    description: 'subtitle Benchmarks Session',
    defaultMessage: 'All statistics for {name}'
  },
  benchmarksSettingsSelectDefault: {
    id: 'benchmarks.settings.option.default',
    description: 'option label Benchmarks select Settings',
    defaultMessage: 'Benchmark from session'
  },
  benchmarksSettingsPackage: {
    id: 'benchmarks.settings.label.package',
    description: 'label Benchmarks Settings Package',
    defaultMessage: 'Package'
  },
  benchmarksSettingsType: {
    id: 'benchmarks.settings.label.type',
    description: 'label Benchmarks Settings Type',
    defaultMessage: 'Type'
  },
  benchmarksSettingsBenchmark: {
    id: 'benchmarks.settings.label.benchmark',
    description: 'label Benchmarks Settings Benchmark',
    defaultMessage: 'Benchmark'
  },
  benchmarksSettingsTypeAverage: {
    id: 'benchmarks.settings.label.type-average',
    description: 'label Benchmarks Settings Type Average',
    defaultMessage: 'Average'
  },
  benchmarksSettingsTypeBenchmark: {
    id: 'benchmarks.settings.label.type-benchmark',
    description: 'label Benchmarks Settings Type Benchmark',
    defaultMessage: 'Benchmark'
  },
  benchmarksSettingsFilter: {
    id: 'benchmarks.settings.label.filter',
    description: 'label Benchmarks Settings Filter',
    defaultMessage: 'Filter'
  },
  benchmarksSettingsFilterBest: {
    id: 'benchmarks.settings.label.filter-best',
    description: 'option Benchmarks Settings Filter Best',
    defaultMessage: 'Best'
  },
  benchmarksSettingsFilterLast: {
    id: 'benchmarks.settings.label.filter-last',
    description: 'option Benchmarks Settings Filter Last',
    defaultMessage: 'Last'
  },
  benchmarksSettingsFilterAverage: {
    id: 'benchmarks.settings.label.filter-average',
    description: 'option Benchmarks Settings Filter Average',
    defaultMessage: 'Average'
  },
  benchmarksAlertMessageEmptyGroup: {
    id: 'benchmarks.alert.message.empty-group',
    description: 'message Benchmarks Empty Group',
    defaultMessage:
      'It seems like you have selected an empty group, please select a group with athletes or add athletes to this group through the groups page.'
  },
  benchmarksAlertMessageNoBenchmark: {
    id: 'benchmarks.alert.message.no-benchmark',
    description: 'message Benchmarks No benchmark results',
    defaultMessage:
      'The selected test set does not contain any results that can be displayed. Please select another test set.'
  },
  benchmarksAlertButtonNoBenchmark: {
    id: 'benchmarks.alert.button.no-benchmark',
    description: 'button Benchmarks No benchmark',
    defaultMessage: 'Change selection'
  },
  benchmarksAlertMessageTimeout: {
    id: 'benchmarks.alert.message.timeout',
    description: 'message Benchmarks timeout',
    defaultMessage:
      'There was a problem preparing the data for the selected group. We are working on it. Please try again later.'
  },
  benchmarksAlertButtonTimeout: {
    id: 'benchmarks.alert.button.timeout',
    description: 'button Benchmarks timeout',
    defaultMessage: 'Select another group'
  },
  benchmarksAlertButtonGoToSporters: {
    id: 'benchmarks.alert.button.go-to-athletes',
    description: 'button Benchmarks Goto Athletes',
    defaultMessage: 'Go to athletes'
  },
  benchmarksAlertButtonGoBack: {
    id: 'benchmarks.alert.button.go-back',
    description: 'button Benchmarks Back',
    defaultMessage: 'Back'
  },
  benchmarksMenuItemExportBenchmark: {
    id: 'benchmarks.menu-item.export-benchmark',
    description: 'menu item Benchmarks Export Benchmark',
    defaultMessage: 'Export benchmark'
  },
  // 13 - './containers/pages/packages/messages.js'
  // ... nothing of this exist anymore

  // 14 - './containers/pages/persons/messages.js'
  breadcrumbSporters: {
    id: 'athletes.breadcrumb.athlete',
    description: 'breadcrumb Athletes',
    defaultMessage: 'Athletes'
  },
  breadcrumbSportersSessions: {
    id: 'athletes.breadcrumb.athlete-sessions',
    description: 'breadcrumb Athlete Sessions',
    defaultMessage: '{firstname} {lastname} : Sessions'
  },
  titleSporters: {
    id: 'athletes.title.athletes',
    description: 'title Athletes',
    defaultMessage: '{inGroup, select, true {{group}} other {Athletes}}'
  },
  titleGroups: {
    id: 'athletes.title.groups',
    description: 'title Groups',
    defaultMessage: 'Groups of Athletes'
  },
  titlePool: {
    id: 'athletes.title.pool',
    description: 'title Pool',
    defaultMessage: 'All athletes'
  },
  subtitleSporters: {
    id: 'athletes.title.athletes-subtitle',
    description: 'subtitle Athletes',
    defaultMessage:
      '{root, select, true {All groups within the organisation} other {{inGroup, select, true {All athletes and subgroups in the group "{group}"} other {The pool of all athletes in the organisation}}}}'
  },
  titleSportersAddSporter: {
    id: 'athletes.title.add-athlete',
    description: 'title Athletes Add Athlete',
    defaultMessage:
      'New athlete {inGroup, select, true {in the group <span>{group}</span>} other {}}'
  },
  buttonSportersAddSporter: {
    id: 'athletes.button.add-athlete',
    description: 'button Athletes Add Athlete',
    defaultMessage: 'Create athlete'
  },
  labelSportersGroupName: {
    id: 'athletes.group.label.name',
    description: 'label Athletes Group Name',
    defaultMessage: 'name'
  },
  labelSportersGroupGrade: {
    id: 'athletes.group.label.grade',
    description: 'label Athletes Group Grade',
    defaultMessage: 'grade'
  },
  labelSportersGroupYear: {
    id: 'athletes.group.label.year',
    description: 'label Athletes Group year',
    defaultMessage: 'year'
  },
  titleSportersAddGroup: {
    id: 'athletes.title.add-group',
    description: 'title Athletes Add Group',
    defaultMessage:
      'Create a new {inGroup, select, true {subgroup} other {group}}{inGroup, select, true { in the group <span>{group}</span>} other {}}'
  },
  addGroupButton: {
    id: 'athletes.button.add-group',
    description: 'button Athletes Add Group',
    defaultMessage:
      'Create {root, select, true {group} other {subgroup (in the current group)}}'
  },
  titleSportersEditGroup: {
    id: 'athletes.title.edit-group',
    description: 'title Athletes edit Group',
    defaultMessage: 'Rename the <span>{group}</span>'
  },
  editGroupButton: {
    id: 'athletes.button.edit-group',
    description: 'button Athletes Edit Group',
    defaultMessage: 'Save'
  },
  gradeNotANumber: {
    id: 'global.error.grade-not-number',
    description: 'error grade is not a number',
    defaultMessage: 'Grade is not a valid number'
  },
  messageGroupSaved: {
    id: 'athletes.message.group-saved',
    description: 'Message when a group is saved',
    defaultMessage: 'Your group is saved'
  },
  messageGroupUpdated: {
    id: 'athletes.message.group-updated',
    description: 'Message when a group is updated',
    defaultMessage: 'your group is updated'
  },
  tabSporterGlobal: {
    id: 'athletes.athlete.tab.global',
    description: 'tab Athlete Global',
    defaultMessage: 'Global'
  },
  tabSporterSession: {
    id: 'athletes.athlete.tab.sessions',
    description: 'tab Athlete Sessions',
    defaultMessage: 'Sessions'
  },
  tabSporterPrograms: {
    id: 'athletes.athlete.tab.programs',
    description: 'tab Athlete Programs',
    defaultMessage: 'Programs'
  },
  tabSporterMedical: {
    id: 'athletes.athlete.tab.medical',
    description: 'tab Athlete Medical',
    defaultMessage: 'Medical'
  },
  tabSporterVideos: {
    id: 'athletes.athlete.tab.videos',
    description: 'tab Athlete Videos',
    defaultMessage: 'Videos'
  },
  labelSporterAge: {
    id: 'athletes.athlete.label.age',
    description: 'label Athlete Age',
    defaultMessage: '{age} years'
  },
  menuItemSporterRemoveSporter: {
    id: 'athletes.athlete.menu-item.remove-athlete',
    description: 'menu item Athlete Remove Athlete',
    defaultMessage: 'Remove athlete from this group'
  },
  menuItemSporterArchiveSporters: {
    id: 'athletes.athlete.menu-item.archive-selected-athletes',
    description: 'menu item Athlete archive Selected Athletes',
    defaultMessage:
      'Remove {count, plural, =1 {the selected athlete} other {the ({selected}) selected athletes}} from this group'
  },
  menuItemSporterRemoveSporters: {
    id: 'athletes.athlete.menu-item.remove-selected-athletes',
    description: 'menu item Athlete Remove Selected Athletes',
    defaultMessage:
      'Permanently remove selected {count, plural, =1 {athlete} other {athletes}}'
  },
  menuItemSporterRemoveSession: {
    id: 'athletes.athlete.menu-item.remove-session',
    description: 'menu item Athlete Remove Session',
    defaultMessage: 'Remove session'
  },
  menuItemSporterRemoveSporterFromSession: {
    id: 'athletes.athlete.menu-item.remove-athlete-from-session',
    description: 'menu item Athlete Remove Athlete from Session',
    defaultMessage: 'Remove athlete from session'
  },
  menuItemSporterRemoveGroup: {
    id: 'athletes.athlete.menu-item.remove-group',
    description: 'menu item Athlete Remove Group',
    defaultMessage: 'Remove the current group'
  },
  menuItemSporterRemoveSelectedGroup: {
    id: 'athletes.athlete.menu-item.remove-selected-group',
    description: 'menu item Athlete Remove Selected Group(s)',
    defaultMessage:
      'Remove {count, plural, =1 {selected group} other {{count} selected groups}}'
  },
  menuItemSporterEditGroup: {
    id: 'athletes.athlete.menu-item.edit-group',
    description: 'menu item Athlete Rename Group',
    defaultMessage: 'Rename the current group'
  },
  menuItemSporterEditSelectedGroup: {
    id: 'athletes.athlete.menu-item.edit-selected-group',
    description: 'menu item Athlete Rename Selected Group(s)',
    defaultMessage:
      'Rename {count, plural, =1 {selected group} other {{count} selected groups}}'
  },
  panelSportersEmpty: {
    id: 'athletes.message.empty-athletes-panel',
    description: 'label Athletes Empty Panel',
    defaultMessage:
      'Please select one of the options on the left to view the details...'
  },
  buttonSportersImportSporters: {
    id: 'athletes.button.import-athletes',
    description: 'button Athletes Import Athletes',
    defaultMessage: 'Import athletes into this group'
  },
  buttonSportersCopySporters: {
    id: 'athletes.button.copy-athletes',
    description: 'button Athletes Copy Athletes',
    defaultMessage:
      'Copy {count, plural, =1 {the selected athlete} other {the ({count}) selected athletes}} into another group'
  },
  buttonSportersMoveSporters: {
    id: 'athletes.button.move-athletes',
    description: 'button Athletes Move Athletes',
    defaultMessage:
      'Move {count, plural, =1 {the selected athlete} other {the ({count}) selected athletes}} to another group'
  },
  buttonSportersCopySportersFromPool: {
    id: 'athletes.button.copy-athletes-from-pool',
    description: 'button Athletes copy Athletes from Pool',
    defaultMessage:
      'Add {count, plural, =1 {the selected athlete} other {the ({count}) selected athletes}} to a group'
  },
  buttonSportersInvite: {
    id: 'athletes.button.invite-athletes',
    description: 'button Athletes Invite Athletes',
    defaultMessage:
      'Invite {count, plural, =1 {the selected athlete} other {the ({count}) selected athletes}} to the platform'
  },

  // 15 - './containers/pages/scanners/messages.js'
  scannersEmptyPanel: {
    id: 'scanners.message.empty-panel',
    description: 'label Scanners Empty Panel',
    defaultMessage:
      'Please select one of the scanners on the left to view the details...'
  },
  breadcrumbScanners: {
    id: 'scanners.breadcrumb.scanners',
    description: 'breadcrumb Scanners',
    defaultMessage: 'Scanners'
  },
  breadcrumbScannersCalibrations: {
    id: 'scanners.breadcrumb.calibrations',
    description: 'breadcrumb Scanners Calibrations',
    defaultMessage: '{scanner}: Calibrations'
  },
  scannerTitle: {
    id: 'scanners.title.scanners',
    description: 'title Scanners',
    defaultMessage: 'Scanners'
  },
  scannersSubtitle: {
    id: 'scanners.title.scanners-subtitle',
    description: 'subtitle Scanners',
    defaultMessage: `All available scanners within {name}`
  },
  scannersAddButton: {
    id: 'scanners.button.add-scanner',
    description: 'button Scanners Add Scanner',
    defaultMessage: 'New Scanner'
  },
  scannerTabGlobal: {
    id: 'scanners.scanner.tab.global',
    description: 'tab Scanner Global',
    defaultMessage: 'Global'
  },
  scannerTabResult: {
    id: 'scanners.scanner.tab.result',
    description: 'tab Scanner Result',
    defaultMessage: 'Result'
  },
  scannerTabCalibrations: {
    id: 'scanners.scanner.tab.calibrations',
    description: 'tab Scanner Calibrations',
    defaultMessage: 'Calibrations'
  },
  scannerTabDiagram: {
    id: 'scanners.scanner.tab.diagram',
    description: 'tab Scanner Diagram',
    defaultMessage: 'Diagram'
  },
  scannerTitleNewScanner: {
    id: 'scanners.title.new-scanner',
    description: 'title Scanners Add Scanner',
    defaultMessage: 'New scanner'
  },
  scannerTitleNewCalibration: {
    id: 'scanners.title.new-calibration',
    description: 'title Scanner Add Calibration',
    defaultMessage: 'New calibration'
  },
  scannerTitleDetails: {
    id: 'scanners.scanner.title.scanner-details',
    description: 'title Scanner Details',
    defaultMessage: 'Scanner details'
  },
  scannerNameLabel: {
    id: 'scanners.scanner.label.name',
    description: 'label Scanner Name',
    defaultMessage: 'Scanner name'
  },
  scannerTypeLabel: {
    id: 'scanners.scanner.label.type',
    description: 'label Scanner Type',
    defaultMessage: 'Scanner type'
  },
  scannerVendorLabel: {
    id: 'scanners.scanner.label.vendor',
    description: 'label Scanner Vendor',
    defaultMessage: 'Scanner vendor'
  },
  scannerCoilTypeLabel: {
    id: 'scanners.scanner.label.coil-type',
    description: 'label Scanner Coil Type',
    defaultMessage: 'Coil type'
  },
  scannerLocationLabel: {
    id: 'scanners.scanner.label.location',
    description: 'label Scanner Location',
    defaultMessage: 'Location'
  },
  scannerButtonSubmit: {
    id: 'scanners.scanner.button.submit',
    description: 'button Scanner Submit',
    defaultMessage: 'Save'
  },
  scannerButtonCancel: {
    id: 'scanners.scanner.button.cancel',
    description: 'button Scanner Cancel',
    defaultMessage: 'Cancel'
  },
  scannerButtonAddCalibration: {
    id: 'scanners.scanner.button.add-calibration',
    description: 'button Scanner Add Calibration',
    defaultMessage: 'Add calibration'
  },
  calibrationNameLabel: {
    id: 'scanners.calibration.label.name',
    description: 'label Scanner Calibration Name',
    defaultMessage: 'Calibration name'
  },
  calibrationDateLabel: {
    id: 'scanners.calibration.label.date',
    description: 'label Scanner Calibration Date',
    defaultMessage: 'Calibration date'
  },
  calibrationShimLabel: {
    id: 'scanners.calibration.label.shim',
    description: 'label Scanner Calibration Shim',
    defaultMessage: 'Calibration shim (Hz)'
  },
  calibrationCoilLabel: {
    id: 'scanners.calibration.label.coil',
    description: 'label Scanner Calibration Coil',
    defaultMessage: 'Calibration coil (V)'
  },
  calibrationButtonSubmit: {
    id: 'scanners.calibration.button.submit',
    description: 'button Scanner Calibration Submit',
    defaultMessage: 'Save'
  },
  scannerMenuItemRemoveScanner: {
    id: 'scanners.scanner.menu-item.remove-scanner',
    description: 'menu item Scanner Remove Scanner',
    defaultMessage: 'Remove scanner'
  },
  scannerMenuItemRemoveCalibration: {
    id: 'scanners.scanner.menu-item.remove-calibration',
    description: 'menu item Scanner Remove Calibration',
    defaultMessage: 'Remove calibration from scanner'
  },
  scannerCalibrationTitleDetails: {
    id: 'scanners.calibration.title.details',
    description: 'title Scanner Calibration Details',
    defaultMessage: 'Calibration details'
  },
  scannerCalibrationTitleResult: {
    id: 'scanners.calibration.title.results',
    description: 'title Scanner Calibration Results',
    defaultMessage: 'Calibration result'
  },
  calibrationCarnosineLabel: {
    id: 'scanners.calibration.label.carnosine',
    description: 'label Scanner Calibration Carnosine',
    defaultMessage: 'Carnosine (mM)'
  },
  calibrationCreatineLabel: {
    id: 'scanners.calibration.label.creatine',
    description: 'label Scanner Calibration Creatine',
    defaultMessage: 'Creatine (mM)'
  },
  calibrationTmaLabel: {
    id: 'scanners.calibration.label.tma',
    description: 'label Scanner Calibration TMA',
    defaultMessage: 'TMA (mM)'
  },
  calibrationFeedbackLabel: {
    id: 'scanners.calibration.label.feedback',
    description: 'label Scanner Calibration Feedback',
    defaultMessage: 'Feedback'
  },
  calibrationVoxelSizeLabel: {
    id: 'scanners.calibration.label.voxel-size',
    description: 'label Scanner Calibration Voxel Size',
    defaultMessage: 'Voxel size (mm)'
  },
  calibrationVoxelSizeXLabel: {
    id: 'scanners.calibration.label.voxel-size-x',
    description: 'label Scanner Calibration Voxel Size X',
    defaultMessage: 'x'
  },
  calibrationVoxelSizeYLabel: {
    id: 'scanners.calibration.label.voxel-size-y',
    description: 'label Scanner Calibration Voxel Size Y',
    defaultMessage: 'y'
  },
  calibrationVoxelSizeZLabel: {
    id: 'scanners.calibration.label.voxel-size-z',
    description: 'label Scanner Calibration Voxel Size Z',
    defaultMessage: 'z'
  },
  calibrationSignalWaterLabel: {
    id: 'scanners.calibration.label.signal-water',
    description: 'label Scanner Calibration Signal Water',
    defaultMessage: 'Signal water (mM)'
  },

  // 16 - './containers/pages/rehab/messages.js'
  rehabMenuItemRemovePersonInjury: {
    id: 'rehab.menu-item.remove-person-injury',
    description: 'menu item Rehab Remove Person Injury',
    defaultMessage: 'Remove injury'
  },
  rehabAddScreeningTitle: {
    id: 'rehab.title.add-screening',
    description: 'title Rehab Add Screening',
    defaultMessage: 'Add Screening'
  },
  rehabCopyScreeningTitle: {
    id: 'rehab.title.copy-screening',
    description: 'title Rehab copy Screening',
    defaultMessage: 'Add a Screening based on the screening {screening}'
  },
  rehabAddScreeningButton: {
    id: 'rehab.button.add-screening',
    description: 'button Rehab Add Screening',
    defaultMessage: 'Add Screening'
  },
  rehabRemoveScreeningButton: {
    id: 'rehab.button.remove-screening',
    description: 'button Rehab Remove Screening',
    defaultMessage: 'Remove screening'
  },
  rehabMedicalHistory: {
    id: 'rehab.title.medical',
    description: 'title Rehab Medical History',
    defaultMessage: 'Medical History'
  },
  rehabMedicalSaved: {
    id: 'rehab.medical.message.saved',
    description: 'message Rehab Medical History Saved',
    defaultMessage: 'Medical History is saved'
  },
  rehabAddInjuryTitle: {
    id: 'rehab.title.add-injury',
    description: 'title Rehab Add Injury',
    defaultMessage:
      '{injuryExists, select, true {{injury}} other {Add a new injury}}'
  },
  breadcrumbScreening: {
    id: 'rehab.breadcrumb.screening',
    description: 'breadcrumb Rehab Screening',
    defaultMessage: 'Screening'
  },
  breadcrumbInjuries: {
    id: 'rehab.breadcrumb.injuries',
    description: 'breadcrumb Rehab Injuries',
    defaultMessage: 'Injuries'
  },
  rehabInjuryDetailsTab: {
    id: 'rehab.tab.injury-details',
    description: 'tab Injury details',
    defaultMessage: 'Details'
  },
  rehabScreeningScheduleTestButton: {
    id: 'rehab.screening-schedule-test-button',
    description: 'button Schedule re-test',
    defaultMessage: 'Schedule re-test'
  },
  titleInjuries: {
    id: 'rehab.title.injuries-title',
    description: 'title Injuries',
    defaultMessage: 'Injuries'
  },
  titleInjuryDetails: {
    id: 'rehab.title.injury-details',
    description: 'title Injury details',
    defaultMessage: 'Injury details'
  },
  subtitleInjuryCount: {
    id: 'rehab.title.injuries-subtitle-count',
    description: 'subtitle Injury Count',
    defaultMessage:
      '{count, plural, =1 {1 injury} other {{count} injuries}} in the last {years}'
  },
  subtitleNoInjury: {
    id: 'rehab.title.injuries-subtitle-no-injuries',
    description: 'subtitle No Injuries',
    defaultMessage: 'No injuries in recent years'
  },
  subtitleIllnessCount: {
    id: 'rehab.title.illnesses.subtitle-count',
    description: 'subtitle Illness Count',
    defaultMessage:
      '{count, plural, =1 {1 illness} other {{count} illnesses}} in the last {years}'
  },
  subtitleNoIllness: {
    id: 'rehab.title.illnesses.subtitle-no-injuries',
    description: 'subtitle No illnesses',
    defaultMessage: 'No illnesses in recent years'
  },
  buttonAddInjury: {
    id: 'rehab.button.add-injury',
    description: 'button Add Injury',
    defaultMessage: 'Add Injury'
  },
  buttonShowInjuries: {
    id: 'rehab.button.show-injuries',
    description: 'button Show all/less injuries',
    defaultMessage:
      '{showAll, select, true {Show all injuries…} false {Show less injuries…} other {}}'
  },
  titleIssues: {
    id: 'rehab.title.issues-title',
    description: 'title Issues',
    defaultMessage: 'Issues'
  },
  titleIssueDetails: {
    id: 'rehab.title.issue-details',
    description: 'title Issue details',
    defaultMessage: 'Issue details'
  },
  subtitleIssueCount: {
    id: 'rehab.title.issues.subtitle-count',
    description: 'subtitle issues Count',
    defaultMessage:
      '{count, plural, =1 {1 issue} other {{count} issues}} in the last {years}'
  },
  subtitleNoIssue: {
    id: 'rehab.title.issues-subtitle-no-injuries',
    description: 'subtitle No issues',
    defaultMessage: 'No issues in recent years'
  },
  buttonAddIssue: {
    id: 'rehab.button.add-issue',
    description: 'button Add Issue',
    defaultMessage: 'Add Issue'
  },
  labelRegion: {
    id: 'rehab.injury.region.label.select',
    description: 'label Region',
    defaultMessage: 'Region'
  },
  labelInjury: {
    id: 'rehab.injury.label.select',
    description: 'label Injury',
    defaultMessage: 'Injury'
  },
  rehabInjuryScreeningsTab: {
    id: 'rehab.injury.tab.screenings',
    description: 'tab Rehab Injury Screenings',
    defaultMessage: 'Screenings'
  },
  rehabInjuryReportsTab: {
    id: 'rehab.injury.tab.reports',
    description: 'tab Rehab Injury Reports',
    defaultMessage: 'Reports'
  },
  rehabInjuryDateLabel: {
    id: 'rehab.injury.label.date',
    description: 'label Rehab Injury Date',
    defaultMessage: 'Issue Date'
  },
  rehabInjuryEndDateLabel: {
    id: 'rehab.injury.label.end-date',
    description: 'label Rehab Injury End Date',
    defaultMessage: 'Issue End Date'
  },
  rehabInjuryTypeLabel: {
    id: 'rehab.injury.label.type',
    description: 'label Rehab Injury Type',
    defaultMessage: 'Type of Injury'
  },
  rehabInjuryTypePlaceholder: {
    id: 'rehab.injury.placeholder.type',
    description: 'placeholder Rehab Injury Type',
    defaultMessage: 'Select a type of injury'
  },
  rehabInjuryTypeAcuteOption: {
    id: 'rehab.injury.option.type-acute',
    description: 'option Rehab Injury Type Acute',
    defaultMessage: 'Acute injury'
  },
  rehabInjuryTypeOveruseOption: {
    id: 'rehab.injury.option.type-overuse',
    description: 'option Rehab Injury Type Overuse',
    defaultMessage: 'Overuse injury'
  },
  rehabInjuryTypeOtherOption: {
    id: 'rehab.injury.option.type-other',
    description: 'option Rehab Injury Type Other',
    defaultMessage: 'Other'
  },
  rehabInjuryCauseLabel: {
    id: 'rehab.injury.label.cause',
    description: 'label Rehab Injury Cause',
    defaultMessage: 'Cause of Injury'
  },
  rehabInjuryCausePlaceholder: {
    id: 'rehab.injury.placeholder.cause',
    description: 'placeholder Rehab Injury Cause',
    defaultMessage: 'Select a cause of injury'
  },
  rehabInjuryCauseContactOption: {
    id: 'rehab.injury.option.cause-contact',
    description: 'option Rehab Injury Cause Contact',
    defaultMessage: 'Contact'
  },
  rehabInjuryCauseNonContactOption: {
    id: 'rehab.injury.option.cause-non-contact',
    description: 'option Rehab Injury Cause Non-Contact',
    defaultMessage: 'Non-contact'
  },
  rehabInjuryRelatedToLabel: {
    id: 'rehab.injury.label.related-to',
    description: 'label Rehab Injury Related to',
    defaultMessage: 'Related to'
  },
  rehabInjuryRelatedToSport: {
    id: 'rehab.injury.label.related-to-sport',
    description: 'label Rehab Injury Related to Sport',
    defaultMessage: 'sport'
  },
  rehabInjuryRelatedToGrowth: {
    id: 'rehab.injury.label.related-to-growth',
    description: 'label Rehab Injury Related to Growth',
    defaultMessage: 'growth'
  },
  rehabInjuryDescriptionLabel: {
    id: 'rehab.injury.label.description',
    description: 'label Rehab Injury description',
    defaultMessage: 'Issue description (type/location)'
  },
  rehabInjuryExtraInfoLabel: {
    id: 'rehab.injury.label.extra-info',
    description: 'label Rehab Injury extra info',
    defaultMessage: 'More info about cause / injury mechanism'
  },
  rehabInjuryMoreInfo: {
    id: 'rehab.injury.label.more-info-button',
    description: 'label button to open the accordion',
    defaultMessage: 'More info'
  },

  rehabInjurySideLabel: {
    id: 'rehab.injury.label.side',
    description: 'label Rehab Injury Side',
    defaultMessage: 'Side'
  },
  rehabInjurySideValue: {
    id: 'rehab.injury.label.side-value',
    description: 'option Rehab Injury Side',
    defaultMessage: '{side, select, right {right} left {left} other {}}'
  },
  rehabInjuryNoRegionWarning: {
    id: 'rehab.injury.option.no-regoin',
    description: 'option Rehab no region selected',
    defaultMessage: 'Select a region first'
  },
  rehabInjurySaved: {
    id: 'rehab.injury.message.saved',
    description: 'message Rehab Injury Saved',
    defaultMessage: 'Injury is saved'
  },
  rehabScreeningReportTab: {
    id: 'rehab.screening.tab.reports',
    description: 'tab Rehab Screening Report',
    defaultMessage: 'Report'
  },
  rehabScreeningResultsTab: {
    id: 'rehab.screening.tab.results',
    description: 'tab Rehab Screening Test Results',
    defaultMessage: 'Test Results'
  },
  rehabScreeningDetailsTab: {
    id: 'rehab.screening.tab.details',
    description: 'tab Rehab Screening Details',
    defaultMessage: 'Details'
  },
  rehabScreeningSaved: {
    id: 'rehab.screening.message.saved',
    description: 'message Rehab Screening Saved',
    defaultMessage: 'Screening is saved'
  },
  titleScreening: {
    id: 'rehab.title.screening',
    description: 'title Rehab Screening',
    defaultMessage: 'Screening'
  },
  rehabScreeningNameLabel: {
    id: 'rehab.screening.label.name',
    description: 'label Rehab Screening Name',
    defaultMessage: 'Name'
  },
  rehabScreeningDateLabel: {
    id: 'rehab.screening.label.date',
    description: 'label Rehab Screening Date',
    defaultMessage: 'Screening Date'
  },
  rehabScreeningMilestoneLabel: {
    id: 'rehab.screening.label.milestone',
    description: 'label Rehab Screening Milestone',
    defaultMessage: 'Milestone'
  },
  rehabScreeningMilestonePlaceholder: {
    id: 'rehab.screening.placeholder.milestone',
    description: 'Placeholder Rehab Screening Milestone Select',
    defaultMessage: 'Select a Milestone'
  },
  rehabScreeningTimeLabel: {
    id: 'rehab.screening.label.time',
    description: 'label Rehab Screening Time',
    defaultMessage: 'Estimated Time'
  },
  rehabScreeningMilestoneNonOptionLabel: {
    id: 'rehab.screening.label.none-milestone',
    description: 'option Rehab Screening without Milestone',
    defaultMessage: 'Choose your own tests'
  },
  titleScreeningTests: {
    id: 'rehab.screening.label.tests',
    description: 'title Rehab Screening Tests',
    defaultMessage: 'Tests'
  },
  titleScreeningSelectTest: {
    id: 'rehab.screening.title.select-test',
    description: 'title Rehab Screening Select Test',
    defaultMessage: 'Select Test'
  },
  titleScreeningUnselectedTests: {
    id: 'rehab.screening.title.unselected-tests',
    description: 'title Rehab Screening Unselected Tests',
    defaultMessage: 'Unselected tests'
  },
  buttonScreeningAddTests: {
    id: 'rehab.screening.button.add-tests',
    description: 'button Rehab Screening Add Tests',
    defaultMessage: 'Add extra tests'
  },
  labelScreeningNoTests: {
    id: 'rehab.screening.message.no-tests',
    description: 'label Rehab Screening No Tests',
    defaultMessage: 'No tests added yet...'
  },
  titleScreeningExercises: {
    id: 'rehab.screening.title.exercises',
    description: 'title Rehab Screening Exercises',
    defaultMessage: 'Exercises'
  },
  buttonScreeningSaveAsTemplate: {
    id: 'rehab.screening.button.save-as-template',
    description: 'button Rehab Screening Save as Template',
    defaultMessage: 'Save as a template'
  },
  // 17 - './containers/partials/drawer/messages.js'
  drawerSporters: {
    id: 'drawer.label.athletes',
    description: 'label Drawer Athletes',
    defaultMessage: 'Athletes'
  },
  drawerScanners: {
    id: 'drawer.label.scanners',
    description: 'label Drawer Scanners',
    defaultMessage: 'Scanners'
  },
  drawerSettings: {
    id: 'drawer.label.settings',
    description: 'label Drawer Settings',
    defaultMessage: 'Settings'
  },
  drawerSupport: {
    id: 'drawer.label.support',
    description: 'label Drawer Support',
    defaultMessage: 'Help & support'
  },
  drawerSessions: {
    id: 'drawer.label.sessions',
    description: 'label Drawer Sessions',
    defaultMessage: 'Sessions'
  },
  drawerLogout: {
    id: 'drawer.label.logout',
    description: 'label Drawer Logout',
    defaultMessage: 'Logout'
  },
  drawerBenchmarks: {
    id: 'drawer.label.benchmarks',
    description: 'label Drawer Benchmarks',
    defaultMessage: 'Results'
  },
  drawerGrowthPrediction: {
    id: 'drawer.label.growth-prediction',
    description: 'label Drawer Growth Prediction',
    defaultMessage: 'Growth tracker'
  },
  drawerExercises: {
    id: 'drawer.label.exercises',
    description: 'label Drawer Exercises',
    defaultMessage: 'Exercises'
  },
  drawerTest: {
    id: 'drawer.label.test',
    description: 'label Drawer Test',
    defaultMessage: 'Test'
  },
  drawerCollections: {
    id: 'drawer.label.collections',
    description: 'label Drawer Collections',
    defaultMessage: 'Collections'
  },
  drawerPrograms: {
    id: 'drawer.label.programs',
    description: 'label Drawer Programs',
    defaultMessage: 'Programs'
  },
  drawerDocuments: {
    id: 'drawer.label.documents',
    description: 'label Drawer Documents',
    defaultMessage: 'Documents'
  },
  drawerTrainings: {
    id: 'drawer.label.trainings',
    description: 'label Drawer Trainings',
    defaultMessage: 'Trainings'
  },
  drawerLessons: {
    id: 'drawer.label.lessons',
    description: 'label Drawer Lessons',
    defaultMessage: 'Lessons'
  },
  drawerVideos: {
    id: 'drawer.label.videos',
    description: 'label Drawer videos',
    defaultMessage: 'Videos'
  },

  // 18 - './containers/partials/list-items/messages.js'
  sessionListItemTakeTest: {
    id: 'session.list-item.take-test',
    description: 'list item Take Test',
    defaultMessage: 'Take test'
  },
  sessionListItemResults: {
    id: 'session.list-item.results',
    description: 'list item Results',
    defaultMessage: 'Results'
  },
  sessionListItemAddSporters: {
    id: 'session.list-item.add-athletes',
    description: 'list item Add Athletes',
    defaultMessage: 'Add athletes'
  },

  // 19 - './containers/partials/buttons/messages.js'
  modalConfirmRemoveAcceptButton: {
    id: 'global.button.confirm-remove',
    description: 'button Confirm Remove Accept',
    defaultMessage: 'Remove'
  },
  modalConfirmRemoveCancelButton: {
    id: 'global.button.confirm-remove-cancel',
    description: 'button Confirm Remove Cancel',
    defaultMessage: 'Cancel'
  },
  modalConfirmResetButton: {
    id: 'global.button.confirm-reset',
    description: 'button Confirm Reset',
    defaultMessage: 'Reset'
  },
  modalConfirmPublishButton: {
    id: 'global.button.confirm-publish',
    description: 'button Confirm Publish',
    defaultMessage: 'Publish'
  },
  modalConfirmUnPublishButton: {
    id: 'global.button.confirm-unpublish',
    description: 'button Confirm UnPublish',
    defaultMessage: 'Unpublish'
  },
  modalConfirmRemoveBenchmarkTitle: {
    id: 'benchmarks.title.confirm-remove-benchmark-title',
    description: 'title Confirm Remove Benchmark',
    defaultMessage: 'Remove benchmark'
  },
  modalConfirmRemoveBenchmarkMessage: {
    id: 'benchmarks.title.confirm-remove-benchmark-message',
    description: 'message Confirm Remove Benchmark',
    defaultMessage: 'Are you sure you want to delete this benchmark?'
  },
  modalConfirmPublishBenchmarkTitle: {
    id: 'benchmarks.title.confirm-publish-benchmark-title',
    description: 'title Confirm Publish Benchmark',
    defaultMessage: 'Publish benchmark'
  },
  modalConfirmPublishBenchmarkMessage: {
    id: 'benchmarks.title.confirm-publish-benchmark-message',
    description: 'message Confirm Publish Benchmark',
    defaultMessage: 'Are you sure you want to publish this benchmark?'
  },
  modalConfirmUnPublishBenchmarkTitle: {
    id: 'benchmarks.title.confirm-unpublish-benchmark-title',
    description: 'title Confirm UnPublish Benchmark',
    defaultMessage: 'Unpublish benchmark'
  },
  modalConfirmUnPublishBenchmarkMessage: {
    id: 'benchmarks.title.confirm-unpublish-benchmark-message',
    description: 'message Confirm UnPublish Benchmark',
    defaultMessage: 'Are you sure you want to unpublish this benchmark?'
  },
  modalConfirmResetBenchmarkTitle: {
    id: 'benchmarks.title.confirm-reset-benchmark-title',
    description: 'title Confirm Reset Benchmark',
    defaultMessage: 'Reset benchmark'
  },
  modalConfirmResetBenchmarkMessage: {
    id: 'benchmarks.title.confirm-reset-benchmark-message',
    description: 'message Confirm Reset Benchmark',
    defaultMessage: 'Are you sure you want to reset this benchmark?'
  },
  modalConfirmRemoveTestSetTitle: {
    id: 'testsets.title.confirm-remove-testset-title',
    description: 'title Confirm Remove Testset',
    defaultMessage: 'Remove test set'
  },
  modalConfirmRemoveTestSetMessage: {
    id: 'testsets.title.confirm-remove-testset-message',
    description: 'message Confirm Remove Testset',
    defaultMessage: 'Are you sure you want to delete this test set?'
  },
  modalConfirmRemoveOrganisationTitle: {
    id: 'organisations.title.confirm-remove-organisation-title',
    description: 'title Confirm Remove Organisation',
    defaultMessage: 'Remove organisation'
  },
  modalConfirmRemoveOrganisationMessage: {
    id: 'organisations.title.confirm-remove-organisation-message',
    description: 'message Confirm Remove Organisation',
    defaultMessage: 'Are you sure you want to delete this organisation?'
  },
  modalConfirmRemoveGroupTitle: {
    id: 'athletes.title.confirm-remove-group-title',
    description: 'title Confirm Remove Group',
    defaultMessage: 'Remove group'
  },
  modalConfirmRemoveGroupMessage: {
    id: 'athletes.title.confirm-remove-group-message',
    description: 'message Confirm Remove Group',
    defaultMessage: 'Are you sure you want to delete the group <b>{group}</b>?'
  },
  modalConfirmRemoveGroupsTitle: {
    id: 'athletes.title.confirm-remove-groups-title',
    description: 'title Confirm Remove Groups',
    defaultMessage: 'Remove groups'
  },
  modalConfirmRemoveGroupsMessage: {
    id: 'athletes.title.confirm-remove-groups-message',
    description: 'message Confirm Remove Groups',
    defaultMessage:
      "Are you sure you want to delete the groups {count, plural, =1 {<b>this group</b>} other {these <b>{count} groups</b>}}?<br></br><b>{groups}</b><br></br> Note: The persons in these groups aren't deleted."
  },
  modalRemoveGroupsConfirmed: {
    id: 'athletes.title.confirm-remove-groups',
    description: 'Confirmed Remove Groups',
    defaultMessage: 'The groups are removed'
  },
  modalConfirmRemoveUserTitle: {
    id: 'users.title.confirm-remove-user-title',
    description: 'title Confirm Remove User',
    defaultMessage: 'Remove user'
  },
  modalConfirmRemoveUserMessage: {
    id: 'users.title.confirm-remove-user-message',
    description: 'message Confirm Remove User',
    defaultMessage: 'Are you sure you want to delete this user?'
  },
  modalConfirmRemoveScannerTitle: {
    id: 'scanners.title.confirm-remove-scanner-title',
    description: 'title Confirm Remove Scanner',
    defaultMessage: 'Remove scanner'
  },
  modalConfirmRemoveScannerMessage: {
    id: 'scanners.title.confirm-remove-scanner-message',
    description: 'message Confirm Remove Scanner',
    defaultMessage: 'Are you sure you want to delete this scanner?'
  },
  modalConfirmRemoveCalibrationTitle: {
    id: 'scanners.title.confirm-remove-calibration-title',
    description: 'title Confirm Remove Calibration',
    defaultMessage: 'Remove calibration'
  },
  modalConfirmRemoveCalibrationMessage: {
    id: 'scanners.title.confirm-remove-calibration-message',
    description: 'message Confirm Remove Calibration',
    defaultMessage: 'Are you sure you want to delete this calibration?'
  },
  modalConfirmRemoveSporterTitle: {
    id: 'athletes.title.confirm-remove-athlete-title',
    description: 'title Confirm Remove Athlete',
    defaultMessage: 'Remove athlete from group'
  },
  modalConfirmRemoveSporterMessage: {
    id: 'athletes.title.confirm-remove-athlete-message',
    description: 'message Confirm Remove Athlete',
    defaultMessage:
      'Are you sure you want to remove this athlete from your group?'
  },
  modalConfirmRemoveSessionTitle: {
    id: 'sessions.title.confirm-remove-session-title',
    description: 'title Confirm Remove Session',
    defaultMessage: 'Remove session'
  },
  modalConfirmRemoveSessionMessage: {
    id: 'sessions.title.confirm-remove-session-message',
    description: 'message Confirm Remove Session',
    defaultMessage: 'Are you sure you want to delete this session?'
  },
  modalConfirmRemoveFilesTitle: {
    id: 'filesupload.title.confirm-remove-files-title',
    description: 'title Confirm Remove Files',
    defaultMessage: 'Remove files'
  },
  modalConfirmRemoveFilesMessage: {
    id: 'filesupload.title.confirm-remove-files-message',
    description: 'message Confirm Remove Files',
    defaultMessage: 'Are you sure you want to delete this files?'
  },
  modalConfirmRemoveSporterFromSessionTitle: {
    id: 'sessions.title.confirm-remove-athlete-from-session-title',
    description: 'title Confirm Remove Athlete from Session',
    defaultMessage: 'Remove athlete from session'
  },
  modalConfirmRemoveSporterFromSessionMessage: {
    id: 'sessions.title.confirm-remove-athlete-from-session-message',
    description: 'message Confirm Remove Athlete from Session',
    defaultMessage:
      'Are you sure you want to delete this athlete from the session?'
  },
  modalConfirmResetPasswordTitle: {
    id: 'users.title.confirm-reset-password-title',
    description: 'title Confirm Reset Password',
    defaultMessage: 'Reset user password'
  },
  modalConfirmResetPasswordMessage: {
    id: 'users.title.confirm-reset-password-message',
    description: 'message Confirm Reset Password',
    defaultMessage: 'Are you sure you want to reset the user password?'
  },
  modalConfirmResetPasswordAcceptButton: {
    id: 'global.button.confirm-reset-password-accept',
    description: 'button Confirm Reset Password Accept',
    defaultMessage: 'Reset'
  },
  modalConfirmInviteAcceptButton: {
    id: 'global.button.confirm-invite-accept',
    description: 'button Confirm Invite Accept',
    defaultMessage: 'Invite'
  },
  modalConfirmInviteSporterTitle: {
    id: 'athletes.title.confirm-invite-athlete',
    description: 'title Confirm Invite Athlete',
    defaultMessage: 'Invite athlete'
  },
  modalConfirmInviteSporterMessage: {
    id: 'athletes.title.confirm-invite-athlete-message',
    description: 'message Confirm Invite Athlete',
    defaultMessage:
      'Are you sure you want to send an invitation to this person?'
  },
  modalConfirmReInviteSporterMessage: {
    id: 'athletes.title.confirm-reinvite-athlete-message',
    description: 'message Confirm ReInvite Athlete',
    defaultMessage:
      'Are you sure you want to send a new invitation to this person?'
  },
  modalConfirmInviteSportersTitle: {
    id: 'athletes.title.confirm-invite-athletes',
    description: 'title Confirm Invite Athletes',
    defaultMessage: 'Invite athletes'
  },
  modalConfirmInviteSportersMessageWithListPersons: {
    id: 'athletes.title.confirm-invite-athletes-message-with-list',
    description: 'message Confirm Invite Athletes',
    defaultMessage:
      'Are you sure you want to send an invitation to {count, plural, =1 {<b>this athlete</b>} other {these <b>{count} athletes</b>}}?<br></br><b>{listPersons}</b><br></br><br></br>The invitation will only be sent to the athletes with an expired or without a login and a valid email.'
  },
  modalConfirmInviteSportersAcceptButton: {
    id: 'global.button.confirm-invite-athletes-accept',
    description: 'button Confirm Invite Athletes Accept',
    defaultMessage: 'Invite'
  },
  modalConfirmFinishSessionTitle: {
    id: 'sessions.title.confirm-finish-session',
    description: 'title Confirm Finish Session',
    defaultMessage: 'Finish session'
  },
  modalConfirmFinishSessionMessage: {
    id: 'sessions.title.confirm-finish-session-message',
    description: 'message Confirm Finish Session',
    defaultMessage:
      "Are you sure you want to finish this session? After the session is closed, it's no longer possible to log new tests, all links will become inactive."
  },
  modalConfirmFinishSessionAcceptButton: {
    id: 'global.button.confirm-finish-session-accept',
    description: 'button Confirm Finish Session',
    defaultMessage: 'Finish'
  },
  modalConfirmRemoveExerciseTitle: {
    id: 'exercises.title.confirm-remove-exercise',
    description: 'title Confirm Remove Exercise',
    defaultMessage: 'Remove exercise'
  },
  modalConfirmRemoveExerciseMessage: {
    id: 'exercises.title.confirm-remove-exercise-message',
    description: 'message Confirm Remove Exercise',
    defaultMessage: 'Are you sure you want to delete this exercise?'
  },
  modalConfirmMailReportTitle: {
    id: 'sessions.report.title.confirm-mail-report',
    description: 'title Confirm Mail Report',
    defaultMessage: 'Mail Report'
  },
  modalConfirmMailReportMessage: {
    id: 'sessions.report.title.confirm-mail-report-message',
    description: 'message Confirm Mail Report',
    defaultMessage: 'Are you sure you want to mail this report?'
  },
  modalConfirmMailReportAcceptButton: {
    id: 'global.button.confirm-mail-report-accept',
    description: 'button Confirm Mail Report Accept',
    defaultMessage: 'Mail'
  },
  modalConfirmMailAllReportsTitle: {
    id: 'sessions.report.title.confirm-mail-all-reports',
    description: 'title Confirm Mail All Reports',
    defaultMessage: 'Mail Reports'
  },
  modalConfirmMailAllReportsMessage: {
    id: 'sessions.report.title.confirm-mail-all-reports-message',
    description: 'message Confirm Mail All Reports',
    defaultMessage:
      'Are you sure you want to mail all the reports of this session to the athletes?'
  },
  modalConfirmRecalculateTestDataTitle: {
    id: 'sessions.report.title.confirm-recalculate-test-data',
    description: 'title Confirm Recalculate Test Data',
    defaultMessage: 'Recalculate testdata?'
  },
  modalConfirmRecalculateTestDataMessage: {
    id: 'sessions.report.message.confirm-recalculate-test-data',
    description: 'message Confirm Recalculate Test Data',
    defaultMessage: 'Are you sure you want to recalculate the testdata?'
  },
  modalConfirmRecalculateTestDataButton: {
    id: 'sessions.report.button.confirm-recalculate-test-data',
    description: 'button Confirm Recalculate Test Data',
    defaultMessage: 'Recalculate'
  },
  modalConfirmRemoveTemplateTitle: {
    id: 'exercises.template.title.confirm-remove-template',
    description: 'title Confirm Remove Template',
    defaultMessage: 'Remove template'
  },
  modalConfirmRemoveTemplateMessage: {
    id: 'exercises.template.title.confirm-remove-template-message',
    description: 'message Confirm Remove Template',
    defaultMessage: 'Are you sure you want to delete this template?'
  },
  modalConfirmRemoveCollectionTitle: {
    id: 'exercises.collection.title.confirm-remove-template',
    description: 'title Confirm Remove collection',
    defaultMessage: 'Remove collection'
  },
  modalConfirmRemoveCollectionMessage: {
    id: 'exercises.collection.title.confirm-remove-collection-message',
    description: 'message Confirm Remove collection',
    defaultMessage: 'Are you sure you want to delete this collection?'
  },
  modalConfirmRemoveTrainingTitle: {
    id: 'exercises.training.title.confirm-remove-template',
    description: 'title Confirm Remove training',
    defaultMessage: 'Remove training'
  },
  modalConfirmRemoveTrainingMessage: {
    id: 'exercises.training.title.confirm-remove-training-message',
    description: 'message Confirm Remove training',
    defaultMessage: 'Are you sure you want to delete this training?'
  },
  modalConfirmRemoveProgramTitle: {
    id: 'exercises.program.title.confirm-remove-program',
    description: 'title Confirm Remove Program',
    defaultMessage: 'Remove program'
  },
  modalConfirmRemoveProgramMessage: {
    id: 'exercises.program.title.confirm-remove-program-message',
    description: 'message Confirm Remove Program',
    defaultMessage: 'Are you sure you want to delete this program?'
  },
  modalConfirmRemovePersonInjuryTitle: {
    id: 'rehab.injury.title.confirm-remove-person-injury',
    description: 'title Confirm Remove Person Injury',
    defaultMessage: 'Remove injury'
  },
  modalConfirmRemovePersonInjuryMessage: {
    id: 'rehab.injury.title.confirm-remove-person-injury-message',
    description: 'message Confirm Remove Person Injury',
    defaultMessage: 'Are you sure you want to delete this injury?'
  },
  modalConfirmRemoveScreeningTitle: {
    id: 'rehab.screening.title.confirm-remove-screening',
    description: 'title Confirm Remove Screening',
    defaultMessage: 'Remove screening'
  },
  modalConfirmRemoveScreeningMessage: {
    id: 'rehab.screening.title.confirm-remove-screening-message',
    description: 'message Confirm Remove Screening',
    defaultMessage: 'Are you sure you want to delete this screening?'
  },

  // 20 - './containers/partials/cards/messages.js'
  cardTitleSporter: {
    id: 'athletes.athlete.title.athlete',
    description: 'title Athlete Details',
    defaultMessage: 'Athlete details'
  },
  cardLabelFirstname: {
    id: 'athletes.athlete.label.firstname',
    description: 'label First Name',
    defaultMessage: 'First name'
  },
  cardLabelLastname: {
    id: 'athletes.athlete.label.lastname',
    description: 'label Last Name',
    defaultMessage: 'Last name'
  },
  cardLabelBirthdate: {
    id: 'athletes.athlete.label.birthdate',
    description: 'label Birthdate',
    defaultMessage: 'Date of birth'
  },
  cardLabelGender: {
    id: 'athletes.athlete.label.gender',
    description: 'label Gender',
    defaultMessage: 'Sex'
  },
  cardLabelDate: {
    id: 'athletes.athlete.label.date',
    description: 'label Date',
    defaultMessage: 'Date'
  },
  cardLabelUid: {
    id: 'athletes.athlete.label.uid',
    description: 'label Uid',
    defaultMessage: 'Uid code'
  },
  cardLabelNotes: {
    id: 'athletes.athlete.label.notes',
    description: 'label Notes',
    defaultMessage: 'Notes'
  },
  cardLabelDominantHand: {
    id: 'athletes.athlete.label.dominant-hand',
    description: 'label Dominant Hand',
    defaultMessage: 'Dominant hand'
  },
  cardLabelEmail: {
    id: 'athletes.athlete.label.email',
    description: 'label Email',
    defaultMessage: 'E-mail address'
  },
  cardButtonSave: {
    id: 'global.button.save',
    description: 'button Save',
    defaultMessage: 'Save'
  },
  cardButtonCancel: {
    id: 'global.button.cancel',
    description: 'Button Cancel',
    defaultMessage: 'Cancel'
  },
  cardButtonClose: {
    id: 'athletes.athlete.button.close',
    description: 'button Close',
    defaultMessage: 'Close'
  },
  cardButtonExport: {
    id: 'athletes.athlete.button.export',
    description: 'button Export',
    defaultMessage: 'export'
  },
  cardButtonShowAll: {
    id: 'athletes.athlete.button.show-all-sports',
    description: 'button Show All Sports',
    defaultMessage: 'Show all {count} sports...'
  },
  cardButtonShowLess: {
    id: 'athletes.athlete.button.show-less-sports',
    description: 'button Show Less Sports',
    defaultMessage: 'Show less sports'
  },
  cardButtonShowQuestions: {
    id: 'athletes.athlete.button.show-all-sports-questions',
    description: 'button Show All questions',
    defaultMessage: 'Show all {count} questions...'
  },
  cardButtonShowLessQuestions: {
    id: 'athletes.athlete.button.show-less-sports-questions',
    description: 'button Show Less questions',
    defaultMessage: 'Show less questions'
  },
  cardLabelTitleSports: {
    id: 'athletes.athlete.title.sports',
    description: 'title Best Fitting Sports',
    defaultMessage: 'Best fitting sports'
  },
  cardLabelNotAllTestsCompleted: {
    id: 'athletes.athlete.message.not-all-tests-completed',
    description: 'label Not All Tests Completed',
    defaultMessage: 'Not all data is available to show sports...'
  },
  cardLabelNoTests: {
    id: 'athletes.athlete.message.no-tests',
    description: 'label No Tests',
    defaultMessage: 'No tests added yet...'
  },
  cardLabelNoTestsSubText: {
    id: 'athletes.athlete.message.no-tests-subtext',
    description: 'sublabel No Tests',
    defaultMessage: `Click 'Start session' to collect data.`
  },
  cardButtonStartSession: {
    id: 'athletes.athlete.button.start-session',
    description: 'button Start Session',
    defaultMessage: 'Start session'
  },
  cardMenuItemOpenTestApp: {
    id: 'athletes.athlete.button.open-test-app',
    description: 'button Open Test App',
    defaultMessage: 'Open test app'
  },
  cardMenuItemCopyUrl: {
    id: 'athletes.athlete.menu-item.copy-url',
    description: 'menu item Copy Url',
    defaultMessage: 'Copy url...'
  },
  cardMenuItemEnterTestResults: {
    id: 'athletes.athlete.menu-item.enter-test-results',
    description: 'menu item Enter Test Results',
    defaultMessage: 'Enter test results'
  },
  cardLabelNoData: {
    id: 'athletes.athlete.message.no-data',
    description: 'label No Data',
    defaultMessage: 'No data'
  },
  cardLabelShimSoleus: {
    id: 'mts.label.shim-soleus-carnosine',
    description: 'label Shim Soleus Carnosine',
    defaultMessage: 'Shim Soleus Carnosine (Hz)'
  },
  cardLabelShimSoleusWaterPhase: {
    id: 'mts.label.shim-soleus-water-phase',
    description: 'label Shim Soleus Water Phase',
    defaultMessage: 'Shim Soleus Water Phase (Hz)'
  },
  cardLabelShimSoleusWaterContent: {
    id: 'mts.label.shim-soleus-water-content',
    description: 'label Shim Soleus Water Content',
    defaultMessage: 'Shim Soleus Water Content (Hz)'
  },
  cardLabelShimGastro: {
    id: 'mts.label.shim-gastro-carnosine',
    description: 'label Shim Gastro Carnosine',
    defaultMessage: 'Shim Gastro Carnosine (Hz)'
  },
  cardLabelShimGastroWaterPhase: {
    id: 'mts.label.shim-gastro-water-phase',
    description: 'label Shim Gastro Water Phase',
    defaultMessage: 'Shim Gastro Water Phase (Hz)'
  },
  cardLabelShimGastroWaterContent: {
    id: 'mts.label.shim-gastro-water-content',
    description: 'label Shim Gastro Water Content',
    defaultMessage: 'Shim Gastro Water Content (Hz)'
  },
  cardLabelCalibration: {
    id: 'mts.label.calibration',
    description: 'label Calibration',
    defaultMessage: 'Calibration'
  },
  cardLabelNoScanner: {
    id: 'mts.message.no-scanner-for-session',
    description: 'label No Scanner for Session',
    defaultMessage: 'No scanner defined for session!'
  },
  cardLabelNoCalibrationsForScanner: {
    id: 'mts.message.no-calibrations-for-scanner',
    description: 'label No Calibrations for Scanner',
    defaultMessage: 'No calibrations found for defined scanner!'
  },
  cardLabelSportclub: {
    id: 'mts.person.label.sportclub',
    description: 'label Sportclub',
    defaultMessage: 'Sportclub'
  },
  cardLabelSport: {
    id: 'mts.person.label.sport',
    description: 'label Sport',
    defaultMessage: 'Sport'
  },
  cardLabelOther: {
    id: 'mts.person.label.outer',
    description: 'label Other',
    defaultMessage: 'Other'
  },
  cardLabelNoSpecificSport: {
    id: 'mts.person.label.no-specific-sport',
    description: 'label No Specific Sport',
    defaultMessage: 'No specific sport'
  },
  cardLabelWeight: {
    id: 'mts.person.label.weight',
    description: 'label Weight',
    defaultMessage: 'Weight (kg)'
  },
  cardLabelLength: {
    id: 'mts.person.label.height',
    description: 'label Length',
    defaultMessage: 'Length (cm)'
  },
  cardLabelCreatineCheckbox: {
    id: 'mts.person.label.creatine',
    description: 'label Creatine',
    defaultMessage: 'Creatine'
  },
  cardLabelCreatineSince: {
    id: 'mts.person.label.creatine-since',
    description: 'label Creatine Since',
    defaultMessage: 'Creatine since'
  },
  cardLabelTaurine: {
    id: 'mts.person.label.taurine',
    description: 'label Taurine',
    defaultMessage: 'Taurine'
  },
  cardLabelTaurineSince: {
    id: 'mts.person.label.taurine-since',
    description: 'label Taurine Since',
    defaultMessage: 'Taurine since'
  },
  cardLabelVegetarian: {
    id: 'mts.person.label.vegetarian',
    description: 'label Vegetarian',
    defaultMessage: 'Vegetarian'
  },
  cardLabelBetaAlanine: {
    id: 'mts.person.label.beta-alanine',
    description: 'label Beta Alanine',
    defaultMessage: 'Beta alanine'
  },
  cardLabelSportdisciplinePosition: {
    id: 'mts.person.label.sport-discipline-position',
    description: 'label Sport Discipline / Position',
    defaultMessage: 'Sportdiscipline/position'
  },
  cardLabelVegetarianSince: {
    id: 'mts.person.label.vegetarian-since',
    description: 'label Vegetarian Since',
    defaultMessage: 'Vegetarian since'
  },
  cardLabelBetaAlanineSince: {
    id: 'mts.person.label.beta-alanine-since',
    description: 'label Beta Alanine Since',
    defaultMessage: 'Beta alanine since'
  },
  cardLabelLevel: {
    id: 'mts.person.label.level',
    description: 'label Level',
    defaultMessage: 'Level'
  },
  cardButtonMTSStart: {
    id: 'mts.button.start',
    description: 'button MTS Start',
    defaultMessage: `Start`
  },
  cardLabelMTSStartInfo: {
    id: 'mts.label.start-info',
    description: 'label MTS Start Information',
    defaultMessage: `Click 'Start' to collect data.`
  },
  cardLabelUploadFilesTitle: {
    id: 'uploadfiles.title.upload-files',
    description: 'title Upload Files',
    defaultMessage: `Upload files`
  },
  cardMenuItemRemoveAllFiles: {
    id: 'uploadfiles.menu-item.remove-all',
    description: 'menu item Remove All Files',
    defaultMessage: `Remove all files`
  },
  cardMenuItemDownloadAllFiles: {
    id: 'uploadfiles.menu-item.download-all',
    description: 'menu item Download All Files',
    defaultMessage: `Download all files`
  },
  cardImportSportersTitle: {
    id: 'athletes.import-athletes.title.import-athletes',
    description: 'title Import Athletes',
    defaultMessage: `Import athletes`
  },
  cardImportSportersSubTitle: {
    id: 'athletes.import-athletes.title.import-athletes-subtitle',
    description: 'subtitle Import Athletes',
    defaultMessage: `Import multiple athletes at once through a spreadsheet`
  },
  cardImportSportersIdentifyingSporters: {
    id: 'athletes.import-athletes.message.identifying-athletes',
    description: 'message Import Athletes Identifying Athletes',
    defaultMessage: `Identifying athletes...`
  },
  cardImportSportersUploadingFile: {
    id: 'athletes.import-athletes.message.uploading-file',
    description: 'message Import Athletes Uploading File',
    defaultMessage: `Uploading {extension} file...`
  },
  cardImportSportersResultQuestion: {
    id: 'athletes.import-athletes.message.result-question',
    description: 'message Import Athletes Result Question',
    defaultMessage: `{count} rows have been identified. There are {errors} issues that needs to be revised?`
  },
  cardImportSportersFailed: {
    id: 'athletes.import-athletes.error.failed',
    description: 'error Import Athletes',
    defaultMessage: `Unable to identify athletes, please use the correct formatting.`
  },
  cardImportSportersImporting: {
    id: 'athletes.import-athletes.message.importing',
    description: 'message Import Athletes',
    defaultMessage: `Your import has started. This can take some time. Reload the page as long as you don't see all imported athletes in the list.`
  },
  cardImportSportersRetryButton: {
    id: 'athletes.import-athletes.button.retry',
    description: 'button Import Athletes Retry',
    defaultMessage: `Retry`
  },
  cardImportSportersCloseButton: {
    id: 'athletes.import-athletes.button.close',
    description: 'button Import Athletes Close',
    defaultMessage: `Close`
  },
  cardImportSportersAddButton: {
    id: 'athletes.import-athletes.button.add',
    description: 'button Import Athletes Add Athletes',
    defaultMessage: `Add athletes ({count})`
  },
  cardImportSportersCheckButton: {
    id: 'athletes.import-athletes.button.check',
    description: 'button Import Athletes Check Athlete changes',
    defaultMessage: `Recheck problems`
  },
  cardImportSportersDragInfo: {
    id: 'athletes.import-athletes.message.athletes-drag-info',
    description: 'message Import Athletes Drag Information',
    defaultMessage: `Drag'n drop a spreadsheet (xls, xlsx, csv)`
  },
  cardImportSportersFileInfo: {
    id: 'athletes.import-athletes.message.athletes-file-info',
    description: 'message Import Athletes File Information',
    defaultMessage: `How to format your spreadsheet?`
  },
  cardImportSportersBrowseButton: {
    id: 'athletes.import-athletes.button.browse',
    description: 'button Import Athletes Browse',
    defaultMessage: 'Browse'
  },
  cardImportSportersDownloadExampleButton: {
    id: 'athletes.import-athletes.button.download-example',
    description: 'button Import Athletes Download Example',
    defaultMessage: 'Download template'
  },
  cardImportSportersErrorRowLabel: {
    id: 'athletes.import-athletes.error.label.row',
    description: 'error row Import Athletes',
    defaultMessage: 'Row {row}'
  },
  cardImportSportersErrorNoValidDate: {
    id: 'athletes.import-athletes.error.no-valid-birthdate',
    description: 'error Import Athletes No Valid Birthdate',
    defaultMessage: 'Invalid birthdate'
  },
  cardImportSportersErrorNoFirstname: {
    id: 'athletes.import-athletes.error.no-firstname',
    description: 'error Import Athletes No Firstname',
    defaultMessage: 'Empty firstname'
  },
  cardImportSportersErrorNoLastname: {
    id: 'athletes.import-athletes.error.no-lastname',
    description: 'error Import Athletes No Lastname',
    defaultMessage: 'Empty lastname'
  },
  cardImportSportersErrorNoGender: {
    id: 'athletes.import-athletes.error.no-gender',
    description: 'error Import Athletes No Gender',
    defaultMessage: 'Empty sex'
  },
  cardImportSportersErrorExistingUid: {
    id: 'athletes.import-athletes.error.existing-uid',
    description: 'error Import Athletes Existing Uid',
    defaultMessage: 'Existing uid'
  },
  cardImportSportersErrorExistingPerson: {
    id: 'athletes.import-athletes.error.existing-person',
    description: 'error Import Athletes Existing Person',
    defaultMessage: 'Existing person'
  },
  cardImportBenchmarkTitle: {
    id: 'benchmarks.import-benchmark.title.import-benchmark',
    description: 'title Import Benchmark',
    defaultMessage: `Import benchmark data`
  },
  cardImportBenchmarkSubTitle: {
    id: 'benchmarks.import-benchmark.title.import-benchmark-subtitle',
    description: 'subtitle Import Benchmark',
    defaultMessage: `Import benchmark data at once through a spreadsheet`
  },
  cardImportBenchmarkResult: {
    id: 'benchmarks.import-benchmark.message.result',
    description: 'message Import Benchmark Result',
    defaultMessage: 'Benchmark data has been imported'
  },
  cardImportBenchmarkDragInfo: {
    id: 'benchmarks.import-benchmark.message.drag-info',
    description: 'message Import Benchmark Drag Information',
    defaultMessage: `Drag'n drop a spreadsheet (xls, xslx)`
  },
  cardImportBenchmarkIdentifying: {
    id: 'benchmarks.import-benchmark.message.identifying',
    description: 'message Import Benchmark Identifying',
    defaultMessage: `Identifying benchmark data...`
  },
  cardImportBenchmarkClose: {
    id: 'benchmarks.import-benchmark.button.close',
    description: 'button Import Benchmark Close',
    defaultMessage: 'Close'
  },
  cardImportBenchmarkFailed: {
    id: 'benchmarks.import-benchmark.error.failed',
    description: 'error Import benchmark',
    defaultMessage: `Unable to upload benchmark, please use the correct formatting.`
  },
  cardImportBenchmarkErrorsInfoLabel: {
    id: 'benchmarks.import-benchmark.error.label.info',
    description: 'error information Import benchmarks',
    defaultMessage:
      'This is a message to show you the errors found in your spreadsheet.'
  },
  cardLabelTitleSoleus: {
    id: 'mts.testdata.label.soleus',
    description: 'label title Soleus',
    defaultMessage: 'Soleus'
  },
  cardLabelTitleGastro: {
    id: 'mts.testdata.label.gastro',
    description: 'label title Gastro',
    defaultMessage: 'Gastro'
  },
  cardLabelCarnosine: {
    id: 'mts.testdata.label.carnosine',
    description: 'label Carnosine',
    defaultMessage: 'Carnosine (mM)'
  },
  cardLabelCreatine: {
    id: 'mts.testdata.label.creatine',
    description: 'label Creatine',
    defaultMessage: 'Creatine (mM)'
  },
  cardLabelTma: {
    id: 'mts.testdata.label.tma',
    description: 'label TMA',
    defaultMessage: 'TMA (mM)'
  },
  cardLabelVoxelSize: {
    id: 'mts.testdata.label.voxel-size',
    description: 'label Voxel Size',
    defaultMessage: 'Voxel size (mm)'
  },
  cardLabelVoxelSizeX: {
    id: 'mts.testdata.label.voxel-size-x',
    description: 'label Voxel Size X',
    defaultMessage: 'x'
  },
  cardLabelVoxelSizeY: {
    id: 'mts.testdata.label.voxel-size-y',
    description: 'label Voxel Size Y',
    defaultMessage: 'y'
  },
  cardLabelVoxelSizeZ: {
    id: 'mts.testdata.label.voxel-size-z',
    description: 'label Voxel Size Z',
    defaultMessage: 'z'
  },
  cardLabelWaterSignal: {
    id: 'mts.testdata.label.water-signal',
    description: 'label Water Signal',
    defaultMessage: 'Signal water (mM)'
  },
  cardSendReportToSporter: {
    id: 'sessions.report.button.send-report-to-athlete',
    description: 'button Send Report to Athlete',
    defaultMessage: 'Send report to athlete'
  },
  modalReportSendToSporterSuccessTitle: {
    id: 'sessions.report.message.send-to-athlete-success.title',
    description: 'title Send Report to Athlete Success',
    defaultMessage: 'Mail send'
  },
  modalReportSendToSporterSuccessMessage: {
    id: 'sessions.report.message.send-to-athlete-success.message',
    description: 'message Send Report to Athlete Success',
    defaultMessage: 'Email was successfully sent to the athlete.'
  },
  modalReportSendToSporterFailedNoEmailTitle: {
    id: 'sessions.report.message.send-to-athlete-failed-no-email.title',
    description: 'title Send Report to Athlete Failed No Email',
    defaultMessage: 'Mail failed!'
  },
  modalReportSendToSporterFailedNoEmailMessage: {
    id: 'sessions.report.message.send-to-athlete-failed-no-mail.message',
    description: 'message Send Report to Athlete Failed No Email',
    defaultMessage:
      'Athlete has no email or it is not valid. Fill in a valid email in the athlete panel.'
  },
  modalReportSendToSporterFailedTitle: {
    id: 'sessions.report.message.send-to-athlete-failed.title',
    description: 'title Send Report to Athlete Failed',
    defaultMessage: 'Mail failed!'
  },
  modalReportSendToSporterFailedMessage: {
    id: 'sessions.report.message.send-to-athlete-failed.message',
    description: 'message Send Report to Athlete Failed',
    defaultMessage: 'Mail failed to send. Try it again later...'
  },
  modalZipSendToUserSuccessTitle: {
    id: 'sessions.report.message.zip-send-to-athlete-success.title',
    description: 'title Zip Send Report to Athlete Success',
    defaultMessage: 'Mail send'
  },
  modalZipSendToUserSuccessMessage: {
    id: 'sessions.report.message.zip-send-to-athlete-success.message',
    description: 'message Zip Send Report to Athlete Success',
    defaultMessage: 'You will get an email in few moments with a zip file.'
  },
  modalZipSendToUserFailedTitle: {
    id: 'sessions.report.message.zip-send-to-athlete-failed.title',
    description: 'title Zip Send Report to Athlete Failed',
    defaultMessage: 'Error'
  },
  modalZipSendToUserFailedMessage: {
    id: 'sessions.report.message.zip-send-to-athlete-failed.message',
    description: 'message Zip Send Report to Athlete Failed',
    defaultMessage:
      'Something went wrong with mailing the zip file. Try it again layer or contact the administrator'
  },
  modalExportSessionsTitle: {
    id: 'sessions.export-sessions.title.export-sessions',
    description: 'title Export Sessions',
    defaultMessage: 'Export Sessions in Periode of Time'
  },
  modalExportSessionsSubTitle: {
    id: 'sessions.export-sessions.title.export-sessions-subtitle',
    description: 'subtitle Export Sessions',
    defaultMessage: 'Receive an e-mail with the sessions'
  },
  modalExportSessionsEmailSent: {
    id: 'sessions.export-sessions.message.email-sent',
    description: 'message Export Sessions Success',
    defaultMessage: 'E-mail sent to: {email}'
  },
  cardLabelFrom: {
    id: 'sessions.export-sessions.label.from',
    description: 'label From Date',
    defaultMessage: 'From'
  },
  cardLabelTill: {
    id: 'sessions.export-sessions.label.till',
    description: 'label Till Date',
    defaultMessage: 'Till'
  },
  modalExportSessionsInValidDates: {
    id: 'sessions.export-sessions.error.invalid-dates',
    description: 'error Export Sessions Invalid Dates',
    defaultMessage: 'From date must be earlier then till date.'
  },
  modalExportSessionsNoSessionsFound: {
    id: 'sessions.export-sessions.error.no-sessions-found',
    description: 'error Export Sessions No Sessions Found',
    defaultMessage: 'No sessions found between given dates.'
  },
  cardTitleExercise: {
    id: 'exercises.exercise.title.exercise',
    description: 'title Exercise',
    defaultMessage: 'Info'
  },
  cardExercisePracticalTitle: {
    id: 'exercises.exercise.title.exercise-practical',
    description: 'title Exercise Practical',
    defaultMessage: 'Exercise'
  },
  exercisesLabelTitle: {
    id: 'exercises.exercise.label.title',
    description: 'label Exercises Title',
    defaultMessage: 'Title exercise'
  },
  exercisesLabelProtocol: {
    id: 'exercises.exercise.label.protocal',
    description: 'label Exercises Protocol',
    defaultMessage: 'Protocol'
  },
  exercisesLabelFotoAndVideo: {
    id: 'exercises.exercise.label.foto-video',
    description: 'label Exercises Foto & Video',
    defaultMessage: 'Foto & Video'
  },
  exercisesWarningFotoAndVideo: {
    id: 'exercises.exercise.warning.foto-video',
    description: 'warning if Exercises is not saved',
    defaultMessage:
      "Please save the exercise before you can upload foto's or video's"
  },
  exercisesLabelNotes: {
    id: 'exercises.exercise.label.notes',
    description: 'label Exercises Notes',
    defaultMessage: 'Notes'
  },
  exercisesLabelRemarks: {
    id: 'exercises.exercise.label.remarks',
    description: 'label Exercises Remarks',
    defaultMessage: 'Remarks'
  },
  exercisesLabelLevel: {
    id: 'exercises.exercise.label.level',
    description: 'label Exercises Level',
    defaultMessage: 'Level'
  },
  tagsLabel: {
    id: 'global.label.tags',
    description: 'label Filter Tags',
    defaultMessage: 'Filter by Tags'
  },
  tagsPlaceHolder: {
    id: 'global.label.tags-placeholder',
    description: 'placeholder Tags',
    defaultMessage: 'select one or more tags'
  },
  tagsNoOption: {
    id: 'global.label.tags-no-more-tags',
    description: 'option No More Tags',
    defaultMessage: 'There are no more tags'
  },
  noteSaved: {
    id: 'global.message.note-saved',
    description: 'message Note Saved',
    defaultMessage: `Note is saved`
  },
  noteRemove: {
    id: 'global.message.note-removed',
    description: 'message Note Removed',
    defaultMessage: `Note is removed`
  },

  // 21 - './containers/partials/error-boundary/messages.js'
  /*
   * Inputduration Messages
   *
   * This contains all the text for the Inputduration component.
   */
  title: {
    id: 'global.error.hmmm.title',
    description: 'title Something Went Wrong',
    defaultMessage: 'Hmmn something went wrong...'
  },
  message: {
    id: 'global.error.hmmm.message',
    description: 'message Something Went Wrong',
    defaultMessage: 'Please try again...'
  },
  retry: {
    id: 'global.error.hmmm.retry',
    description: 'button Something Went Wrong Retry',
    defaultMessage: 'Retry'
  },
  titleTests: {
    id: 'sessions.test-result.title.tests',
    description: 'title Tests',
    defaultMessage: 'Tests'
  },

  // 22 - './containers/partials/modals/messages.js'
  modalCopyUrlSubtitle: {
    id: 'global.message.copy-url-subtitle',
    description: 'subtitle Copy Url',
    defaultMessage: `Send this link to the user(s) who'll take the tests`
  },
  modalCopyUrlsSubtitle: {
    id: 'global.message.copy-urls-subtitle',
    description: 'subtitle Copy Urls',
    defaultMessage: `Send this links to the user(s) who'll take the tests`
  },
  modalCopyUrlTitle: {
    id: 'global.message.copy-url-title',
    description: 'title Copy Url',
    defaultMessage: 'Copy session url'
  },
  modalCopyUrlsTitle: {
    id: 'global.message.copy-urls-title',
    description: 'title Copy Urls',
    defaultMessage: 'Copy session urls'
  },
  modalButtonCopy: {
    id: 'global.button.copy-count',
    description: 'button Copy Count',
    defaultMessage: 'Copy ({count})'
  },
  modalButtonCopyOrMove: {
    id: 'global.button.copy-or-move-count',
    description: 'button Copy/Move Count',
    defaultMessage:
      '{copy, select, true {Add {count} {count, plural, =1 {athlete} other {athletes}}} other {Move {count} {count, plural, =1 {athlete} other {athletes}}}}'
  },
  modalButtonRetry: {
    id: 'global.button.retry-count',
    description: 'button Retry Count',
    defaultMessage: 'Retry ({count})'
  },
  modalLabelUrl: {
    id: 'sessions.label.url',
    description: 'label Session Url',
    defaultMessage: 'Session URL'
  },
  modalLabelUrls: {
    id: 'sessions.label.urls',
    description: 'label Session Urls',
    defaultMessage: 'Session URLs'
  },
  modalButtonCopyToClipboard: {
    id: 'global.button.copy-to-clipboard',
    description: 'button Copy to Clipboard',
    defaultMessage: 'Copy to clipboard'
  },
  modalCopyOrMoveSporterTitle: {
    id: 'athletes.title.copy-or-move-athlete',
    description: 'title Copy/Move Athletes',
    defaultMessage:
      '{copy, select, true {Add (copy) athlete(s) to one or more group(s)} other {Move athlete(s) to other group(s)}}'
  },
  modalCopyOrMoveSporterSubtitle: {
    id: 'athletes.title.copy-or-move-athlete-subtitle',
    description: 'subtitle Copy/Move Athletes',
    defaultMessage:
      '{copy, select, true {Add} other {Move}} <b>{count}</b> {count, plural, =1 {athlete} other {athletes}}: <b>{listAthletes}</b> to {selectedGroupsLength, plural, =0 {the group(s) you select from the list below:} =1 {<b>{selectedGroupsLength}</b> group (<b>{selectedGroupsList}</b>) selected from the list below.} other {<b>{selectedGroupsLength}</b> groups (<b>{selectedGroupsList}</b>) selected from the list below.}}'
  },
  modalListItemSportersGroupsCount: {
    id: 'modal.list-item.athletes-group-count',
    description: 'list item Athletes Groups Count',
    defaultMessage:
      '{groups, plural, =0 {} =1 {# subgroup, } other {# subgroups, }}{athletes, plural, =1 {# athlete} other {# athletes}}'
  },
  modalMoveSporterFailed: {
    id: 'global.error.move-athlete-failed',
    description: 'error Move Athletes',
    defaultMessage: `No athlete(s) moved to selected group. See error(s):`
  },
  modalCopySporterFailed: {
    id: 'global.error.copy-athlete-failed',
    description: 'error Copy Athletes',
    defaultMessage: `No athlete(s) copied to selected group. See error(s):`
  },
  modalMoveSporterHasErrors: {
    id: 'global.error.move-athlete-has-errors',
    description: 'error Move Athletes Count',
    defaultMessage: `{count} athlete(s) failed to move to selected group. See error(s):`
  },
  modalCopySporterHasErrors: {
    id: 'global.error.copy-athlete-has-errors',
    description: 'error Copy Athletes Count',
    defaultMessage: `{count} athlete(s) failed to copy to selected group. See error(s):`
  },
  modalCopyMoveSporterErrorExistingSporter: {
    id: 'global.error.copy-move-athlete-existing-athlete',
    description: 'error Copy / Move Athletes Existing Athlete',
    defaultMessage: `Athlete already exists in selected group`
  },
  modalCopyMoveSporterErrorUnknownReason: {
    id: 'global.error.copy-move-athlete-unknown-reason',
    description: 'error Copy / Move Athletes Unknown Reason',
    defaultMessage: `Unknown reason`
  },
  modalBenchmarkLabelName: {
    id: 'benchmarks.benchmark.label.name',
    description: 'label Benchmark Name',
    defaultMessage: 'Benchmark name'
  },
  modalBenchmarkLabelType: {
    id: 'benchmarks.benchmark.label.type',
    description: 'label Benchmark Type',
    defaultMessage: 'Benchmark type'
  },
  modalBenchmarkLabelTypeAge: {
    id: 'benchmarks.benchmark.label.type-age',
    description: 'label Benchmark Type Age',
    defaultMessage: 'Age'
  },
  modalBenchmarkLabelTypeBioAge: {
    id: 'benchmarks.benchmark.label.type-bio-age',
    description: 'label Benchmark Type Biological Age',
    defaultMessage: 'Biological age'
  },
  modalBenchmarkLabelNotes: {
    id: 'benchmarks.benchmark.label.notes',
    description: 'label Benchmark Notes',
    defaultMessage: 'Notes'
  },
  modalAddBenchmarkTitle: {
    id: 'benchmarks.title.add-benchmark',
    description: 'title Benchmark Add',
    defaultMessage: 'Add benchmark'
  },
  modalAddBenchmarkSubtitle: {
    id: 'benchmarks.title.add-benchmark-subtitle',
    description: 'subtitle Benchmark Add',
    defaultMessage: 'Add benchmark to your organisation'
  },
  modalEditBenchmarkTitle: {
    id: 'benchmarks.title.edit-benchmark',
    description: 'title Benchmark Edit',
    defaultMessage: 'Edit benchmark'
  },
  modalEditBenchmarkSubtitle: {
    id: 'benchmarks.title.edit-benchmark-subtitle',
    description: 'subtitle Benchmark Edit',
    defaultMessage: 'Edit your benchmark name or note.'
  },
  modalCopyBenchmarkTitle: {
    id: 'benchmarks.title.copy-benchmark',
    description: 'title Benchmark Copy',
    defaultMessage: 'Copy benchmark'
  },
  modalCopyBenchmarkSubtitle: {
    id: 'benchmarks.title.copy-benchmark-subtitle',
    description: 'subtitle Benchmark Copy',
    defaultMessage: 'Copy this benchmark to your organisation'
  },
  modalBenchmarkButtonCreate: {
    id: 'benchmarks.benchmark.button.create',
    description: 'button Benchmark Create',
    defaultMessage: 'Create'
  },
  modalBenchmarkButtonSave: {
    id: 'benchmarks.benchmark.button.save',
    description: 'button Benchmark Save',
    defaultMessage: 'Save'
  },
  modalBenchmarkErrorMessage: {
    id: 'benchmarks.benchmark.error.hmmm',
    description: 'error Benchmark Something Went Wrong',
    defaultMessage: 'Hmmm something went wrong, try it again later.'
  },
  modalBenchmarkErrorExistingName: {
    id: 'benchmarks.benchmark.error.existing-name',
    description: 'error Benchmark Existing Name',
    defaultMessage: 'Benchmark name already exists, choose another name.'
  },
  modalTestSetLabelName: {
    id: 'testsets.testset.label.name',
    description: 'label Testset Name',
    defaultMessage: 'Test set name'
  },
  modalTestSetLabelNotes: {
    id: 'testsets.testset.label.notes',
    description: 'label Testset Notes',
    defaultMessage: 'Notes'
  },
  modalAddTestSetTitle: {
    id: 'testsets.title.add-testset',
    description: 'title Testset',
    defaultMessage: 'Add test set'
  },
  modalAddTestSetSubtitle: {
    id: 'testsets.title.add-testset-subtitle',
    description: 'subtitle Testset',
    defaultMessage: 'Add test set to your organisation'
  },
  modalEditTestSetTitle: {
    id: 'testsets.title.edit-testset',
    description: 'title Testset Edit',
    defaultMessage: 'Edit test set'
  },
  modalEditTestSetSubtitle: {
    id: 'testsets.title.edit-testset-subtitle',
    description: 'subtitle Testset Edit',
    defaultMessage: 'Edit your test set name or notes.'
  },
  modalCopyTestSetTitle: {
    id: 'testsets.title.copy-testset',
    description: 'title Testset Copy',
    defaultMessage: 'Copy test set'
  },
  modalCopyTestSetSubtitle: {
    id: 'testsets.title.copy-testset-subtitle',
    description: 'subtitle Testset Copy',
    defaultMessage: 'Copy this test set to your organisation'
  },
  modalTestSetButtonCreate: {
    id: 'testsets.testset.button.create',
    description: 'button Testset Create',
    defaultMessage: 'Create'
  },
  modalTestSetButtonSave: {
    id: 'testsets.testset.button.save',
    description: 'button Testset Save',
    defaultMessage: 'Save'
  },
  modalTestSetErrorExistingName: {
    id: 'testsets.testset.error.existing-name',
    description: 'error Testset Existing Name',
    defaultMessage: 'Test set name already exists, choose another name.'
  },
  exercisesLabelCues: {
    id: 'exercises.exercise.label.cues',
    description: 'label Exercises Cues',
    defaultMessage: 'Cues'
  },
  exercisesLabelTags: {
    id: 'exercises.exercise.label.tags',
    description: 'label Exercises Tags',
    defaultMessage: 'Tags'
  },

  // 23 - './containers/partials/no-roles/messages.js'
  noRolesTitle: {
    id: 'settings.user.message.no-roles',
    description: 'title No Roles',
    defaultMessage: 'No roles'
  },
  noRolesMessage: {
    id: 'settings.user.message.no-roles-message',
    description: 'message No Roles',
    defaultMessage: 'Please contact the Administrator'
  },

  // 24 - './containers/partials/file-upload/messages.js'
  fileUploadLabelBrowse: {
    id: 'partials.file-upload.label.browse',
    description: 'label File Upload Browse',
    defaultMessage: 'Browse'
  },
  fileUploadGeneratingThumbnails: {
    id: 'partials.file-upload.message.generating-thumbs',
    description: 'message uploading/generating thumbs',
    defaultMessage: 'generating thumbs'
  },
  fileUploadLabelMultiInfo: {
    id: 'partials.file-upload.label.multi-info',
    description: 'label File Upload Multiple Information',
    defaultMessage:
      'Drag & drop files to here or click "Browse" for uploading files...'
  },
  modalConfirmRemoveFileTitle: {
    id: 'partials.file-upload.message.remove-file.title',
    description: 'title Confirm Remove File',
    defaultMessage: 'Remove file'
  },
  modalConfirmRemoveFileMessage: {
    id: 'partials.file-upload.message.remove-file.message',
    description: 'message Confirm Remove File',
    defaultMessage: `Are you sure you want to delete {file}?`
  },
  modalConfirmRemoveThisFileMessage: {
    id: 'partials.file-upload.message.remove-this-file.message',
    description: 'message Confirm Remove this File',
    defaultMessage: `Are you sure you want to delete this file?`
  },
  modalConfirmRemoveFileAcceptButton: {
    id: 'partials.file-upload.button.confirm-remove-file-accept',
    description: 'button Confirm Remove File Accept',
    defaultMessage: 'Remove'
  },
  modalConfirmRemoveFileDismissButton: {
    id: 'partials.file-upload.button.confirm-remove-file-dismiss',
    description: 'button Confirm Remove File Dismiss',
    defaultMessage: 'Cancel'
  },
  fileUploadLabelShowAllFiles: {
    id: 'partials.file-upload.label.show-all-files',
    description: 'label File Upload Show All Files',
    defaultMessage: 'Show all {count} files...'
  },
  fileUploadLabelShowLessFiles: {
    id: 'partials.file-upload.label.show-less-files',
    description: 'label File Upload Show Less Files',
    defaultMessage: 'Show less files'
  },
  fileUploadVideoNotAvailableTooltipMessage: {
    id: 'exercise.uppy.file-unavailable.tooltip',
    description: 'label tooltip when file unavailable',
    defaultMessage: "We're sorry, but this file isn't available yet."
  },
  fileUploadVideoFailedTooltipMessage: {
    id: 'exercise.uppy.file-failed.tooltip',
    description: 'label tooltip when file failed',
    defaultMessage: "We're sorry, but there was an error uploading this file."
  },
  // 25 - './containers/partials/header/messages.js'
  headerSignOut: {
    id: 'partials.header.label.sign-out',
    description: 'label Sign Out',
    defaultMessage: 'Sign out'
  },
  headerAccount: {
    id: 'partials.header.label.account',
    description: 'label account',
    defaultMessage: 'Manage account'
  },
  headerTreeUpLabel: {
    id: 'partials.header.label.sign-in-tree-up',
    description: 'label Sign In Tree Up',
    defaultMessage: 'Parent Organisation:'
  },
  headerTreeOrgsLabel: {
    id: 'partials.header.label.sign-in-tree-organisations',
    description: 'label Sign In Tree Organisations',
    defaultMessage: 'Orgs'
  },

  // Global File
  // 26 - './messages.js'
  sexMale: {
    id: 'global.label.male',
    description: 'label Male',
    defaultMessage: 'Male'
  },
  sexFemale: {
    id: 'global.label.female',
    description: 'label Female',
    defaultMessage: 'Female'
  },
  sideLeft: {
    id: 'global.label.dominant-hand-left',
    description: 'label Dominant Hand Left',
    defaultMessage: 'Left'
  },
  sideRight: {
    id: 'global.label.dominant-hand-right',
    description: 'label Dominant Hand Right',
    defaultMessage: 'Right'
  },
  sporters: {
    id: 'global.label.athletes',
    description: 'label Athletes',
    defaultMessage: 'Athletes'
  },
  buttonOK: {
    id: 'global.button.ok',
    description: 'button OK',
    defaultMessage: 'OK'
  },
  cancelButton: {
    id: 'global.button.cancel',
    description: 'Button Cancel',
    defaultMessage: 'Cancel'
  },
  saveButton: {
    id: 'global.button.save',
    description: 'button Save',
    defaultMessage: 'Save'
  },
  sendButton: {
    id: 'global.button.send',
    description: 'button send',
    defaultMessage: 'Send'
  },
  finishButton: {
    id: 'global.button.finish',
    description: 'button Finish',
    defaultMessage: 'Finish'
  },
  seeReportButton: {
    id: 'global.button.see-report',
    description: 'button See Report',
    defaultMessage: 'See Report'
  },
  noResultsOption: {
    id: 'global.option.no-results',
    description: 'option No Results',
    defaultMessage: 'None'
  },
  otherResultsOption: {
    id: 'global.option.other-results',
    description: 'option other Results',
    defaultMessage: 'Other'
  },
  addSportersButton: {
    id: 'global.button.add-athletes',
    description: 'button Add Athletes',
    defaultMessage: 'Add athletes'
  },
  addButton: {
    id: 'global.button.add',
    description: 'button Add',
    defaultMessage: 'Add'
  },
  fullEditButton: {
    id: 'global.button.full-edit',
    description: 'button Full Edit',
    defaultMessage: 'Full Edit'
  },
  titleRequired: {
    id: 'global.error.title-required',
    description: 'error Title Required',
    defaultMessage: 'Title is required'
  },
  startdateRequired: {
    id: 'global.error.start-date-required',
    description: 'error Start Date Required',
    defaultMessage: 'Start date is required'
  },
  enddateRequired: {
    id: 'global.error.end-date-required',
    description: 'error end Date Required',
    defaultMessage: 'End date is required'
  },
  enddateNotBeforeStartdata: {
    id: 'global.error.end-date-not-before-start-date',
    description: 'error end Date Not Before Start Date',
    defaultMessage: 'The end date cannot occur earlier than the start date'
  },
  durationRequired: {
    id: 'global.error.duration-required',
    description: 'error Duration Required',
    defaultMessage: 'Duration is required'
  },
  levelRequired: {
    id: 'global.error.level-required',
    description: 'error level Required',
    defaultMessage: 'Level is required'
  },
  typeRequired: {
    id: 'global.error.type-required',
    description: 'error type Required',
    defaultMessage: 'Type is required'
  },
  connectionLost: {
    id: 'global.error.connection-lost',
    description: 'error Connection Lost',
    defaultMessage: 'Connection is lost'
  },
  connectionBack: {
    id: 'global.error.connection-back',
    description: 'message Connection Back',
    defaultMessage: 'Connection is back online'
  },
  errorMessage: {
    id: 'global.error.error-message',
    description: 'label Error Message',
    defaultMessage: 'Error! {message}'
  },
  errorOnRow: {
    id: 'global.error.error-row-message',
    description: 'label Error Row Message',
    defaultMessage: 'Row {message}'
  },
  firstnameRequired: {
    id: 'global.error.firstname-required',
    description: 'error Firstname Required',
    defaultMessage: 'Firstname is required'
  },
  lastnameRequired: {
    id: 'global.error.lastname-required',
    description: 'error Lastname Required',
    defaultMessage: 'Lastname is required'
  },
  genderRequired: {
    id: 'global.error.gender-required',
    description: 'error Gender Required',
    defaultMessage: 'Sex is required'
  },
  emailRequired: {
    id: 'global.error.email-required',
    description: 'error Email Required',
    defaultMessage: 'Email is required'
  },
  emailNotValid: {
    id: 'global.error.email-not-valid',
    description: 'error Email Not Valid',
    defaultMessage: 'Email is not valid'
  },
  birthdateRequired: {
    id: 'global.error.birthate-required',
    description: 'error Birthdate Required',
    defaultMessage: 'Birthdate is required'
  },
  injuryRequired: {
    id: 'global.error.injury-required',
    description: 'error Injury Required',
    defaultMessage: 'Injury is required'
  },
  dateRequired: {
    id: 'global.error.date-required',
    description: 'error Date Required',
    defaultMessage: 'Date is required'
  },
  sideRequired: {
    id: 'global.error.side-required',
    description: 'error Side Required',
    defaultMessage: 'Select at least one side'
  },
  screeningNameRequired: {
    id: 'global.error.screening-name',
    description: 'error Screening name Required',
    defaultMessage: 'Screening name is required'
  },
  screeningDateRequired: {
    id: 'global.error.screening-date-required',
    description: 'error Screening Date Required',
    defaultMessage: 'Screening date is required'
  },
  feedbackRequired: {
    id: 'global.error.feedback-required',
    description: 'error Feedback Required',
    defaultMessage: 'Feedback is required'
  },
  screentypesError: {
    id: 'global.error.loading-screentypes',
    description: 'error Loading Screentypes',
    defaultMessage: 'Error loading screentypes'
  },
  sessionNameRequired: {
    id: 'global.error.session-name-required',
    description: 'error Session Name Required',
    defaultMessage: 'Session name is required'
  },
  testsetTypeRequired: {
    id: 'global.error.testset-type-required',
    description: 'error testset type Required',
    defaultMessage: 'Testset type is required'
  },
  sportRequired: {
    id: 'global.error.sport-required',
    description: 'error sport Required',
    defaultMessage: 'Sport is required'
  },
  labelTestSetType: {
    id: 'global.label.testset-type',
    description: 'label Testset Type',
    defaultMessage: 'Testset Type'
  },
  checkboxSide: {
    id: 'global.label.side-value',
    description: 'checkbox Side',
    defaultMessage:
      '{side, select, NoSide {No Side} Left {Left} Right {Right} other {}}'
  },
  numberValidation: {
    id: 'global.error.valid-number',
    description: 'validation: The value should be a number',
    defaultMessage: 'The value should be a number'
  },
  minimumValidation: {
    id: 'global.error.valid-minimum',
    description: 'validation: The minimum value is ...',
    defaultMessage: 'The minimum value is {value}'
  },
  maximumValidation: {
    id: 'global.error.valid-maximum',
    description: 'validation: The maximum value is ...',
    defaultMessage: 'The maximum value is {value}'
  },
  betweenValidation: {
    id: 'global.error.valid-between',
    description: 'validation: Values between ... and ...',
    defaultMessage: 'Values between {minimum} and {maximum}'
  },
  selectOptionsHints: {
    id: 'global.error.hints-for-input-with-options',
    description: 'Hint for input with options',
    defaultMessage: 'Please select one of the given options'
  },
  labelTrial: {
    id: 'global.label.trial-n',
    description: 'label Trial n',
    defaultMessage: 'Trial {trial}:'
  },
  labelLevel: {
    id: 'global.label.level-n',
    description: 'label Level n',
    defaultMessage: 'Level {level}'
  },
  emptySessionsDataAthlete: {
    id: 'global.message.empty-sessions-data-for-athlete',
    description:
      "message shown when athlete logged in, and doesn't have any sessions",
    defaultMessage: 'There are no completed sessions'
  },
  emptyMedicalDataAthlete: {
    id: 'global.message.empty-medical-data-for-athlete',
    description:
      "message shown when athlete logged in, and doesn't have any medical data",
    defaultMessage: 'There is no medical data'
  },
  emptySessionForAthlete: {
    id: 'global.message.empty-session-for-athlete',
    description:
      "message shown when athlete logged in, and choose a session that doesn't have a report",
    defaultMessage: 'There is not enough data to generate the report'
  },
  emptyProgramExercisesFeedbackAthlete: {
    id: 'global.message.empty-program-exercises-feedback-for-athlete',
    description:
      'message shown when there is no feedback for exercises in a program',
    defaultMessage: 'There is no feedback'
  },
  testItemLabelVideo: {
    id: 'testitem.label.video',
    description: 'label for a test item preview',
    defaultMessage: 'Video'
  },
  exerciseProgramTitleDownloadFailed: {
    id: 'exercises.program.download-failed.title',
    description: '',
    defaultMessage: 'Download rapport failed'
  },
  exerciseCollectionTitleDownloadFailed: {
    id: 'exercises.collection.download-failed.title',
    description: 'collection download failed message',
    defaultMessage: 'Download collection failed'
  },
  exerciseTrainingTitleDownloadFailed: {
    id: 'exercises.training.download-failed.title',
    description: 'training download failed message',
    defaultMessage: 'Download training failed'
  },
  exerciseTemplateTitleDownloadFailed: {
    id: 'exercises.template.download-failed.title',
    description: 'template download failed message',
    defaultMessage: 'Download template failed'
  },
  exerciseProgramMessageDownloadFailed: {
    id: 'exercises.program.download-failed.message',
    description: 'Download failed message for Program report',
    defaultMessage: 'Try it again later.'
  },
  menuItemExerciseDownloadProgram: {
    id: 'global.button.download-program',
    description: 'Download link in menu',
    defaultMessage: 'Download program'
  },
  menuItemExerciseDownloadTemplate: {
    id: 'global.button.download-template',
    description: 'Download template link in menu',
    defaultMessage: 'Download template'
  },
  menuItemExerciseDownloadCollection: {
    id: 'global.button.download-collection',
    description: 'Download collection link in menu',
    defaultMessage: 'Download collection'
  },
  menuItemExerciseDownloadTraining: {
    id: 'global.button.download-training',
    description: 'Download training link in menu',
    defaultMessage: 'Download training'
  },
  sessionReportAthleteNoteFormTitle: {
    id: 'session.report.athlete.comment',
    description: 'Title on the comment input for Athlete report',
    defaultMessage: 'Comment'
  },
  noAthletesOrGroups: {
    id: 'global.list.athletes-groups.no-results',
    description: 'Empty users view',
    defaultMessage: 'There are no groups or athletes in this group'
  },
  talentLabel: {
    id: 'global.label.talent',
    description: 'Readable label for talent sessions',
    defaultMessage: 'Custom'
  },
  benchmarkSaved: {
    id: 'settings.benchmarks.message.saved',
    description: 'message Benchmark item Saved',
    defaultMessage: 'Benchmark is saved'
  },
  messageAddProgramSaveError: {
    id: 'sessions.program.error.save',
    description: 'Error Add program Save',
    defaultMessage: 'Hmm something went wrong, try it again later.'
  },
  messageAddExerciseSuccess: {
    id: 'exercises.exercise.message.add.success',
    description: 'Status notification after exercise add',
    defaultMessage: 'Exercise is created'
  },
  messageEditExerciseSuccess: {
    id: 'exercises.exercise.message.edit.success',
    description: 'Status notification after exercise edit',
    defaultMessage: 'Exercise is updated'
  },
  collectionsTitle: {
    id: 'collections.title',
    description: 'The title of the collections page',
    defaultMessage: 'My Collections'
  },
  collectionsSubtitle: {
    id: 'collections.subtitle',
    description: 'The subtitle of the collections page, tab My Collections',
    defaultMessage: 'My personal collections'
  },
  collectionsSubtitleMine: {
    id: 'collections.subtitle-mine',
    description: 'The subtitle of the collections page, tab My Collections',
    defaultMessage: 'My personal collections'
  },
  collectionsSubtitleOther: {
    id: 'collections.subtitle-other',
    description: 'The subtitle of the collections page, tab Other Collections',
    defaultMessage: 'All other collections'
  },
  collectionsAddTitle: {
    id: 'collections.add.title',
    description: 'The title of the add collections page',
    defaultMessage: 'New Collection'
  },
  collectionsAddExerciseFilterInModalMessage: {
    id: 'collections.add.exercise.filter.modal.message',
    defaultMessage:
      'Select one or more Tags in order to filter and get a subset of exercises. Apply the changes, or cancel, to go back.',
    description:
      'A message shown as a subtitle when the filtering is active on the modal: Add Exercises (to collections)'
  },
  breadcrumbCollections: {
    id: 'collections.breadcrumb.title',
    description: 'The label of the breadcrumb of the collections page',
    defaultMessage: 'My Collections'
  },
  breadcrumbCollection: {
    id: 'collections.collection.breadcrumb.title',
    description: 'The label of the breadcrumb of the collection page',
    defaultMessage: 'Collection'
  },
  trainingsTitle: {
    id: 'trainings.title',
    description: 'The title of the trainings page',
    defaultMessage: 'My Trainings'
  },
  trainingsSubTitle: {
    id: 'trainings.subtitle',
    description: 'The title of the trainings page',
    defaultMessage: 'My personal trainings'
  },
  trainingsAddTitle: {
    id: 'trainings.add.title',
    description: 'The title of the add trainings page',
    defaultMessage: 'New Training'
  },
  labelApplyFilter: {
    id: 'global.filter.apply',
    description: 'Button label',
    defaultMessage: 'Apply Filters'
  },
  breadcrumbTrainings: {
    id: 'trainings.breadcrumb.title',
    description: 'The label of the breadcrumb of the trainings page',
    defaultMessage: 'My Trainings'
  },
  breadcrumbTraining: {
    id: 'trainings.training.breadcrumb.title',
    description: 'The label of the breadcrumb of the training page',
    defaultMessage: 'Training'
  },
  exercisesAddTrainingButton: {
    id: 'exercises.button.add-training',
    description: 'button Exercises Add training',
    defaultMessage: 'Add training'
  },
  trainingsTabTrainings: {
    id: 'trainings.tab.trainings',
    description: 'tab trainings',
    defaultMessage: 'Trainings'
  },
  messageAddTrainingSuccess: {
    id: 'trainings.training.message.add.success',
    description: 'Status notification after training add',
    defaultMessage: 'Training is created'
  },
  trainingNameLabel: {
    id: 'trainings.training.label.name',
    description: 'label training Name',
    defaultMessage: 'Training Name'
  },
  messageEditTrainingSuccess: {
    id: 'trainings.training.message.edit.success',
    description: 'Status notification after training edit',
    defaultMessage: 'Training is updated'
  },
  messageRemovedtrainingsSuccess: {
    id: 'trainings.training.message.remove.success',
    description: 'Message when a training is successfully deleted',
    defaultMessage: 'Training successfully removed'
  },
  trainingSaved: {
    id: 'trainings.training.message.saved',
    description: 'message training Saved',
    defaultMessage: 'Training is saved'
  },
  removeTrainingButton: {
    id: 'trainings.training.remove.training.button',
    description: 'Label for delete button for training',
    defaultMessage: 'Remove training'
  },
  sortTrainingButton: {
    id: 'trainings.training.sort.training.button.enable',
    description: 'Label for sort button for training menu',
    defaultMessage: 'Sort training'
  },
  trainingsDetails: {
    id: 'trainings.training.tab.detail',
    description: 'message training Tab Panel',
    defaultMessage: 'Details'
  },
  trainingsExercises: {
    id: 'trainings.training.tab.exercises',
    description: 'message training Tab Panel',
    defaultMessage: 'Exercises'
  },
  trainingNamePlaceholder: {
    id: 'trainings.training.label.name-placeholder',
    description: 'placeholder training Name',
    defaultMessage: 'Add a training name'
  },
  exerciseDetailsTab: {
    id: 'exercises.tab.details',
    description: 'Exercise detail tab label',
    defaultMessage: 'Exercise details'
  },
  exerciseDetails: {
    id: 'exercises.details',
    description: 'Exercise detail label',
    defaultMessage: 'Details'
  },
  exerciseDetailsFrequencyLabel: {
    id: 'trainings.exercise.label.frequency',
    description: 'Exercise detail frequency label',
    defaultMessage: 'Frequency'
  },
  trainingsDetailsNoteLabel: {
    id: 'trainings.training.label.note',
    description: 'Training detail note label',
    defaultMessage: 'Details'
  },
  messageAddTemplateSuccess: {
    id: 'templates.template.message.add.success',
    description: 'Status notification after template add',
    defaultMessage: 'Template is created'
  },
  messageEditTemplateSuccess: {
    id: 'templates.template.message.edit.success',
    description: 'Status notification after template edit',
    defaultMessage: 'Template is updated'
  },
  messageRemovedTemplateSuccess: {
    id: 'templates.template.remove.success',
    description: 'Message when a template is successfully deleted',
    defaultMessage: 'Template successfully removed'
  },
  messageAddTemplateSaveError: {
    id: 'templates.template.error.save',
    description: 'Error Add template Save',
    defaultMessage: 'Hmm something went wrong, try it again later.'
  },
  templateEmptyPanel: {
    id: 'templates.template.message.empty-panel',
    description: 'message Templates Empty Panel',
    defaultMessage:
      'Please select one of the options on the left to view the details...'
  },
  collectionsDetails: {
    id: 'templates.template.tab.detail',
    description: 'message Templates Tab Panel',
    defaultMessage: 'Details'
  },
  collectionsExercises: {
    id: 'templates.template.tab.exercises',
    description: 'message Templates Tab Panel',
    defaultMessage: 'Exercises'
  },
  exercisesAddCollectionButton: {
    id: 'exercises.button.add-collection',
    description: 'button Exercises Add Collection',
    defaultMessage: 'Add Collection'
  },
  exercisesLengthInIt: {
    id: 'exercises.collection.length.init',
    description: 'how many exercises are there?',
    defaultMessage:
      "{this, plural,  =0 {There aren't exercises added yet}  =1 {One exercise}  other {# exercises} }"
  },
  exercisesLengthInListItem: {
    id: 'exercises.collection.length.in-list-item',
    description: 'how many exercises are there?',
    defaultMessage: '{this, plural,  =1 {# exercise}  other {# exercises} }'
  },
  messageExercisesAddedToTemplate: {
    id: 'exercises.message.success.added-to-collection',
    description: 'Message when Exercises are Added to a Collection',
    defaultMessage: 'Exercise succesfully added to the selected libraries'
  },
  messageExercisesAddedToTemplateError: {
    id: 'exercises.message.error.added-to-collection',
    description: 'Message when Exercises failed to add to a Collection',
    defaultMessage: 'Failed to add Exercises to the selected libraries'
  },
  collectionNameLabel: {
    id: 'collections.collection.label.name',
    description: 'label collection Name',
    defaultMessage: 'Collection Name'
  },
  collectionAddExercisesToTemplateButton: {
    id: 'collections.collection.add-exercises-to-template-button',
    description: 'label for menu item: add exercises to template',
    defaultMessage:
      'Add {thisMany, plural, =0 {this exercise} =1 {# exercise} other {# exercises}} to a {type, select, collection {collection} template {template} training {training} program {program} other {other}}'
  },
  collectionAddExercisesToTemplateDisabled: {
    id: 'library.collection.add-exercises-to-template-disabled',
    description: 'add menu-item is disabled because no exercise is selected',
    defaultMessage:
      'Select one or more exercises in order to add it into a {type, select, collection {collection} template {template} training {training} program {program} other {other}}'
  },
  collectionNamePlaceholder: {
    id: 'collections.collection.label.name-placeholder',
    description: 'placeholder collection Name',
    defaultMessage: 'Add a collection name'
  },
  collectionRemoveExerciseDisabledDragging: {
    id: 'collections.collection.message.remove-disabled.dragging',
    description: 'remove menu-item is disabled because dragging is active',
    defaultMessage: "While sorting you can't select an exercise"
  },
  collectionRemoveExerciseDisabledNotSelected: {
    id: 'collections.collection.message.remove-disabled.not-selected',
    description:
      'remove menu-item is disabled because exercise is not selected',
    defaultMessage: 'Select one or more exercises in order to remove it'
  },
  collectionSortExercisesDisabledDragging: {
    id: 'collections.collection.message.sort-disabled.dragging',
    description: 'sort menu-item is disabled because is already active',
    defaultMessage: 'Sorting... please cancel or save your changes'
  },
  addExtraExercisesModalTitle: {
    id: 'modal.add-exercises.title',
    description: 'title Add exercises modal',
    defaultMessage: 'Add extra exercises'
  },
  filterButton: {
    id: 'global.button.filter',
    description: 'button Filter',
    defaultMessage: 'Filter'
  },
  collectionsHeaderListCollections: {
    id: 'collections.list-header.collections',
    description: 'List header: "Collections"',
    defaultMessage: 'Collections'
  },
  collectionsHeaderListExercisesQty: {
    id: 'collections.list-header.exercises-quantity',
    description: 'List header: "# Exercises"',
    defaultMessage: '# Exercises'
  },
  removeCollectionButton: {
    id: 'collections.collection.remove.collection.button',
    description: 'Label for delete button for collection',
    defaultMessage: 'Remove collection'
  },
  messageRemovedcollectionSuccess: {
    id: 'collections.collection.remove.collection.success',
    description: 'Message when a collection is successfully deleted',
    defaultMessage: 'Collection successfully removed'
  },
  messageAddCollectionSuccess: {
    id: 'collections.collection.message.add.success',
    description: 'Status notification after collection add',
    defaultMessage: 'Collection is created'
  },
  messageEditCollectionSuccess: {
    id: 'collections.collection.message.edit.success',
    description: 'Status notification after collection edit',
    defaultMessage: 'Collection is updated'
  },
  collectionSaved: {
    id: 'collections.collection.message.saved',
    description: 'message collection Saved',
    defaultMessage: 'Collection is saved'
  },
  sortCollectionButton: {
    id: 'collections.collection.sort.collection.button.enable',
    description: 'Label for sort button for collection menu',
    defaultMessage: 'Sort collection'
  },
  saveSortButton: {
    id: 'sort.collection.button.save',
    description: 'Label for sort submit button',
    defaultMessage: 'Submit sorting'
  },
  copyCollectionToTemplateButton: {
    id: 'collections.collection.copyCollection.button',
    description: 'Label for copy collection to template button menu',
    defaultMessage: 'Create training from collection'
  },
  breadcrumbMyExercises: {
    id: 'collections.collection.breadcrumb.my-exercises',
    description: 'The title of the collection page with only my exercises',
    defaultMessage: 'My exercises'
  },
  collectionsTabMyCollections: {
    id: 'collections.tab.my-collections',
    description: 'tab My collections',
    defaultMessage: 'My collections'
  },
  collectionsTabAllCollections: {
    id: 'collections.tab.all-collections',
    description: 'tab other collections',
    defaultMessage: 'Other collections'
  },
  addExercisesToCollectionModalTitle: {
    id: 'exercises.modal.add-to-collection.title',
    description: 'The title of the add Exercises To Collection Modal',
    defaultMessage: 'Add to a collection'
  },
  addExercisesToCollectionModalSubtitle: {
    id: 'exercises.modal.add-to-collection.subtitle',
    description: 'The subtitle of the add Exercises To Collection Modal',
    defaultMessage: 'Select a collection to which you want to add the exercise'
  },
  addCollectionInput: {
    id: 'exercises.modal.add-to-collection.input',
    description: 'The list item to add a new collection',
    defaultMessage: 'New collection'
  },
  exercisesRemoveExerciseFromTemplateButton: {
    id: 'exercises.button.remove-selected-exercise-from-template',
    description: 'button Selected Exercises Remove from collection',
    defaultMessage:
      'Remove {count, plural, =1 {this exercise} other {# exercises}} from your {type, select, collection { collection} template {template}  training { training} other {}}'
  },
  modalConfirmRemoveExerciseFromTemplateTitle: {
    id: 'exercises.title.confirm-remove-exercise-from-template',
    description: 'title Confirm Remove Exercise from template',
    defaultMessage:
      'Remove {count, plural, =1 {exercise} other {exercises}} from {type, select, collection { collection} training { training} template {template} other {}}'
  },
  modalConfirmRemoveExerciseFromTemplateMessage: {
    id: 'exercises.message.confirm-remove-exercise-from-template',
    description: 'message Confirm Remove Exercise from template',
    defaultMessage:
      'Are you sure you want to delete {count, plural, =1 {this exercise} other {# exercises}} from your {type, select, collection { collection} template {template} training { training} other {}}?'
  },
  messageGeneratingPDF: {
    id: 'global.message.generatingpdf',
    description: 'Generating pdf message',
    defaultMessage: 'Generating the pdf, please wait...'
  },
  globalLoading: {
    id: 'global.loading',
    description: 'Loading',
    defaultMessage: 'Loading...'
  },
  globalMessageLoading: {
    id: 'global.message.loading',
    description: 'message Loading',
    defaultMessage: 'Loading, please wait...'
  },
  apiError: {
    id: 'message.api.error',
    description: 'message Loading',
    defaultMessage:
      'We are sorry, but seems something went wrong. Please try again, and if the problem persists, contact support.'
  },
  addExercisesToTemplateModalTitle: {
    id: 'exercises.modal.add-to-template.title',
    description: 'The template title of the add Exercises To Collection Modal',
    defaultMessage: 'Add to a template'
  },
  addExercisesToTemplateModalSubtitle: {
    id: 'exercises.modal.add-to-template.subtitle',
    description:
      'The template subtitle of the add Exercises To Collection Modal',
    defaultMessage: 'Select a template to which you want to add the exercise'
  },
  addTemplateInput: {
    id: 'exercises.modal.add-to-template.input',
    description: 'The list item to add a new template',
    defaultMessage: 'New template'
  },
  templatesSortExercisesDisabledDragging: {
    id: 'exercises.template.message.sort-disabled.dragging',
    description: 'sort menu-item is disabled because is already active',
    defaultMessage: 'Sorting... please cancel or save your changes'
  },
  editNotesButton: {
    id: 'notes.edit.button',
    description: 'Button to enable note edit',
    defaultMessage: 'Edit note'
  },
  enableAddRemoveExercisesButton: {
    id: 'exercises.toggle-add-remove.button',
    description: 'Button to enable add/remove exercises',
    defaultMessage: 'Add/remove exercises'
  },
  enableSortExercisesButton: {
    id: 'exercises.toggle-sort.button',
    description: 'Button to enable sorting exercises',
    defaultMessage: 'Sort exercises'
  },
  lessonsEmptyPanel: {
    id: 'lessons.message.empty-panel',
    description: 'message Lessons Empty Panel',
    defaultMessage:
      'Please select one of the lessons on the left to view the details...'
  },
  lessonsTitle: {
    id: 'lessons.title.title',
    description: 'The title of the lessons page',
    defaultMessage: 'My Lessons'
  },
  lessonsSubTitle: {
    id: 'lessons.subtitle',
    description: 'The subtitle of the lessons page',
    defaultMessage: 'My personal lessons'
  },
  lessonsAddButton: {
    id: 'lessons.button.add',
    description: 'Add a lesson button',
    defaultMessage: 'Add lesson'
  },
  lessonsAddTitle: {
    id: 'lessons.title.add',
    description: 'Add a lesson title',
    defaultMessage: 'Add lesson'
  },
  messageRemovedLessonSuccess: {
    id: 'lessons.button.add',
    description: 'Add a lesson button',
    defaultMessage: 'Add lesson'
  },
  breadcrumbLessons: {
    id: 'lesson.breadcrumb.title',
    description: 'The label of the breadcrumb for the lessons page',
    defaultMessage: 'My Lessons'
  },
  breadcrumbLesson: {
    id: 'lessons.lesson.breadcrumb.title',
    description: 'The label of the breadcrumb of the lesson page',
    defaultMessage: 'Lesson'
  },
  removeLessonButton: {
    id: 'lessons.remove.lesson.button',
    description: 'Label for delete button for lessons',
    defaultMessage: 'Remove lesson'
  },
  sortLessonsButton: {
    id: 'lessons.sort.lesson.button.enable',
    description: 'Label for sort button for lessons menu',
    defaultMessage: 'Sort Videos'
  },
  lessonLabelDidactics: {
    id: 'lessons.lesson.label.didactics',
    description: 'Lesson label didactics',
    defaultMessage: 'Didactics'
  },
  programPlaceholderDidactics: {
    id: 'lessons.lesson.placeholder.didactics',
    description: 'Lesson placeholder didactics',
    defaultMessage: 'Didactics'
  },
  lessonLabelSchool: {
    id: 'lessons.lesson.label.school',
    description: 'Lesson label school',
    defaultMessage: 'School'
  },
  lessonPlaceholderSchool: {
    id: 'lessons.lesson.placeholder.school',
    description: 'Lesson placeholder school',
    defaultMessage: 'The name of the school'
  },
  lessonLabelClassroom: {
    id: 'lessons.lesson.label.classroom',
    description: 'Lesson label classroom',
    defaultMessage: 'Classroom'
  },
  lessonPlaceholderClassroom: {
    id: 'lessons.lesson.placeholder.classroom',
    description: 'Lesson placeholder classroom',
    defaultMessage: 'Which classroom'
  },
  lessonLabelSubject: {
    id: 'lessons.lesson.label.subject',
    description: 'Lesson label subject',
    defaultMessage: 'Subject'
  },
  lessonPlaceholderSubject: {
    id: 'lessons.lesson.placeholder.subject',
    description: 'Lesson placeholder subject',
    defaultMessage: 'The subject of the lesson'
  },
  lessonLabelLessonDate: {
    id: 'lessons.lesson.label.lessonDate',
    description: 'Lesson label lessonDate',
    defaultMessage: 'LessonDate'
  },
  videosTitle: {
    id: 'videos.title.title',
    description: 'The title of the videos page',
    defaultMessage: 'Videos'
  },
  breadcrumbVideos: {
    id: 'videos.breadcrumb.title',
    description: 'The label of the breadcrumb of the videos page',
    defaultMessage: 'Videos'
  },
  breadcrumbVideo: {
    id: 'video.breadcrumb.title',
    description: 'The label of the breadcrumb of the video page',
    defaultMessage: 'Video'
  },
  videosAddVideoButton: {
    id: 'videos.button.add-video',
    description: 'button videos Add video',
    defaultMessage: 'Add Video'
  },
  videoMissingVideo: {
    id: 'videos.message.missing-video',
    description: "Message when the user hasn't uploaded a video yet",
    defaultMessage: "You haven't uploaded a video yet."
  },
  lessonVideosTab: {
    id: 'lessons.tab.videos',
    description: 'title lessons tab videos',
    defaultMessage: 'Videos'
  },
  lessonDetails: {
    id: 'lessons.tab.details',
    description: 'title lessons tab detail',
    defaultMessage: 'Details'
  },
  lessonEmptyVideos: {
    id: 'lessons.lesson.message.empty-videos',
    description: 'message Lesson Empty Videos',
    defaultMessage: 'Please add some videos first...'
  },
  lessonsAddVideo: {
    id: 'lessons.button.add-video',
    description: 'button Lessons Add Video',
    defaultMessage: 'Add Video'
  },
  messageAddVideoSuccess: {
    id: 'lessons.lesson.message.add-video.success',
    description: 'Status notification after video add',
    defaultMessage: 'Video is created'
  },
  messageEditVideoSuccess: {
    id: 'lessons.lesson.message.edit-video.success',
    description: 'Status notification after video edit',
    defaultMessage: 'Video is updated'
  },
  lessonsRemoveVideoDisabledDragging: {
    id: 'lessons.message.remove-disabled.dragging',
    description: 'remove menu-item is disabled because dragging is active',
    defaultMessage: "While sorting you can't select a video"
  },
  videosLengthInIt: {
    id: 'lessons.videos.length.init',
    description: 'how many videos are there?',
    defaultMessage:
      "{this, plural,  =0 {There aren't videos added yet}  =1 {One video}  other {# videos} }"
  },
  lessonsAddVideoTitle: {
    id: 'lessons.title.add-video',
    description: 'title lessons Add video',
    defaultMessage: 'New video'
  },
  videosRemoveVideoButton: {
    id: 'videos.button.remove-video',
    description: 'button video Remove',
    defaultMessage: 'Remove video'
  },
  modalConfirmRemoveVideoTitle: {
    id: 'videos.title.confirm-remove-video',
    description: 'title Confirm Remove video',
    defaultMessage: 'Remove video'
  },
  modalConfirmRemoveVideoMessage: {
    id: 'videos.title.confirm-remove-video-message',
    description: 'message Confirm Remove video',
    defaultMessage: 'Are you sure you want to delete this video?'
  },
  lessonsHeaderListLessons: {
    id: 'lessons.list-header.lessons',
    description: 'List header: "lessons"',
    defaultMessage: "Lesson's name"
  },
  lessonsHeaderListLessonsQty: {
    id: 'lessons.list-header.lessons-quantity',
    description: 'List header: "# Video\'s"',
    defaultMessage: '# Videos'
  },
  lessonsVideoLabel: {
    id: 'lessons.video.label.video',
    description: 'label lessons Video',
    defaultMessage: 'Video'
  },
  videoUpload: {
    id: 'video.label.upload',
    description: 'Label for the upload video button',
    defaultMessage: 'Upload a video'
  },
  videoNotInLesson: {
    id: 'video.label.button.not-in-any-lesson',
    description:
      'label for video list item when video dont belong to any lesson',
    defaultMessage: 'Not in a lesson'
  },
  videosMessageNotInLesson: {
    id: 'videos.message.video-not-in-any-lesson',
    description: 'Message: this video dont belong to any lesson',
    defaultMessage:
      'This video is not linked to any lesson. This is probably because the linked lesson no longer exists.'
  },
  videosTabMyVideos: {
    id: 'videos.tab.my-videos',
    description: 'My videos tab for the videos overview page',
    defaultMessage: 'My videos'
  },
  videosTabSharedWithMe: {
    id: 'videos.tab.shared-with-me',
    description: 'Shared with me tab for the videos overview page',
    defaultMessage: 'Shared with me'
  },
  videoEdit: {
    id: 'videos.edit',
    description: 'Label "Video Edit" button',
    defaultMessage: 'Edit video'
  },
  videosAnnotate: {
    id: 'annotations.cta-annotate',
    description: 'Label for the buttons: just with the word Annotate',
    defaultMessage: 'Annotate'
  },
  videosViewAnnotationBtn: {
    id: 'videos.annotations.view-button',
    description: 'Button to annotations page view',
    defaultMessage: 'View annotations'
  },
  videoAnnotationsAddBtn: {
    id: 'video.annotations.add-button',
    description: 'Button to add a new annotations',
    defaultMessage: 'New Annotation'
  },
  videoAnnotationsShareBtn: {
    id: 'video.annotations.share-button',
    description: 'Button to share the video',
    defaultMessage: 'Share'
  },
  messageAddAnnotationSuccess: {
    id: 'video.annotations.message.add.success',
    description: 'Status notification after annotations add',
    defaultMessage: 'Annotations is created'
  },
  messageEditAnnotationSuccess: {
    id: 'video.annotations.message.edit.success',
    description: 'Status notification after annotations edit',
    defaultMessage: 'Annotations is updated'
  },
  buttonSaveAsProgram: {
    id: 'templates.template.button.save-as-program',
    description: 'button Save as Program',
    defaultMessage: 'Save as a program'
  },
  annotationFormTitle: {
    id: 'annotations.title.form',
    description: 'Title for the annotation form',
    defaultMessage: 'Add/Edit Annotation'
  },
  labelAnnotationStartTime: {
    id: 'annotations.label.starttime',
    description: 'label for start time input',
    defaultMessage: 'Start time'
  },
  labelAnnotationEndTime: {
    id: 'annotations.label.endtime',
    description: 'label for end time input',
    defaultMessage: 'End time'
  },
  labelAnnotationText: {
    id: 'annotations.label.text',
    description: 'label for text input',
    defaultMessage: 'Annotation'
  },
  labelAnnotationTagsDimension: {
    id: 'annotations.label.tags.dimension',
    description: 'label for dimension tags input',
    defaultMessage: 'Dimension'
  },
  labelAnnotationTagsBehavior: {
    id: 'annotations.label.tags.behavior',
    description: 'label for behavior tags input',
    defaultMessage: 'Behavior'
  },
  labelAnnotationScore: {
    id: 'annotations.label.input.score',
    description: 'label for the score input',
    defaultMessage: 'Score'
  },
  annotationsRemoveAnnotationButton: {
    id: 'annotations.button.remove-annotation',
    description: 'button annotation Remove',
    defaultMessage: 'Remove annotation'
  },
  modalConfirmRemoveAnnotationTitle: {
    id: 'annotations.title.confirm-remove-annotation',
    description: 'title Confirm Remove annotation',
    defaultMessage: 'Remove annotation'
  },
  modalConfirmRemoveAnnotationMessage: {
    id: 'annotations.title.confirm-remove-annotation-message',
    description: 'message Confirm Remove annotations',
    defaultMessage: 'Are you sure you want to delete this annotation?'
  },
  annotationOwnershipSupervisor: {
    id: 'annotations.ownership.supervisor',
    description: 'Annotation ownership label for a supervisor',
    defaultMessage: 'Supervisor'
  },
  annotationDateCreatedLabel: {
    id: 'annotations.annotate-video.label.date-created',
    description: 'Label for the date created of a video',
    defaultMessage: 'Uploaded on {date_created}'
  },
  annotationDateCreatedAndOwnerLabel: {
    id: 'annotations.annotate-video.label.date-created-owner',
    description: 'Label for the date created and the owner of a video',
    defaultMessage: 'Uploaded on {date_created} by <b>{person}</b>'
  },
  annotationLegend: {
    id: 'annotations.legend.label',
    description: 'Word "Legend"',
    defaultMessage: 'Legend'
  },
  annotationOwnAnnotation: {
    id: 'annotations.legend.owner-video',
    description: 'Legend: "Video owner annotation"',
    defaultMessage: 'Video Owner Annotation'
  },
  annotationOwnAndSupervisorAnnotation: {
    id: 'annotations.legend.own-supervisor',
    description: 'Legend: "Own annotation with Supervisor\'s annotations"',
    defaultMessage: "Own Annotation + Supervisor's Annotations (or Check)"
  },
  annotationStudentAnnotation: {
    id: 'annotations.legend.student',
    description: 'Legend: "Own annotation with Supervisor\'s annotations"',
    defaultMessage: "Fellow Student's Annotation"
  },
  annotationSupervisorAnnotation: {
    id: 'annotations.legend.supervisor',
    description: 'Legend: "Supervisor\'s annotations"',
    defaultMessage: "Supervisor's Annotations (or Check)"
  },
  annotationsHowToAnotate: {
    id: 'annotations.messages.how-to-anotate',
    description: 'message describing the steps to annotate, with html markup',
    defaultMessage:
      // eslint-disable-next-line no-multi-str
      '<h2>How do you annotate a video?</h2> \
    <p>After you uploaded a video, you\'ll be able to start annotating.</p> \
    <ol> \
    <li>Click the "Add Annotation" button, and add a time slot, or use your mouse to select a time slot on the video\'s timeline.</li> \
    <li>Give a rating (1 to 4) for the "Quantitative Assessment".</li> \
    <li>Choose the "Dimension" from the dropdown.</li> \
    <li>Select a "Behavior" among the options.</li> \
    <li>Fill the input field with your annotation.</li> \
    <li>Save the changes.</li> \
    </ol>'
  },
  videoSharedWithLabel: {
    id: 'annotations.annotate-video.label.shared-with',
    description: 'List of users with whom the video has been shared',
    defaultMessage: 'Shared with '
  },
  videoSharedBy: {
    id: 'annotations.annotate-video.label.shared-by',
    description: 'Label preceding name of sharer',
    defaultMessage: 'shared by {person}'
  },
  andLabel: {
    id: 'label.and',
    description: 'The word: "and"',
    defaultMessage: 'and'
  },
  meLabel: {
    id: 'label.me',
    description: 'The word: "me"',
    defaultMessage: 'me'
  },
  annotationMadeByStudentName: {
    id: 'annotations.owner.relation-video',
    description:
      'Label describing the owner of the annotation (and their relation with the video)',
    defaultMessage:
      '{isOwner, select, true {by {student} (owner)} other {by {student}}}'
  },
  annotationLength: {
    id: 'label.annotation-length',
    description: 'Annotations length',
    defaultMessage: '{length, plural, one {annotation} other {annotations}}'
  },
  annotationChecked: {
    id: 'label.annotation.checked',
    description: 'Annotation "Checked" word',
    defaultMessage: 'Checked'
  },
  annotationSharedTimes: {
    id: 'label.annotation-shared-times',
    description: 'Annotation shared x times',
    defaultMessage: '&times; shared'
  },
  annotationEmptySharedWithMe: {
    id: 'label.annotation.shared-with-me.empty',
    description: 'Empty card shared with me',
    defaultMessage: "There are no video's shared with you at the moment"
  },
  annotationEmptySharedWithMeExtra: {
    id: 'label.annotation.shared-with-me.empty-extra',
    description: 'Empty card shared with me: extra info',
    defaultMessage:
      'If you think there is an error, contact your teacher or the person who invited you to use the app'
  },
  videoShareModalShareTitle: {
    id: 'annotations.video-share-modal.sharewith.title',
    description: 'Title for the share with modal',
    defaultMessage: 'Share your video'
  },
  videoShareModalShareSubTitle: {
    id: 'annotations.video-share-modal.sharewith.sub-title',
    description: 'Subtitle for the share with modal',
    defaultMessage:
      'Enter an email of a classmate or supervisor to share your video with him or her'
  },
  videoShareModalShareWithLabel: {
    id: 'annotations.video-share-modal.sharewith.label',
    description: 'Label for the email input',
    defaultMessage: 'Enter a valid email'
  },
  lessonListEmpty: {
    id: 'exercises.message.empty-lesson-list',
    description: 'message Lesson List Empty',
    defaultMessage:
      "Filter results don't match any lesson or there are any lessons available"
  },
  videoFinishButtonLabel: {
    id: 'videos.finish-video',
    description: 'label Finish video',
    defaultMessage: 'Finish video'
  },
  modalConfirmFinishAnnotationTitle: {
    id: 'annotations.confirm-finish-annotation',
    description: 'title Confirm Finish annotation',
    defaultMessage: 'Check annotation'
  },
  modalConfirmFinishAnnotationMessage: {
    id: 'annotations.confirm-finish-annotations-message',
    description: 'message Confirm Finish Session',
    defaultMessage:
      "Are you sure you want to mark this annotation as checked? After the annotation is checked, it's no longer possible to edit the state."
  },
  messageAddNoteSuccess: {
    id: 'message.add-note.success',
    description: 'Message when a note is added',
    defaultMessage: 'Note is added'
  },
  videosEmptyPanel: {
    id: 'videos.message.empty-panel',
    description: 'message Videos Empty Panel',
    defaultMessage:
      'Please select one of the videos on the left to view the details...'
  },
  textareaCharacterLimit: {
    id: 'global.textarea.character-limit',
    description: 'Display the maximun allowe characters under the textarea',
    defaultMessage: 'Maximum {limit} characters'
  },
  textareaCharacterCount: {
    id: 'global.textarea.character-count',
    description: 'Display the remaining characters under the textarea',
    defaultMessage: '({count} remaining)'
  },
  textareaCharacterCountWarning: {
    id: 'global.textarea.character-count-warning',
    description:
      'Display a warning when character limit is reached under the textarea',
    defaultMessage: '({count} to many)'
  },
  modalConfirmFinishVideoTitle: {
    id: 'videos.title.confirm-finish-video',
    description: 'title Confirm Finish video',
    defaultMessage: 'Finish video'
  },
  modalConfirmFinishVideoMessage: {
    id: 'videos.title.confirm-finish-video-message',
    description: 'message Confirm Finish videos',
    defaultMessage:
      "Are you sure you want to finish this video? After the video is finished, it's no longer possible to add or edit annotations by you, only for fellow students and supervisors."
  },
  modalConfirmFinishVideoAcceptButton: {
    id: 'global.button.confirm-finish-video-accept',
    description: 'button Confirm Finish video',
    defaultMessage: 'Finish'
  },
  messageVideoFinished: {
    id: 'messages.videos.finished.success',
    description: 'Message to Confirm the video is finished',
    defaultMessage: 'The video is marked as finished'
  },
  addVideosToLessonModalTitle: {
    id: 'videos.modal.add-to-lesson.title',
    description: 'The title of the "add video to lesson" Modal',
    defaultMessage: 'Add a video to a lesson'
  },
  addVideosToLessonModalSubtitle: {
    id: 'videos.modal.add-to-lesson.subtitle',
    description: 'The subtitle of the addVideosToLesson Modal',
    defaultMessage: 'Select a lesson to which you want to add the video'
  },
  errorMessageInjuriesSelect: {
    id: 'global.error.error-message-injuries',
    description: 'label Error Message for injuries select',
    defaultMessage: 'There was an error loading the injuries'
  },
  videoShareMessage: {
    id: 'videos.share.message.success',
    description: 'The message returned to the user after the email is shared',
    defaultMessage: 'The video is shared with the student or supervisor.'
  },
  videoShareErrorMessageNotFound: {
    id: 'videos.share.message.error.not-found',
    description:
      'The error message returned to the user after the email is shared with error shareWith_not_found',
    defaultMessage: "The email you shared with doesn't exist"
  },
  videoShareErrorMessageSamePerson: {
    id: 'videos.share.message.error.same-person',
    description:
      'The error message returned to the user after the email is shared with error same_person',
    defaultMessage: "You can't share a video with yourself"
  },
  videoShareErrorMessageAlreadySharedWithPerson: {
    id: 'videos.share.message.error.already-shared-with-person',
    description:
      'The error message returned to the user after the email is shared with error already_shared_with_person',
    defaultMessage: 'The video is already shared with this person.'
  },
  annotationsRemoveAnnotationNoteButton: {
    id: 'annotations.button.remove-annotation-note',
    description: 'button annotation Remove',
    defaultMessage: 'Remove annotation'
  },
  modalConfirmRemoveAnnotationNoteTitle: {
    id: 'annotations.title.confirm-remove-annotation-note',
    description: 'title Confirm Remove annotation note',
    defaultMessage: 'Remove annotation'
  },
  modalConfirmRemoveAnnotationNoteMessage: {
    id: 'annotations.title.confirm-remove-annotation-note-message',
    description: 'message Confirm Remove annotations note',
    defaultMessage: 'Are you sure you want to delete this annotation?'
  },
  annotationLegendMultiple: {
    id: 'annotations.legend.multiple-annotation-on-same-time',
    description: 'legend for multiple files',
    defaultMessage: 'More annotations at same time'
  },
  timeValidation: {
    id: 'global.error.time-valid',
    description: 'validation: The time value is not valid',
    defaultMessage: 'The time is not valid'
  },
  scoreValidation: {
    id: 'global.error.score-required',
    description: 'validation: The score value is required',
    defaultMessage: 'The score is required'
  },
  annotationValidation: {
    id: 'global.error.annotation-required',
    description: 'validation: The annotation value is required',
    defaultMessage: 'The annotation is required'
  },
  dimensionsBehaviourValidationRequired: {
    id: 'global.error.dimensionbehaviour-required',
    description: 'validation: The dimensionbehaviour value is required',
    defaultMessage: 'The dimension and behaviour are required'
  },
  accountUsageLabel: {
    id: 'account.usage.label',
    description: 'Label of the usage link',
    defaultMessage: 'Account usage'
  },
  breadcrumbAccountUsage: {
    id: 'account.breadcrumb.usage.label',
    description: 'Label of the breadcrumb link',
    defaultMessage: 'Usage'
  },
  settingsOrganisationsListTitle: {
    id: 'settings.list.organisations.title',
    description: 'Settings List Organisations title',
    defaultMessage: 'Organisation'
  },
  accountUsageTitle: {
    id: 'account.usage.title',
    description: 'Title of the usage page',
    defaultMessage: 'Account Usage'
  },
  accountUsageSubTitle: {
    id: 'account.usage.subtitle',
    description: 'Subtitle of the usage page',
    defaultMessage: 'Overview of your usage'
  },
  accountUsageSportersTitle: {
    id: 'account.usage.persons.title',
    description: 'Title of the athletes count on the usage page',
    defaultMessage: 'Accounts'
  },
  accountUsageSportersInfo: {
    id: 'account.usage.persons.info',
    description: 'Info for the athletes count on the usage page',
    defaultMessage:
      'Select the range and click the button to count the number of active accounts'
  },
  accounUsageCountSportersInSubOrgs: {
    id: 'account.usage.sporters_in_suborgs.button',
    description:
      'Checkbox to calculate the persons count in all sub orgs on the usage page',
    defaultMessage: 'Count accounts in all sub organisations'
  },
  accounUsageCountSporters: {
    id: 'account.usage.persons.button',
    description: 'Button to calculate the persons count on the usage page',
    defaultMessage: 'Count accounts'
  },
  accountUsageAnswer: {
    id: 'account.usage.persons.answer',
    description:
      'The message with the answer to the question "How many accounts were active in chosen periode"',
    defaultMessage:
      'In the period <b>{startdate}</b> - <b>{enddate}</b> {activeUsers, plural, one {there was <span>{activeUsers}</span> active account} other {there were <span>{activeUsers}</span> active accounts}}'
  },
  accountUsageSubOrgsAnswer: {
    id: 'account.usage.sporters_in_suborgs.answer',
    description:
      'The message with the answer to the question "How many accounts were active in chosen periode for all sub organisations"',
    defaultMessage:
      'We have sent you an email with your account usage for the period <b>{startdate}</b> - <b>{enddate}</b>'
  },
  accountDemoDataLabel: {
    id: 'account.demodata.label',
    description: 'Label of the demodata link',
    defaultMessage: 'Demo data'
  },
  modalDownloadReportOptionsTitle: {
    id: 'modal.report.download.options.title',
    description: 'Title for download options modal',
    defaultMessage: 'Report options'
  },
  modalDownloadReportOptionsSubtitle: {
    id: 'modal.report.download.options.subtitle',
    description: 'Subtitle for download options modal',
    defaultMessage:
      'With the options below you can configure your report and PDF.'
  },
  reportViewOptions: {
    id: 'report.options.view-options',
    description: 'Button with cogwheel icon: show/hide options',
    defaultMessage: 'Report Options'
  },
  reportOptionShowComment: {
    id: 'report.options.showcomment-label',
    description: 'Show comment option for report settings',
    defaultMessage: 'Show comments in PDF'
  },
  reportOptionShowCommentDesc: {
    id: 'report.options.showcomment-description',
    description: 'Description for the option "Show comments"',
    defaultMessage: 'Add your comments to the first page of the PDF report.'
  },
  reportOptionHideEmpty: {
    id: 'report.options.hide-empty-label',
    description: 'Hide tests/items/table-rows without values',
    defaultMessage: 'Hide empty fields'
  },
  reportOptionHideEmptyDesc: {
    id: 'report.options.hide-empty-description',
    description: 'Description for the option "Hide empty fields"',
    defaultMessage:
      "If there are items without data available (because the tester didn't added the values on the results page, for instance... or because the derivate score depends on a result that hasn't been added yet, you have the possibility to hide those tests in order to declutter your report. This option will only hide the rows on the report. If you add the results of the tests, and so the test got a score, this option will have no effect. <button>Sample Empty</button>"
  },
  reportOptionShowBothSides: {
    id: 'report.options.show-both-sides-label',
    description: 'Show both sides of the test',
    defaultMessage: 'Show both sides'
  },
  reportOptionShowBothSidesDesc: {
    id: 'report.options.show-both-sides-description',
    description: 'Description for the option "Show both sides"',
    defaultMessage:
      'Show also the scores of the other side, and not only the scores of the injuried side. <button>Sample Empty</button>'
  },
  reportOptionShowUnBenchmarked: {
    id: 'report.options.show-unbenchmarked-label',
    description: 'Show scores of unbenchmarked tests for report settings',
    defaultMessage: 'Show scores of tests without benchmarks'
  },
  reportOptionShowUnBenchmarkedDesc: {
    id: 'report.options.show-unbenchmarked-description',
    description: 'Description of "Show scores of unbenchmarked tests" option',
    defaultMessage:
      'The (raw) scores of test without a benchmark will also be visible. This option is checked by default. <button>Sample Empty</button>'
  },
  reportOptionShowUnBenchmarkedHead: {
    id: 'report.options.show-unbenchmarked-header',
    description: 'Subtitle group checkboxes for Unbenchmarked Test',
    defaultMessage: 'Tests without Benchmarks'
  },
  reportOptionShowPercentage: {
    id: 'report.options.show-percentage-label',
    description: 'Show percentage option for report settings',
    defaultMessage: 'Show percentage'
  },
  reportOptionShowPercentageDesc: {
    id: 'report.options.show-percentage-description',
    description: 'Description for option "Show percentage"',
    defaultMessage:
      'The percentage (score) of benchmarked test are shown besides the benchmark bar. <button>Sample Percentage</button>'
  },
  reportOptionShowPercentageHead: {
    id: 'report.options.show-percentage-header',
    description: 'Subtitle group checkboxes for Benchmarked Test',
    defaultMessage: 'Benchmarked Tests'
  },
  reportOptionShowRawScore: {
    id: 'report.options.show-raw-score-label',
    description: 'Show score option for report settings',
    defaultMessage: 'Show raw scores'
  },
  reportOptionShowRawScoreDesc: {
    id: 'report.options.show-raw-score-description',
    description: 'Description for option "Show Raw Score"',
    defaultMessage:
      'The (raw) Score of benchmarked test are shown. <button>Sample Raw Scores</button>'
  },
  reportOptionAdvancedOptions: {
    id: 'report.options.advanced.label',
    description: 'Label for toggler: "show advanced options"',
    defaultMessage: 'Show advanced options'
  },
  reportOptionAdvancedOptionsDesc: {
    id: 'report.options.advanced.description',
    description: 'Description for toggler "advanced options"',
    defaultMessage:
      'If you want to get more options about what kind of score will be shown on your report, activate this switch.'
  },
  selectTagsPlaceholder: {
    id: 'input.select-tags.placeholder',
    description: 'Default placeholder for select tags',
    defaultMessage: 'Select one or more tags ...'
  },
  selectTagsEmptyReadOnlyPlaceholder: {
    id: 'input.select-tags.empty.read-only.placeholder',
    description: 'Placeholder for select tags when empty and read only',
    defaultMessage: 'There are no tags selected'
  },
  breadcrumbAccountDemoData: {
    id: 'account.breadcrumb.demodata.label',
    description: 'Label of the demodata breadcrumb link',
    defaultMessage: 'Demo Data'
  },
  accountDemoDataTitle: {
    id: 'account.demoData.title',
    description: 'Title of the demoData page',
    defaultMessage: 'Demo Data'
  },
  accountDemoDataSubTitle: {
    id: 'account.demoData.subtitle',
    description: 'Subtitle of the demoData page',
    defaultMessage: 'Install demo data to start using the application'
  },
  accountDemoDataInstallTitle: {
    id: 'account.demoData.card.title',
    description: 'Title of the demoData page',
    defaultMessage: 'Install Demo Data'
  },
  accountDemoDataInstallInfo: {
    id: 'account.demoData.persons.info',
    description: 'Info for the athletes count on the demoData page',
    defaultMessage: 'Click the button to install demo data'
  },
  accountDemoDataInstallButton: {
    id: 'account.demoData.install.button',
    description: 'Button to start the installation of demo data',
    defaultMessage: 'Install'
  },
  accountDemoDataIsReady: {
    id: 'global.message.demodataIsReady',
    description: 'message when demo data is installed',
    defaultMessage: 'Demo data is installed'
  },
  testsetSelectPlaceholder: {
    id: 'global.input.select.testset.placeholder',
    description: 'Placeholder for testset select',
    defaultMessage: 'Select a testset'
  },
  videoFilterLabelName: {
    id: 'video.filter.label.name',
    description: 'Label for the name filter',
    defaultMessage: 'Name'
  },
  videoFilterLabelOwner: {
    id: 'video.filter.label.owner',
    description: 'Label for the owners filter',
    defaultMessage: 'Student'
  },
  videoFilterLabelStatus: {
    id: 'video.filter.label.status',
    description: 'Label for the status filter',
    defaultMessage: 'Status'
  },
  videoFilterLabelDimension: {
    id: 'video.filter.label.dimension',
    description: 'Label for the dimensie filter',
    defaultMessage: 'Dimension'
  },
  videoAnnotationStatusCompleted: {
    id: 'video.annotation.status.completed',
    description: 'Status for completed annotations',
    defaultMessage: 'Completed'
  },
  videoAnnotationStatusInProgress: {
    id: 'video.annotation.status.in-progress',
    description: 'Status for in progress annotations',
    defaultMessage: 'In progress'
  },
  videoAnnotationStatusShared: {
    id: 'video.annotation.status.shared',
    description: 'Status for shared annotations',
    defaultMessage: 'Shared'
  },
  athleteImportErrorNoValidDate: {
    id: 'athlete.import.error.no-valid-date',
    description: 'Error message for invalid date',
    defaultMessage: 'Invalid date'
  },
  athleteImportErrorNoGender: {
    id: 'athlete.import.error.no-gender',
    description: 'Error message for no gender',
    defaultMessage: 'No gender'
  },
  athleteImportErrorNoFirstname: {
    id: 'athlete.import.error.no-firstname',
    description: 'Error message for no firstname',
    defaultMessage: 'No firstname'
  },
  athleteImportErrorNoLastname: {
    id: 'athlete.import.error.no-lastname',
    description: 'Error message for no lastname',
    defaultMessage: 'No lastname'
  },
  athleteImportErrorUIDAlreadyInImport: {
    id: 'athlete.import.error.uid-already-in-import',
    description: 'Error message for uid already in import',
    defaultMessage: 'UID already in import'
  },
  athleteImportErrorPersonAlreadyInImport: {
    id: 'athlete.import.error.person-already-in-import',
    description: 'Error message for person already in import',
    defaultMessage: 'Person already in import'
  },
  athleteImportErrorPersonAlreadyInImportDifferentUID: {
    id: 'athlete.import.error.person-already-in-import-different-uid',
    description:
      'Error message for person already in import with different uid',
    defaultMessage: 'Person already in import with different uid'
  },
  athleteImportErrorUIDAlreadyOrganisationSamePerson: {
    id: 'athlete.import.error.uid-already-organisation-same-person',
    description:
      'Error message for uid already in organisation with same person',
    defaultMessage: 'UID already in organisation with same person'
  },
  athleteImportErrorUIDAlreadyOrganisation: {
    id: 'athlete.import.error.uid-already-organisation',
    description: 'Error message for uid already in organisation',
    defaultMessage: 'UID already in organisation'
  },
  athleteImportErrorPersonAlreadyOrganisation: {
    id: 'athlete.import.error.person-already-organisation',
    description: 'Error message for person already in organisation',
    defaultMessage: 'Person already in organisation'
  },
  athleteImportErrorPersonAlreadyOrganisationWithOtherUID: {
    id: 'athlete.import.error.person-already-organisation-with-other-uid',
    description:
      'Error message for person already in organisation with other uid',
    defaultMessage: 'Person already in organisation with other uid'
  },
  tableHeaderRow: {
    id: 'table.header.row',
    description: 'Table header row',
    defaultMessage: 'Row'
  },
  tableHeaderUID: {
    id: 'table.header.uid',
    description: 'Table header uid',
    defaultMessage: 'UID'
  },
  tableHeaderFirstname: {
    id: 'table.header.firstname',
    description: 'Table header firstname',
    defaultMessage: 'Firstname'
  },
  tableHeaderLastname: {
    id: 'table.header.lastname',
    description: 'Table header lastname',
    defaultMessage: 'Lastname'
  },
  tableHeaderBirthdate: {
    id: 'table.header.birthdate',
    description: 'Table header birthdate',
    defaultMessage: 'Birthdate'
  },
  tableHeaderSex: {
    id: 'table.header.sex',
    description: 'Table header sex',
    defaultMessage: 'Sex'
  },
  tableHeaderEmail: {
    id: 'table.header.email',
    description: 'Table header email',
    defaultMessage: 'Email'
  },
  tableHeaderGroup: {
    id: 'table.header.group',
    description: 'Table header group',
    defaultMessage: 'Group'
  },
  tableHeaderProblems: {
    id: 'table.header.problems',
    description: 'Table header problems',
    defaultMessage: 'Problems'
  },
  organisationNameRequired: {
    id: 'global.error.organisation-name-required',
    description: 'error organisation Name Required',
    defaultMessage: 'Organisation name is required'
  },
  noUsersAddedMessage: {
    id: 'global.message.no-users-added',
    description: 'Message when users is empty',
    defaultMessage: "You haven't added any users yet"
  },
  sessions: {
    id: 'global.feature.sessions',
    description: 'Feature sessions',
    defaultMessage: 'Sessions'
  },
  sessionsDescription: {
    id: 'global.feature.sessions-description',
    description: 'Information about the sessions feature',
    defaultMessage: 'Some info about the sessions feature'
  },
  prevention: {
    id: 'global.feature.prevention',
    description: 'Feature prevention',
    defaultMessage: 'Prevention'
  },
  preventionDescription: {
    id: 'global.feature.prevention-description',
    description: 'Information about the prevention feature',
    defaultMessage: 'Some info about the prevention feature'
  },
  rehab: {
    id: 'global.feature.rehab',
    description: 'Feature rehab',
    defaultMessage: 'Rehab'
  },
  rehabDescription: {
    id: 'global.feature.rehab-description',
    description: 'Information about the rehab feature',
    defaultMessage: 'Some info about the rehab feature'
  },
  athletes: {
    id: 'global.feature.athletes',
    description: 'Feature athletes',
    defaultMessage: 'Athletes'
  },
  athletesDescription: {
    id: 'global.feature.athletes-description',
    description: 'Information about the athletes feature',
    defaultMessage: 'Some info about the athletes feature'
  },
  exercises: {
    id: 'global.feature.exercises',
    description: 'Feature exercises',
    defaultMessage: 'Exercises'
  },
  exercisesDescription: {
    id: 'global.feature.exercises-description',
    description: 'Information about the exercises feature',
    defaultMessage: 'Some info about the exercises feature'
  },
  results: {
    id: 'global.feature.results',
    description: 'Feature results',
    defaultMessage: 'Results'
  },
  resultsDescription: {
    id: 'global.feature.results-description',
    description: 'Information about the results feature',
    defaultMessage: 'Some info about the results feature'
  },
  benchmarks: {
    id: 'global.feature.benchmarks',
    description: 'Feature benchmarks',
    defaultMessage: 'Benchmarks'
  },
  benchmarksDescription: {
    id: 'global.feature.benchmarks-description',
    description: 'Information about the benchmarks feature',
    defaultMessage: 'Some info about the benchmarks feature'
  },
  growthtracker: {
    id: 'global.feature.growthtracker',
    description: 'Feature growthtracker',
    defaultMessage: 'Growthtracker'
  },
  growthtrackerDescription: {
    id: 'global.feature.growthtracker-description',
    description: 'Information about the growthtracker feature',
    defaultMessage: 'Some info about the growthtracker feature'
  },
  growthtracker_v2: {
    id: 'global.feature.growthtracker_v2',
    description: 'Feature growthtracker v2',
    defaultMessage: 'Growthtracker v2'
  },
  growthtracker_v2Description: {
    id: 'global.feature.growthtracker_v2-description',
    description: 'Information about the growthtracker v2 feature',
    defaultMessage: 'Some info about the growthtracker v2 feature'
  },
  video: {
    id: 'global.feature.video',
    description: 'Feature video',
    defaultMessage: 'Video'
  },
  videoDescription: {
    id: 'global.feature.video-description',
    description: 'Information about the video feature',
    defaultMessage: 'Some info about the video feature'
  },
  testsets: {
    id: 'global.feature.testsets',
    description: 'Feature testsets',
    defaultMessage: 'Testsets'
  },
  testsetsDescription: {
    id: 'global.feature.testsets-description',
    description: 'Information about the testsets feature',
    defaultMessage: 'Some info about the testsets feature'
  },
  myTestSets: {
    id: 'settings.testsets.my-testsets',
    description: 'My testsets tab',
    defaultMessage: 'My testsets'
  },
  defaultTestSets: {
    id: 'settings.testsets.default-testsets',
    description: 'Default testsets tab',
    defaultMessage: 'Default testsets'
  },
  noTestSetsMessage: {
    id: 'global.message.no-testsets',
    description: 'Message when there are no tests',
    defaultMessage: 'No testsets added yet...'
  },
  markAsDone: {
    id: 'global.label.mark-as-done',
    description: 'Mark as done button label',
    defaultMessage: 'Done'
  },
  doneToday: {
    id: 'global.label.done-today',
    description: 'Done today button label',
    defaultMessage: 'Done for today'
  },
  athletesGroupsTab: {
    id: 'athletes.tab.athlete-groups',
    description: 'tab Athlete groups',
    defaultMessage: 'Groups'
  },
  athletesPoolTab: {
    id: 'athletes.tab.athletes-pool',
    description: 'tab Athletes pool',
    defaultMessage: 'Athletes'
  },
  athleteSessionsEmpty: {
    id: 'athletes.sessions.message.message',
    description: 'Message for empty athlete session panel',
    defaultMessage: "This athlete doesn't have any sessions yet"
  },
  athleteProgramsEmpty: {
    id: 'athletes.programs.message.message',
    description: 'Message for empty athlete programs panel',
    defaultMessage: "This athlete doesn't have any programs yet"
  },
  athleteGroups: {
    id: 'athletes.tab.groups',
    description: 'Label for the athletes group tab',
    defaultMessage: 'Groups'
  },
  athleteDocs: {
    id: 'athletes.tab.docs',
    description: 'Label for the athletes documents tab',
    defaultMessage: 'Documents'
  },
  listEmpty: {
    id: 'global.message.list.empty',
    description: 'Message for en empty list',
    defaultMessage: 'There are no items in this list'
  },
  emailIsNotConfirmed: {
    id: 'global.message.user-email-is-not-confirmed',
    description: 'Message for email is not confirmed',
    defaultMessage:
      'This person has not confirmed their email address and <b>the invitation link has expired</b>.{br}Click on this icon to re-send them an invitation.'
  },
  userWithEmail: {
    id: 'global.message.user-has-email',
    description: 'Tooltip message for icon email',
    defaultMessage:
      '<b>This person has a confirmed e-mail address</b>. People with a confirmed e-mail address can receive reports on their mailboxes.'
  },
  userWithLogin: {
    id: 'global.message.user-has-login',
    description: 'Tooltip message for icon persons',
    defaultMessage:
      '<b>This person is registered</b> and can login into the platform.'
  },
  userPending: {
    id: 'global.message.user-is-pending',
    description: 'Tooltip message for icon pending',
    defaultMessage:
      '<b>This person has been invited</b> but has not yet confirmed their email address.'
  },
  userIsAdded: {
    id: 'global.message.user-is-added',
    description: 'Message when user is added',
    defaultMessage: 'Person added correctly'
  },
  userIsUpdated: {
    id: 'global.message.user-is-updated',
    description: 'Message when user is updated',
    defaultMessage: 'The data has been updated'
  },
  userHasUnconfirmedLogin: {
    id: 'global.message.user-has-unconfirmed-login',
    description: 'Message when user has unconfirmed login',
    defaultMessage:
      'There is already a person on the platform using this e-mail address.\nWe have taken their data from our database, but they have not confirmed their e-mail address yet.'
  },
  userHasConfirmedLogin: {
    id: 'global.message.user-has-confirmed-login',
    description: 'Message when user has confirmed login',
    defaultMessage:
      'There is already a person on the platform using this e-mail address.\nWe have taken their data from our database, they also have confirmed their e-mail address, so they are able to login into the platform.'
  },
  modalConfirmRemoveLoginTitle: {
    id: 'users.title.modal-confirm-remove-login',
    description: 'title for modal confirm remove login',
    defaultMessage: 'Remove login'
  },
  modalConfirmRemoveLoginMessage: {
    id: 'users.title.modal-confirm-remove-login-message',
    description: 'message for modal confirm remove login',
    defaultMessage: "Are you sure you want to remove this athlete's login?"
  },
  modalConfirmRemoveLoginAcceptButton: {
    id: 'global.button.modal-confirm-remove-login-accept',
    description: 'button Confirm for modal confirm remove login',
    defaultMessage: 'Confirm'
  },
  uploadLabel: {
    id: 'documents.label.upload',
    description: 'Label for the upload field',
    defaultMessage: 'Upload'
  },
  uploadedDocuments: {
    id: 'users.title.uploaded-documents',
    description: 'Title for the uploaded documents',
    defaultMessage: 'Uploaded documents'
  },
  modalConfirmToggleVisibilityFileTitle: {
    id: 'partials.file-toggle.message.remove-privacy.title',
    description: 'title Confirm Remove privacy File',
    defaultMessage: 'Toggle document visibility'
  },
  modalConfirmVisibilityFileMessage: {
    id: 'partials.file-toggle.message.remove-privacy.message',
    description: 'message Confirm Remove privacy File',
    defaultMessage: `Are you sure you want to make this file {visibility, select, true {visible} other {hidden}} to the athlete?`
  },
  modalConfirmVisibilityFileAcceptButton: {
    id: 'partials.file-toggle.button.toggle-visibility',
    description: 'button ok for modal confirm toggle visibility of the File',
    defaultMessage: 'Confirm'
  },
  fileEditFailed: {
    id: 'global.file.update-failed',
    description: 'failed file edit request',
    defaultMessage: 'Failed updating file'
  },
  fileEditSuccessfully: {
    id: 'global.file.update-success',
    description: 'success file edit request',
    defaultMessage: 'File updated successfully'
  },
  documents: {
    id: 'global.label.documents',
    description: 'Label for the documents',
    defaultMessage: 'Documents'
  },
  documentsDescription: {
    id: 'global.feature.documents-description',
    description: 'Description for the documents feature',
    defaultMessage: 'Upload documents for your athletes'
  },
  documentsSubtitle: {
    id: 'global.label.documents-subtitle',
    description: 'Label for the documents subtitle',
    defaultMessage: 'Uploaded documents'
  },
  documentsListEmpty: {
    id: 'global.message.documents-list-empty',
    description: 'Message for en empty documents list',
    defaultMessage: 'There are no documents in this list'
  },
  menuItemCopySession: {
    id: 'sessions.session.menu-item.copy-session',
    description: 'menu item Session Copy session',
    defaultMessage: 'Copy session for re-testing'
  },
  searchUsersAndGroupsPlaceholder: {
    id: 'global.label.search-user-groups-placeholder',
    description: 'placeholder User and groups Search',
    defaultMessage: 'Search athletes and groups'
  },
  messageAthletesAddedToTestSession: {
    id: 'global.message.athletes-added-to-test-session',
    description: 'Message for athletes added to test session',
    defaultMessage: 'The selected Athletes are added to the session'
  },
  menuItemRemoveSelectedDocuments: {
    id: 'athletes.documents.remove.selected',
    description: 'Menu item: Remove X selected documents',
    defaultMessage:
      'Delete {count, plural, =1 {# selected document} other {# selected documents}}'
  },
  menuItemUnselectedDocumentsToRemove: {
    id: 'athletes.documents.unselected',
    description: 'Menu item: Unselected list items / nothing to remove',
    defaultMessage: 'Select a document to remove it'
  },
  messageTooltipToggleVisibilityDocument: {
    id: 'athletes.documents.visibility.tooltip',
    description: 'Visibility tooltip message',
    defaultMessage:
      'This document is <b>{visibility, select, true {visible} other {hidden}}</b> for the athlete. You can click on this (eye) icon to <b> {visibility, select, true {hide it from them} other {make it visible to them}}</b>.'
  },
  modalConfirmRemovePersonFromOrgTitle: {
    id: 'global.title.modal-confirm-remove-person-from-org',
    description: 'title for modal confirm remove person from org',
    defaultMessage: 'Remove persons from organization'
  },
  modalConfirmRemovePersonFromOrgMessageWithListPersons: {
    id: 'global.message.modal-confirm-remove-person-from-org-with-list',
    description:
      'message for modal confirm remove person from org with list of persons to be removed',
    defaultMessage:
      'Are you sure you want to remove {count, plural, =1 {<b>{count} athlete</b>} other {<b>{count} athletes</b>}} from the organization?<br></br><b>{listPersons}</b>'
  },
  modalConfirmRemovePersonFromGroupTitle: {
    id: 'global.title.modal-confirm-remove-person-from-group',
    description: 'title for modal confirm remove person from group',
    defaultMessage: 'Remove persons from this group'
  },
  modalConfirmRemovePersonFromGroupMessageWithListPersons: {
    id: 'global.message.modal-confirm-remove-person-from-group-with-list',
    description:
      'message for modal confirm remove person from group with list of persons to be removed',
    defaultMessage:
      'Are you sure you want to remove {count, plural, =1 {<b>{count} athlete</b>} other {<b>{count} athletes</b>}} from the group?<br></br><b>{listPersons}</b>'
  },
  addSelectedPersonsButton: {
    id: 'global.button.add-selected-persons',
    description: 'button Add selected persons',
    defaultMessage:
      'Add {count, plural, =0 {athletes} =1 {athlete} other {# athletes}}'
  },
  addPersonsButton: {
    id: 'global.button.add-persons',
    description: 'button Add persons',
    defaultMessage: 'Add athletes'
  },
  excludedEntityLabel: {
    id: 'global.label.excluded-entity',
    description: 'Label for the excluded entity list item',
    defaultMessage: 'Already added'
  },
  excludedGroupLabel: {
    id: 'global.label.excluded-group',
    description: 'Label for the excluded group list item',
    defaultMessage: 'Active group'
  },
  emptyCategoryLabel: {
    id: 'global.label.empty-category',
    description: 'Label for the empty category list item',
    defaultMessage: 'There are no results available for this category.'
  },
  documentsEmptyPanel: {
    id: 'documents.message.empty-panel',
    description: 'message Documents Empty Panel',
    defaultMessage:
      'Hover over the documents on the left to view the download button'
  },
  personInvite: {
    id: 'global.feature.invite',
    description: 'Feature invite persons',
    defaultMessage: 'Invite athletes'
  },
  personInviteDescription: {
    id: 'global.feature.invite-description',
    description: 'Feature invite persons description',
    defaultMessage: 'Invite athletes to your organization'
  },
  organisations: {
    id: 'global.feature.organisations',
    description: 'Feature manage organisations',
    defaultMessage: 'Organisations'
  },
  organisationsDescription: {
    id: 'global.feature.organisations-description',
    description: 'Feature manage organisations description',
    defaultMessage: 'Manage organisations'
  },
  users: {
    id: 'global.feature.users',
    description: 'Feature manage users',
    defaultMessage: 'Users'
  },
  usersDescription: {
    id: 'global.feature.users-description',
    description: 'Feature manage users description',
    defaultMessage: 'Manage users'
  },
  resultsTableHeaderSelectAthletes: {
    id: 'results.table-header.dropdown.select-athletes',
    description:
      'Placeholder for the results table header select athletes dropdown',
    defaultMessage: 'Select athletes'
  },
  resultsTableHeaderFilterByAthlete: {
    id: 'results.table-header.dropdown.filter-by-athlete',
    description: 'Label for the results table header select athletes dropdown',
    defaultMessage: 'Filter by athlete'
  },
  resultsTableHeaderNoTestItems: {
    id: 'results.table-header.dropdown.no-test-items',
    description:
      'No test item placeholder for the results table header select test items dropdown',
    defaultMessage: 'No test items'
  },
  resultsTableHeaderSelectTestItem: {
    id: 'results.table-header.dropdown.select-test-items',
    description:
      'Label for the results table header select test items dropdown',
    defaultMessage: 'Select test'
  },
  openCopyEmbedModal: {
    id: 'trainings.button.open-copy-embed-modal',
    description: 'Button to open the copy embed modal',
    defaultMessage: 'Generate embed url'
  },
  tabTrainingPublish: {
    id: 'trainings.tab.publish.tab',
    description: 'Tab to share/embed the training',
    defaultMessage: 'Publish'
  },
  tabTrainingPublishTitle: {
    id: 'trainings.tab.publish.title',
    description: 'Title of the tab content to share/embed the training',
    defaultMessage: 'Share or embed Training '
  },
  tabTrainingPublishLeadCopy: {
    id: 'trainings.tab.publish.lead-copy',
    description: 'First (introductory) paragraph of share/embed training',
    defaultMessage:
      'You can share this training with your athletes or embed it on a website.'
  },
  tabTrainingPublishDelenTitle: {
    id: 'trainings.tab.publish.share-title',
    description: 'Title for section: Share Training',
    defaultMessage: 'Share Training'
  },
  tabTrainingPublishDelenCopy: {
    id: 'trainings.tab.publish.share-copy',
    description: 'Copy for section: Share Training',
    defaultMessage:
      'Provide your athletes with the link of this training or use this link in an iframe on your website. They do not need a login or password.'
  },
  tabTrainingPublishDelenVisitCopy: {
    id: 'trainings.tab.publish.share-visit-copy',
    description:
      'Copy for section: Share Training. Invitation to visit public page',
    defaultMessage: 'You can also visit the public page of the <a>training</a>'
  },
  tabTrainingPublishEmbedTitle: {
    id: 'trainings.tab.publish.embed-title',
    description: 'Title for section: Embed Training',
    defaultMessage: 'Embed Training'
  },
  tabTrainingPublishEmbedCopy: {
    id: 'trainings.tab.publish.embed-copy',
    description: 'Copy for section: Embed Training',
    defaultMessage:
      'Go to the <a>Demo</a> page. There you will find all the info about embedding a training.'
  },
  tabTrainingEmbed: {
    id: 'trainings.tab.embed',
    description: 'Tab for the training embed',
    defaultMessage: 'Embed'
  },
  tabTrainingAPI: {
    id: 'trainings.tab.api',
    description: 'Tab for the training api',
    defaultMessage: 'API'
  },
  modalTrainingEmbedUrlTitle: {
    id: 'trainings.modal.embed-url.title',
    description: 'Title for the training embed url modal',
    defaultMessage: 'Embed training'
  },
  modalTrainingEmbedSubtitle: {
    id: 'trainings.modal.embed.subtitle',
    description: 'Subtitle for the training embed modal',
    defaultMessage: 'Use these instructions to embed a training in your website'
  },
  modalTrainingEmbedApiKeyLabel: {
    id: 'trainings.modal.embed.key.label',
    description: 'Label for the api key field in the training embed modal',
    defaultMessage: 'Request Header'
  },
  modalTrainingEmbedURLLabel: {
    id: 'trainings.modal.embed.url.label',
    description: 'Label for the url field in the training embed modal',
    defaultMessage: '[GET] Request URL'
  },
  modalTrainingEmbedExampleLabel: {
    id: 'trainings.modal.embed.example.label',
    description: 'Label for the example field in the training embed modal',
    defaultMessage: 'Example cURL request'
  },
  rehabAddIllnessTitle: {
    id: 'rehab.add-illness.title',
    description: 'Title for the add illness modal',
    defaultMessage:
      '{illnessExists, select, true {{illness}} other {Add a new illness}}'
  },
  rehabIllnessTitleLabel: {
    id: 'rehab.illness.input.title',
    description: 'Label for the illness title field',
    defaultMessage: 'Illness'
  },
  rehabIllnessTitlePlaceholder: {
    id: 'rehab.illness.placeholder.title',
    description: 'Placeholder for the illness title field',
    defaultMessage: 'The name of the illness'
  },
  rehabIllnessDateLabel: {
    id: 'rehab.illness.date.label',
    description: 'Label for the illness date field in the add illness modal',
    defaultMessage: 'Date'
  },
  rehabIllnessEndDateLabel: {
    id: 'rehab.illness.end-date.label',
    description:
      'Label for the illness end date field in the add illness modal',
    defaultMessage: 'End date'
  },
  rehabIllnessDescriptionLabel: {
    id: 'rehab.illness.description.label',
    description:
      'Label for the illness description field in the add illness modal',
    defaultMessage: 'Description'
  },
  rehabIllnessExtraInfoLabel: {
    id: 'rehab.illness.extra-info.label',
    description:
      'Label for the illness extra info field in the add illness modal',
    defaultMessage: 'Extra info'
  },
  rehabIllnessSaved: {
    id: 'rehab.illness.message.saved',
    description: 'Message for the illness saved notification',
    defaultMessage: 'Illness is saved'
  },
  titleIllnesses: {
    id: 'rehab.title.illnesses-title',
    description: 'title Illnesses',
    defaultMessage: 'Illnesses'
  },
  titleIllnessDetails: {
    id: 'rehab.title.illness-details',
    description: 'title Illness details',
    defaultMessage: 'Illness details'
  },
  buttonAddIllness: {
    id: 'rehab.button.add-illness',
    description: 'button Add Illness',
    defaultMessage: 'Add Illness'
  },
  buttonShowIllnesses: {
    id: 'rehab.button.show-illnesses',
    description: 'button Show all/less illnesses',
    defaultMessage:
      '{showAll, select, true {Show all illnesses…} false {Show less illnesses…} other {}}'
  },
  rehabAddIssueTitle: {
    id: 'rehab.add-issue.title',
    description: 'Title for the add issue modal',
    defaultMessage:
      '{issueExists, select, true {{issue}} other {Add a new issue}}'
  },
  rehabIssueTitleLabel: {
    id: 'rehab.issue.input.title',
    description: 'Label for the issue title field',
    defaultMessage: 'Issue'
  },
  rehabIssueTitlePlaceholder: {
    id: 'rehab.issue.placeholder.title',
    description: 'Placeholder for the issue title field',
    defaultMessage: 'The name of the issue'
  },
  rehabIssueDateLabel: {
    id: 'rehab.issue.date.label',
    description: 'Label for the issue date field in the add issue modal',
    defaultMessage: 'Date'
  },
  rehabIssueEndDateLabel: {
    id: 'rehab.issue.end-date.label',
    description: 'Label for the issue end date field in the add issue modal',
    defaultMessage: 'End date'
  },
  rehabIssueDescriptionLabel: {
    id: 'rehab.issue.description.label',
    description: 'Label for the issue description field in the add issue modal',
    defaultMessage: 'Description'
  },
  rehabIssueExtraInfoLabel: {
    id: 'rehab.issue.extra-info.label',
    description: 'Label for the issue extra info field in the add issue modal',
    defaultMessage: 'Extra info'
  },
  rehabIssueSaved: {
    id: 'rehab.issue.message.saved',
    description: 'Message for the issue saved notification',
    defaultMessage: 'Issue is saved'
  },
  modalConfirmRemovePersonIllnessTitle: {
    id: 'rehab.illness.title.confirm-remove-person-illness',
    description: 'title Confirm Remove Person illness',
    defaultMessage: 'Remove illness'
  },
  modalConfirmRemovePersonIllnessMessage: {
    id: 'rehab.illness.title.confirm-remove-person-illness-message',
    description: 'message Confirm Remove Person illness',
    defaultMessage: 'Are you sure you want to delete this illness?'
  },
  modalConfirmRemovePersonIssueTitle: {
    id: 'rehab.issue.title.confirm-remove-person-issue',
    description: 'title Confirm Remove Person issue',
    defaultMessage: 'Remove issue'
  },
  modalConfirmRemovePersonIssueMessage: {
    id: 'rehab.issue.title.confirm-remove-person-issue-message',
    description: 'message Confirm Remove Person issue',
    defaultMessage: 'Are you sure you want to delete this issue?'
  },
  rehabIllnessDetailsTab: {
    id: 'rehab.illness.details.tab',
    description: 'Illness details tab',
    defaultMessage: 'Detail'
  },
  rehabIssueDetailsTab: {
    id: 'rehab.issues.details.tab',
    description: 'Issues details tab',
    defaultMessage: 'Detail'
  },
  rehabMenuItemRemovePersonIllness: {
    id: 'rehab.menu-item.remove-person-illness',
    description: 'menu item Rehab Remove Person illness',
    defaultMessage: 'Remove illness'
  },
  rehabMenuItemRemovePersonIssue: {
    id: 'rehab.menu-item.remove-person-issue',
    description: 'menu item Rehab Remove Person issue',
    defaultMessage: 'Remove issue'
  },
  testsetNotFound: {
    id: 'global.testset.not-found',
    description: 'The testset is not found message',
    defaultMessage: "The testset you selected doesn't exist"
  },
  modalSupportTitle: {
    id: 'modal.support.title',
    description: 'Title of the support modal',
    defaultMessage: 'Help & support'
  },
  modalSupportMessage: {
    id: 'modal.support.message',
    description: 'Message of the support modal',
    defaultMessage:
      'Find out how to use the platform in our Help center.<br></br>If you cant find your answer there, get in touch at <a>{email}</a>.'
  },
  modalSupportHanMessage: {
    id: 'modal.support-han.message',
    description: 'Message of the support modal for HAN',
    defaultMessage:
      'Heb je vragen over het gebruik van het platform? Neem dan een kijkje in onze handleiding<br></br>Als je daar je antwoord niet kan vinden, neem dan contact op via <a>{email}</a>.'
  },
  modalSupportOkButton: {
    id: 'modal.support.ok-button',
    description: 'Ok button of the support modal',
    defaultMessage: 'Help center'
  },
  modalSupportHanOkButton: {
    id: 'modal.support-han.ok-button',
    description: 'Ok button of the support modal for HAN',
    defaultMessage: 'Handleiding'
  },
  modalSupportDismissButton: {
    id: 'modal.support.dismiss-button',
    description: 'Dismiss button of the support modal',
    defaultMessage: 'Dismiss'
  },
  programsEmpty: {
    id: 'programs.message.empty',
    description: 'Message for empty programs panel',
    defaultMessage: 'There are no programs yet'
  },
  testItemCategoryAnthropometrics: {
    id: 'testitem.category.label.anthropometrics',
    description: 'Anthropometrics',
    defaultMessage: 'Anthropometrics'
  },
  testItemCategoryFlexibility: {
    id: 'testitem.category.label.flexibility',
    description: 'Flexibility',
    defaultMessage: 'Flexibility'
  },
  testItemCategoryFunctionalAnalysis: {
    id: 'testitem.category.label.functional-analysis',
    description: 'Functional analysis',
    defaultMessage: 'Functional Analysis'
  },
  testItemCategoryStrength: {
    id: 'testitem.category.label.strength',
    description: 'Strength',
    defaultMessage: 'Strength'
  },
  testItemCategoryGrowthprediction: {
    id: 'testitem.category.label.growthprediction',
    description: 'Growth prediction',
    defaultMessage: 'Growth Prediction'
  },
  testItemCategoryCoordination: {
    id: 'testitem.category.label.coordination',
    description: 'Coordination',
    defaultMessage: 'Coordination'
  },
  testItemCategoryEndurance: {
    id: 'testitem.category.label.endurance',
    description: 'Endurance',
    defaultMessage: 'Endurance'
  },
  testItemCategorySpeed: {
    id: 'testitem.category.label.speed',
    description: 'Speed',
    defaultMessage: 'Speed'
  },
  testItemCategorySupplementary: {
    id: 'testitem.category.label.supplementary',
    description: 'Supplementary',
    defaultMessage: 'Supplementary'
  },
  testItemCategoryCoreStability: {
    id: 'testitem.category.label.core-stability',
    description: 'Core stability',
    defaultMessage: 'Core Stability'
  },
  leftRightBadge: {
    id: 'rehab.injury.label.side-badge',
    description:
      'badge label for Injury side(s), so, it can be "Left", "Right" or both',
    defaultMessage:
      '{sides, select, right {Right} left {Left} both {Left + Right} other {}}'
  },
  tabRecurringSessions: {
    id: 'sessions.tab.recurring-sessions',
    description: 'tab recurrring Sessions',
    defaultMessage: 'Recurring Sessions'
  },
  titleRecurringSessionsNewSession: {
    id: 'sessions.title.recurring-sessions-new-session',
    description: 'title recurring Sessions New Session',
    defaultMessage: 'New Recurring Session'
  },
  labelSessionStartDate: {
    id: 'sessions.label.session-start-date',
    description: 'label Session Start Date',
    defaultMessage: 'Start Date'
  },
  labelSessionEndDate: {
    id: 'sessions.label.session-end-date',
    description: 'label Session End Date',
    defaultMessage: 'End Date'
  },
  labelSessionRecurringType: {
    id: 'sessions.label.session-recurring-type',
    description: 'label Session Recurring Type',
    defaultMessage: 'Questionnaire frequency'
  },
  errorSessionRecurringType: {
    id: 'sessions.error.session-recurring-type',
    description: 'label error Recurring Type',
    defaultMessage: 'Questionnaire frequency is required'
  },
  recurringSessionSubtitle: {
    id: 'sessions.subtitle.recurring-session',
    description: 'subtitle recurring session',
    defaultMessage:
      '{type, select, weekly {Weekly} monthly {Monthly} daily {Daily} other {}} session from {startdate} to {enddate}'
  },
  recurringIntervalDaily: {
    id: 'sessions.recurring-interval.daily',
    description: 'recurring interval daily',
    defaultMessage: 'Daily'
  },
  recurringIntervalWeekly: {
    id: 'sessions.recurring-interval.weekly',
    description: 'recurring interval weekly',
    defaultMessage: 'Weekly'
  },
  recurringIntervalMonthly: {
    id: 'sessions.recurring-interval.monthly',
    description: 'recurring interval monthly',
    defaultMessage: 'Monthly'
  },
  settingsProfileTitle: {
    id: 'settings.profile.title',
    description: 'Title of the profile settings page',
    defaultMessage: 'Profile'
  },
  settingsProfileSubTitle: {
    id: 'settings.profile.subtitle',
    description: 'Subtitle of the profile settings page',
    defaultMessage: 'Manage your personal information'
  },
  settingsProfilePersonal: {
    id: 'settings.profile.personal',
    description: 'Title of the personal info in the profile settings',
    defaultMessage: 'Personal info'
  },
  settingsProfileMfa: {
    id: 'settings.mfa.title',
    description: 'Title of the MFA page in the profile settings',
    defaultMessage: 'Two-factor authentication'
  },
  breadcrumbProfile: {
    id: 'account.breadcrumb.profile.label',
    description: 'Label of the profile breadcrumb link',
    defaultMessage: 'Profile'
  },
  breadcrumbMfa: {
    id: 'account.breadcrumb.mfa.label',
    description: 'Label of the MFA breadcrumb link',
    defaultMessage: 'Two-factor authentication'
  },
  settingsProfileMfaSubTitle: {
    id: 'settings.mfa.subtitle',
    description: 'Subtitle of the profile mfa settings page',
    defaultMessage: 'Protect your account with two-factor authentication'
  },
  settingsProfileMfaIntroTitle: {
    id: 'settings.mfa.intro.title',
    description: 'Title: Intro over 2FA',
    defaultMessage: 'What is a 2FA?'
  },
  settingsProfileMfaIntroText: {
    id: 'settings.mfa.intro.copy',
    description: 'Intro/Explanation about 2FA',
    defaultMessage:
      'Two-factor authentication (2FA) adds an extra security layer to online accounts by demanding two types of verification: something you know (password) and something you have (phone code or fingerprint). This dual-check system thwarts unauthorized access, even if passwords are compromised. Using 2FA doubles account security and safeguards against cyber threats.'
  },
  settingsProfileMfaIntroTextBridge: {
    id: 'settings.mfa.intro.bridge',
    description: 'Bridge to the Steps',
    defaultMessage: "To enable it, you'll need to:"
  },
  settingsProfileMfaTitleStep1: {
    id: 'settings.mfa.step1.title',
    description: 'Step 1 of the MFA page in the profile settings',
    defaultMessage: 'Step 1'
  },
  settingsProfileMfaMessageStep1: {
    id: 'settings.mfa.step1.message',
    description: 'Step 1 of the MFA page in the profile settings',
    defaultMessage:
      'Install (or open) an <button>authenticator app</button> on your phone (or computer) and scan the provided QR code.'
  },
  settingsProfileMfaListApps: {
    id: 'settings.mfa.step2.list-apps',
    description: 'tooltip information',
    defaultMessage:
      'i.e.: FreeOTP+, Google Auth, Microsoft Auth, Aegis, Raivo, Tofu, 2FAS'
  },
  settingsProfileMfaStep1Extra: {
    id: 'settings.mfa.step1.extra',
    description: 'Extra info of the MFA page in the profile settings',
    defaultMessage:
      'Unable to scan? Get a <button>token code</button> and add it to your auth app.'
  },
  settingsProfileMfaStep1CopiedToken: {
    id: 'settings.mfa.step1.token.notification',
    description:
      'Notification message when token is copied after clicking the button',
    defaultMessage: 'Secret successfully copied to your clipboard!'
  },
  settingsProfileMfaTitleStep2: {
    id: 'settings.mfa.step2.title',
    description: 'Step 2 of the MFA page in the profile settings',
    defaultMessage: 'Step 2'
  },
  settingsProfileMfaMessageStep2: {
    id: 'settings.mfa.step2.message',
    description: 'Step 2 of the MFA page in the profile settings',
    defaultMessage:
      'Enter the 6-digit code provided by the authentication app into the input field below.'
  },
  settingsProfileMfaSubmitButton: {
    id: 'settings.mfa.submit',
    description: 'Submit button',
    defaultMessage: 'Activate'
  },
  settingsProfileMfaActiveMessage: {
    id: 'settings.mfa.active',
    description: 'Message shown when 2FA is active',
    defaultMessage: 'Two-factor authentication is active!'
  },
  settingsProfileMfaDeactivateButton: {
    id: 'settings.mfa.deactivate.button',
    description: 'Deactivate 2FA button',
    defaultMessage: 'Deactivate your 2FA'
  },
  settingsProfileMfaDeactivateModalTitle: {
    id: 'settings.mfa.deactivate.modal.title',
    description: 'Modal title: Deactivate.',
    defaultMessage: 'Two-factor deactivate'
  },
  settingsProfileMfaDeactivateModalBody: {
    id: 'settings.mfa.deactivate.modal.body',
    description: 'Modal body: Deactivate.',
    defaultMessage:
      'Het is sterk aangeraden om 2-factor authenticatie te gebruiken. Wil je zeker de 2-Factor authenticatie deactiveren?'
  },
  totpFormTitle: {
    id: 'settings.mfa.settings.mfa.totp.form.title',
    description: 'Title of the TOTP form',
    defaultMessage: 'Multifactor authentication'
  },
  totpFormSubtitle: {
    id: 'settings.mfa.totp.form.subtitle',
    description: 'Subtitle of the TOTP form',
    defaultMessage: 'Enter the code from your authenticator app'
  },
  totpFormCodeLabel: {
    id: 'settings.mfa.totp.form.code.label',
    description: 'Label of the TOTP code input',
    defaultMessage: 'Code'
  },
  totpFormSubmitButton: {
    id: 'settings.mfa.totp.form.submit.button',
    description: 'Label of the TOTP submit button',
    defaultMessage: 'Submit'
  },
  totpSetupErrorMessage: {
    id: 'settings.mfa.totp.setup.error.message',
    description: 'Error message of the TOTP setup',
    defaultMessage: 'The code you submitted is not valid'
  },
  totpSetupErrorMaxSix: {
    id: 'settings.mfa.totp.setup.error.max-six',
    description: 'Max characters allowed',
    defaultMessage: 'Please ensure your entry consists of 6 digits'
  },
  totpSetupErrorDigits: {
    id: 'settings.mfa.totp.setup.error.digits',
    description: 'Only digits allowed',
    defaultMessage: 'The code must be composed solely of numbers'
  },
  timeNavigatorCurrentDate: {
    id: 'time-navigator.current-date',
    description: 'Current date label for the time navigator',
    defaultMessage: 'You are currently seeing data from:'
  },
  timeNavigatorPreviousDate: {
    id: 'time-navigator.previous-date',
    description: 'Previous date label for the time navigator',
    defaultMessage: 'Previous date'
  },
  timeNavigatorNextDate: {
    id: 'time-navigator.next-date',
    description: 'Next date label for the time navigator',
    defaultMessage: 'Next date'
  },
  exportDataLabel: {
    id: 'account.export.label',
    description: 'Label of the export data link',
    defaultMessage: 'Export data'
  },
  exportDataTitle: {
    id: 'account.export.title',
    description: 'Title of the export data page',
    defaultMessage: 'Export data'
  },
  exportDataLabelSubTitle: {
    id: 'account.export.subtitle',
    description: 'Label of the export data link',
    defaultMessage: 'Export data for all your organisations'
  },
  breadcrumbExportData: {
    id: 'account.breadcrumb.export.label',
    description: 'Label of the breadcrumb link',
    defaultMessage: 'Export'
  },
  exportDataCardTitle: {
    id: 'account.export.card.title',
    description: 'Title of the export card',
    defaultMessage: 'Export Sessions'
  },
  exportDataInfo: {
    id: 'account.export.info',
    description: 'Info message for the export data page',
    defaultMessage:
      'Select a test set and the range and click the Export button to generate the export data.'
  },
  exportDataButton: {
    id: 'account.export.button',
    description: 'Button to submit the export form',
    defaultMessage: 'Export'
  },
  globalStartdateLabel: {
    id: 'global.startdate.label',
    description: 'The label for the startdate input',
    defaultMessage: 'Startdate'
  },
  globalEnddateLabel: {
    id: 'global.enddate.label',
    description: 'The label for the enddate input',
    defaultMessage: 'Enddate'
  },
  exportDataSuccessAnswer: {
    id: 'account.exportdata.success.answer',
    description: 'The success message for the export data request',
    defaultMessage:
      'We have sent you an email with an export of the session data for the period <b>{startdate}</b> - <b>{enddate}</b>'
  },
  exportDataFailedAnswer: {
    id: 'account.exportdata.error.answer',
    description: 'The error message for the export data request',
    defaultMessage:
      'There was an error when exporting the data. Please try again later or contact support if the problem persists.'
  },
  generateMagicLinkButton: {
    id: 'person.magic-link.generate.button',
    description: 'Button to generate a magic link',
    defaultMessage: 'Generate magic login link'
  },
  generateMagicLinkButtonDisabled: {
    id: 'person.magic-link.generate-disabled.button',
    description: 'Disabled button to generate a magic link',
    defaultMessage:
      'Generate magic login link (active when person has an email)'
  },
  modalGenerateMagicURLTitle: {
    id: 'person.magic-link.copy.title',
    description: 'Title for the modal to copy the magic link',
    defaultMessage: 'Login link'
  },
  modalGenerateMagicURLSubTitle: {
    id: 'person.magic-link.copy.subtitle',
    description: 'Subtitle for the modal to copy the magic link',
    defaultMessage: 'Copy this login link to share it with the athlete'
  },
  modalGenerateMagicURLLabelUrl: {
    id: 'person.magic-link.copy.label-url',
    description: 'Label for the url field in the modal to copy the magic link',
    defaultMessage: 'Login link'
  },
  modalGenerateMagicURLCopied: {
    id: 'person.magic-link.copy.copied',
    description: 'Message when magic link is copied',
    defaultMessage: 'Magic link copied to clipboard!'
  },
  recurringSessions: {
    id: 'sessions.recurring-sessions',
    description: 'Recurring sessions',
    defaultMessage: 'Recurring sessions'
  },
  recurringSessionsDescription: {
    id: 'sessions.recurring-sessions-description',
    description: 'Recurring sessions description',
    defaultMessage: 'Manage recurring sessions'
  },
  userProgramsEmptyMessage: {
    id: 'user.programs.programs-empty-message',
    description: 'Message for empty programs panel',
    defaultMessage: 'You have no programs yet'
  },
  exercisesFilterLabelMyOrg: {
    id: 'exercises.filter.label.my-org',
    description: 'Label for the my org filter',
    defaultMessage: 'Only show the exercises of my organisation'
  },
  exercisesTagMyOrg: {
    id: 'exercises.filter.tag.my-org',
    description:
      'Label for the tag when "show exercises of my organisation" is selected',
    defaultMessage: 'Own Exercises'
  },
  programEmptyExercises: {
    id: 'exercises.program.message.empty-exercises',
    description: 'message Program Empty Exercises',
    defaultMessage: 'Please add some exercises first...'
  },
  exercisesEditProgramButton: {
    id: 'exercises.program.button.edit-program',
    description: 'button Exercises Edit program',
    defaultMessage: 'Edit program'
  },
  programMarkedAsDone: {
    id: 'sporter.program.message.marked-as-done',
    description: 'message Program Marked as done',
    defaultMessage: 'Program marked as done'
  },
  sortProgramButton: {
    id: 'exercises.program.sort.button.enable',
    description: 'Label for sort button for program menu',
    defaultMessage: 'Sort program'
  },
  exercisesRemoveTemplateButton: {
    id: 'exercises.button.remove-template',
    description: 'button Exercises Template Remove',
    defaultMessage: 'Remove template'
  },
  collectionDetailTitle: {
    id: 'exercises.collection-detail.title.collection-details',
    description: 'title Collection Details',
    defaultMessage: 'Collection details'
  },
  exercisesRemoveExerciseFromProgramButton: {
    id: 'exercises.button.remove-selected-exercise-from-program',
    description: 'button Selected Exercises Remove from program',
    defaultMessage:
      'Remove {count, plural, =1 {this exercise} other {# exercises}} from your program'
  },
  modalConfirmRemoveExerciseFromProgramTitle: {
    id: 'exercises.title.confirm-remove-exercise-from-program',
    description: 'title Confirm Remove Exercise from program',
    defaultMessage:
      'Remove {count, plural, =1 {exercise} other {exercises}} from program'
  },
  modalConfirmRemoveExerciseFromProgramMessage: {
    id: 'exercises.message.confirm-remove-exercise-from-program',
    description: 'message Confirm Remove Exercise from program',
    defaultMessage:
      'Are you sure you want to delete {count, plural,  =1 {this exercise} other {# exercises}} from your program?'
  },
  messageEditProgramSuccess: {
    id: 'programs.program.message.edit.success',
    description: 'Status notification after program edit',
    defaultMessage: 'Program is updated'
  },
  programPersons: {
    id: 'programs.program.persons',
    description: 'Persons label for a program',
    defaultMessage: 'Athletes'
  },

  modalConfirmRemovePersonFromProgramTitle: {
    id: 'programs.title.confirm-remove-person-from-program-title',
    description: 'title Confirm Remove person from Program',
    defaultMessage:
      'Remove {count, plural, =1 {this person} other {these # persons}} from your program'
  },
  modalConfirmRemovePersonFromProgramMessage: {
    id: 'programs.title.confirm-remove-person-from-program-message',
    description: 'message Confirm Remove person from Program',
    defaultMessage:
      'Are you sure you want to delete {count, plural, =1 {this person} other {these # persons}} from your program?'
  },
  messagePersonsRemovedFromProgram: {
    id: 'global.message.persons-removed-from-program',
    description: 'Message for persons removed from program',
    defaultMessage: 'The selected persons have been removed from the program'
  },
  messagePersonsAddedToProgram: {
    id: 'global.message.persons-added-to-program',
    description: 'Message for persons added to program',
    defaultMessage: 'The selected persons have been added to the program'
  },
  buttonRemoveSelectedPersonsEnabled: {
    id: 'global.button.remove-selected-persons.enabled',
    description: 'button remove persons',
    defaultMessage:
      'Remove {count} selected {count, plural, =1 {person} other {persons}} from this program'
  },
  buttonRemoveSelectedPersonsDisabled: {
    id: 'global.button.remove-selected-persons.disabled',
    description: 'disabled button remove persons',
    defaultMessage: 'Select one or more persons in order to remove them'
  },
  selectAllPersonsButton: {
    id: 'button.select-all-persons',
    description: 'button Select All Persons',
    defaultMessage: 'Select all persons'
  },
  selectAllButton: {
    id: 'button.select-all-generic',
    description: 'button Select All (Generic)',
    defaultMessage: 'Select all persons ({count})'
  },
  selectSomePersonsButton: {
    id: 'button.select-some-persons-with-length',
    description: 'button select X persons',
    defaultMessage:
      'Select {count} found {count, plural, =1 {person} other {persons}}'
  },
  clearSelection: {
    id: 'button.clear-select-all',
    description:
      'unambiguous label to remove selection/clean up selection/ deselect all',
    defaultMessage: 'Clear selection ({count})'
  },
  searchInputGeneralFeedback: {
    id: 'input.search.general.by-string.feedback',
    description:
      'text giving feedback about the terms for items (in general) on the search',
    defaultMessage:
      '{count, plural, =0 {Nothing found containing: "<b>{searchTerm}</b>"} =1 {<b>{count}</b> item found containing: "<b>{searchTerm}</b>"} other {<b>{count}</b> items found containing: "<b>{searchTerm}</b>"}}'
  },
  searchInputPersonsFeedback: {
    id: 'input.search.persons.by-string.feedback',
    description:
      'text giving feedback about the terms for persons added on the search',
    defaultMessage:
      '{count, plural, =0 {No persons\' names found containing: "<b>{searchTerm}</b>"} =1 {<b>{count}</b> persons\' name found containing: "<b>{searchTerm}</b>"} other {<b>{count}</b> persons\' names found containing: "<b>{searchTerm}</b>"}}'
  },
  searchInputGroupsFeedback: {
    id: 'input.search.groups.by-string.feedback',
    description:
      'text giving feedback about the terms for groups added on the search',
    defaultMessage:
      '{count, plural, =0 {No groups\' names found containing: "<b>{searchTerm}</b>"} =1 {<b>{count}</b> groups\' name found containing: "<b>{searchTerm}</b>"} other {<b>{count}</b> groups\' names found containing: "<b>{searchTerm}</b>"}}'
  },
  searchInputPersonsAndGroupsFeedback: {
    id: 'input.search.persons-and-groups.by-string.feedback',
    description:
      'text giving feedback about the terms for groups and persons added on the search',
    defaultMessage:
      '{countPersons, plural, =0 {No persons\' names} =1 {"<b>#</b>" persons\' name} other {"<b>#</b>" persons\' names}} and {countGroups, plural, =0 {no groups\' names} =1 {"<b>#</b>" groups\' name} other {"<b>#</b>" groups\' names}} found containing: "<b>{searchTerm}</b>"'
  },
  filterByYearFeedback: {
    id: 'input.search.by-year.feedback',
    description:
      'text giving feedback about how many groups with the selected year were found',
    defaultMessage:
      '{count, plural, =0 {No group assigned to the year <b>{year}</b>} =1 {<b>{count}</b> group found for the year <b>{year}</b>} other {<b>{count}</b> groups found for the year <b>{year}</b>}}'
  },
  searchSessions: {
    id: 'sessions.session.search.placeholder-label',
    description: 'text giving instructions to the user on search input field',
    defaultMessage: 'Search sessions'
  },
  errorModalTitle: {
    id: 'global.error-modal.title',
    description: 'Default title for the error modal',
    defaultMessage: 'There was an error'
  },
  errorModalMessageNotAuthorized: {
    id: 'global.error-modal.message.not_authorized',
    description: 'Not authorized message',
    defaultMessage:
      'You are not authorized to view this page or perform this action'
  },
  errorMessageBenchmark: {
    id: 'global.error.error-message-benchmark',
    description: 'label Error Message benchmark',
    defaultMessage:
      'Error! There was a problem loading the benchmarks. Please refresh the page.'
  },
  exercisesSortExercisesModalWarningTitle: {
    id: 'exercises.sort-exercises.modal-warning-title',
    description: 'Warning title for the sort exercises modal',
    defaultMessage: 'Warning'
  },
  exercisesSortExercisesModalWarningMessage: {
    id: 'exercises.sort-exercises.modal-warning-message',
    description: 'Warning message for the sort exercises modal',
    defaultMessage:
      'You are sorting exercises. Are you sure you want to leave this page and cancel your sort?'
  },
  previous: {
    id: 'global.keywords.previous',
    description: 'Word: "Previous"',
    defaultMessage: 'Previous'
  },
  next: {
    id: 'global.keywords.next',
    description: 'Word: "Next"',
    defaultMessage: 'Next'
  },
  notificationUrlCopied: {
    id: 'notification.successful-copy.url',
    description: 'Notification message shown when URL is copied to clipboard',
    defaultMessage: 'Link successfully copied to your clipboard!'
  },
  notificationContentCopied: {
    id: 'notification.successful-copy.content',
    description:
      'Notification message shown when Generic Content is copied to clipboard',
    defaultMessage: 'Content successfully copied to your clipboard!'
  },
  growthTrackerEstimationMethodOptions: {
    id: 'growthtracker.estimation-method.options.label',
    description: 'Label for the estimation method options',
    defaultMessage: "Growth Tracking's Estimation method"
  },
  growthTrackerEstimationMethodKhamisRoche: {
    id: 'growthtracker.settings.label.method-khamis-roche',
    description: 'Label for the Khamis Roche estimation method',
    defaultMessage: 'Khamis Roche'
  },
  growthTrackerValueUnit: {
    id: 'growthtracker.table.value-unit',
    description: 'units translated',
    defaultMessage:
      '{value, number, ::.0#} {unit, select, yearShort {<span>yr</span>} yearLong {<span>year</span>} cm {<span>cm</span>} cmYearShort {<span>cm/yr</span>} cmYearLong {<span>cm/year</span>} kg {<span>kg</span>} kgYearShort {<span>kg/yr</span>} kgYearLong {<span>kg/year</span>} other {{unit}}}'
  },
  growthTrackerPahLegendLvl0: {
    id: 'growthtracker.test-item.pah-alert-level-0',
    description: 'Legende shown on tooltip "% PAH (K-R)" Level 0: red',
    defaultMessage:
      '<b>{person}</b> is in the middle of the growth spurt and therefore has an increased risk for sports injuries. A reduction of training load is advised'
  },
  growthTrackerPahLegendLvl1: {
    id: 'growthtracker.test-item.pah-alert-level-1',
    description: 'Legende shown on tooltip "% PAH (K-R)" Level 1: orange',
    defaultMessage:
      '<b>{person}</b> is approaching the growth spurt. There might be a slight increase in injury risk. Extra caution is advised in case this athlete has been injured recently'
  },
  growthTrackerPahLegendLvl3: {
    id: 'growthtracker.test-item.pah-alert-level-3',
    description: 'Legende shown on tooltip "% PAH (K-R)" Level 3: lime',
    defaultMessage:
      '<b>{person}</b> is still before the growth spurt. This growth phase is not associated with an increased risk of sports injuries'
  },
  growthTrackerPahLegendLvl4: {
    id: 'growthtracker.test-item.pah-alert-level-4',
    description: 'Legende shown on tooltip "% PAH (K-R)" Level 4: green',
    defaultMessage:
      '<b>{person}</b> is past the growth spurt. The growth velocity will gradually decrease, and with it, the risk of growth-related sports injuries will also decrease'
  },
  generateQrCode: {
    id: 'global.button.generate-qr-code',
    description: 'Button to generate a QR code',
    defaultMessage: 'Generate QR codes'
  },
  buttonPrint: {
    id: 'global.button.print',
    description: 'Button to print',
    defaultMessage: 'Print'
  },
  mirrorIntegration: {
    id: 'global.mirror-integration',
    description: 'Mirror Integration',
    defaultMessage: 'Mirror Integration'
  },
  mirrorIntegrationDescription: {
    id: 'global.mirror-integration-description',
    description: 'Mirror Integration Description',
    defaultMessage: 'Integration with Mirror'
  },
  labelButtonStartMirror: {
    id: 'global.label-button-start-mirror',
    description: 'Label Button Start Mirror',
    defaultMessage: 'Mirror integration'
  },
  buttonStartMirror: {
    id: 'global.button-start-mirror',
    description: 'Button Start Mirror',
    defaultMessage: 'Send Mirror invite to the person'
  },
  sendMirrorInviteTitle: {
    id: 'global.send-mirror-invite-title',
    description: 'Send Mirror Invite Title',
    defaultMessage: 'Send Mirror invite'
  },
  sendMirrorInviteMessage: {
    id: 'global.send-mirror-invite-message',
    description: 'Send Mirror Invite Message',
    defaultMessage:
      'Send an invite to the person to connect their Mirror account'
  },
  messageMirrorInviteSent: {
    id: 'global.message-mirror-invite-sent',
    description: 'Message Mirror Invite Sent',
    defaultMessage: 'Mirror invite sent'
  },
  buttonOpenMirror: {
    id: 'global.button-open-mirror',
    description: 'Button Open Mirror',
    defaultMessage: 'Open Mirror Dashboard'
  },
  modalConfirmOpenMirrotTitle: {
    id: 'global.modal-confirm-open-mirror-title',
    description: 'Modal Confirm Open Mirror Title',
    defaultMessage: 'Open Mirror Dashboard'
  },
  modalConfirmOpenMirrorMessage: {
    id: 'global.modal-confirm-open-mirror-message',
    description: 'Modal Confirm Open Mirror Message',
    defaultMessage: 'This link will open your Mirror dashboard in a new tab'
  },
  modalConfirmOpenMirrorAcceptButton: {
    id: 'global.modal-confirm-open-mirror-accept-button',
    description: 'Modal Confirm Open Mirror Accept Button',
    defaultMessage: 'Go to Mirror'
  },
  labelNoEmail: {
    id: 'global.label-no-email',
    description: 'Label No Email',
    defaultMessage: 'This person is missing a valid email address'
  },
  menuItemSessionGenerateQRCodes: {
    id: 'global.menu-item.session-generate-qr-codes',
    description: 'Menu Item Session Generate QR Codes',
    defaultMessage: 'Generate QR codes'
  },
  modalQRCodesTitle: {
    id: 'global.modal-qr-codes-title',
    description: 'Modal QR Codes Title',
    defaultMessage: 'Print QR codes'
  },
  menuItemNoSelectedPersonsTooltip: {
    id: 'global.menu-item.no-selected-persons-tooltip',
    description: 'Menu Item No Selected Persons Tooltip',
    defaultMessage: 'Select one or more persons to enable this action'
  },
  menuItemNoSelectedGroupTooltip: {
    id: 'global.menu-item.no-selected-group-tooltip',
    description: 'Menu Item No Selected Group Tooltip',
    defaultMessage: 'Select a group to enable this action'
  },

  organisationsTitleOrganisationSupportDetails: {
    id: 'organisations.organisation.title.support-details',
    description: 'title Organisation Support Details',
    defaultMessage: 'Support info'
  },
  labelOrganisationSupportEmail: {
    id: 'organisations.organisation.label.support-email',
    description: 'label Organisation Support Email',
    defaultMessage: 'Support email'
  },
  labelOrganisationSupportLink: {
    id: 'organisations.organisation.label.support-link',
    description: 'label Organisation Support Link',
    defaultMessage: 'Support link'
  }
});
