import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef
} from 'react';
import * as Sentry from '@sentry/react';
import { GrowthTrackerEstimationMethods, Lateralities } from 'constants.js';
import { StoreContext } from 'index';
import { sort, SORT_DATA_TYPES, SORT_TYPES } from 'utils/sort';
import { useHistory } from 'react-router-dom';
import { differenceInWeeks, format } from 'date-fns';
import { useStore } from 'stores/Store';
import { QUERY_GET_GROWTH_DATA_OF_GROUP } from 'services/aws/growthtracker-query';
import { useLazyQuery } from '@apollo/client';

export const GrowthPanelContext = createContext({});

// TODO: Using this to determine width of columns
// aswell the labels
const GrowthTrackerBasicColumns = Object.freeze({
  PRECISE_AGE: {
    id: 'preciseAge',
    label: 'Age',
    width: 48,
    widthExpanded: 64
  },
  PREDICTED_ADULT_HEIGHT: {
    id: 'predictedAdultHeight',
    label: '% Predicted<br />Adult Height (K-R)',
    width: 96,
    widthExpanded: 120
  },
  HEIGHT_VELOCITY: {
    id: 'heightVelocity',
    label: 'Height<br />Velocity <span>(cm/year)</span>',
    width: 96,
    widthExpanded: 120
  },
  WEIGHT_VELOCITY: {
    id: 'weightVelocity',
    label: 'Weight<br />Velocity <span>(kg/year)</span>',
    width: 96,
    widthExpanded: 120,
    defaultView: true
  }
});

const GrowthTrackerFullColumns = Object.freeze({
  PRECISE_AGE: {
    id: 'preciseAge',
    label: 'Age',
    width: 48,
    widthExpanded: 64,
    isBasic: true
  },
  BODY_HEIGHT: {
    id: 'bodyHeight',
    label: 'Body Height',
    width: 64,
    widthExpanded: 64
  },
  BODY_WEIGHT: {
    id: 'bodyWeight',
    label: 'Body Weight',
    width: 64,
    widthExpanded: 64
  },
  ADULT_HEIGHT_PREDICTION: {
    id: 'adultHeightPrediction',
    label: 'A. H.<br />Predict.',
    width: 64,
    widthExpanded: 96
  },
  PAH_90_CI: {
    id: 'pah90CI',
    label: 'PAH 90% CI',
    width: 96,
    widthExpanded: 120
  },
  PREDICTED_ADULT_HEIGHT: {
    id: 'predictedAdultHeight',
    label: '% Pred. Ad.<br />Height (K-R)',
    width: 96,
    widthExpanded: 120,
    isBasic: true
  },
  GROWTH_PHASE: {
    id: 'growthPhase',
    label: 'Growth<br />phase',
    width: 96,
    widthExpanded: 120
  },
  DEVELOPMENT_ZSCORE: {
    id: 'devZScore',
    label: 'Development<br /><span>(Z-score)</span>',
    width: 96,
    widthExpanded: 120
  },
  HEIGHT_VELOCITY: {
    id: 'heightVelocity',
    label: 'Height<br />Velocity<br /><span>(cm/year)</span>',
    width: 96,
    widthExpanded: 120,
    isBasic: true
  },
  WEIGHT_VELOCITY: {
    id: 'weightVelocity',
    label: 'Weight<br />Velocity<br /><span>(kg/year)</span>',
    width: 96,
    widthExpanded: 120,
    isBasic: true
  },
  BIO_AGE: {
    id: 'bioAge',
    label: 'Bio-Age',
    width: 48,
    widthExpanded: 64
  },
  CHRONO_VS_AGE: {
    id: 'chronoVsBioAge',
    label: 'Chrono<br />vs Bio Age',
    width: 96
  }
});

const GrowthPanelContextProvider = ({ groupId, children }) => {
  const {
    authStore: {
      user: { rootEntityId }
    },
    uiState: { locale }
  } = useContext(StoreContext);

  const { replace } = useHistory();
  const location = useHistory();
  const selectedHeaders = useStore.getState().selectedHeaders;
  const { setSelectedHeaders, resetSelectedHeaders } = useStore(state => state);

  const usersArray = useRef([]);
  const testItemsArray = useRef([]);
  const testSetsArray = useRef([]);

  const [athletes, setAthletes] = useState([]);
  const [athletesSortDirection, setAthletesSortDirection] = useState(false);
  const [testItems, setTestItems] = useState([]);
  const [testSets, setTestSets] = useState([]);
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [sidePanelData, setSidePanelData] = useState(null);
  const [sortIndex, setSortIndex] = useState(-1);
  const [error, setError] = useState(null);
  const [method, setMethod] = useState(
    GrowthTrackerEstimationMethods.KHAMIS_ROCHE
  );

  const [fetchGrowthDataByGroup, { loading, error: fetchError, refetch }] =
    useLazyQuery(QUERY_GET_GROWTH_DATA_OF_GROUP);

  /*
  const data = [
    {
      person: {
        id: '08c09051-4b81-4302-a172-4d1ec043c202',
        firstname: 'Zoef',
        lastname: 'de Haas',
        birthdate: '2013-02-26',
        gender: 'female',
        preciseAge: 11
      },
      preciseAge: 11,
      ageAtTestDate: 11.4688,

      //   predictedAdultHeight: 97.12,
      //   heightVelocity: 12.5,
      //   weightVelocity: 7.74,
      //   testdate: new Date('2024-07-27'),
      //   weekDiff: differenceInWeeks(new Date(), new Date('2024-07-27')),
      //   alert: differenceInWeeks(new Date(), new Date('2024-07-27')) > 10,
      //   testdata: ['TestDataV2']
      testItemValues: {
        '19688d86-a4e8-4cfb-966d-69bdf035f6ab': [124.3], // Body Height
        'a2a243bc-8d3e-4eb8-9837-9a3bb814488d': [42], // Body Weight
        // 'b25b2bc3-f722-4eba-b754-a343a19496f4': [160], // Body Height Father
        // 'f8a705cb-653b-46ea-be13-5a8e68386e34': [163], // Body Height Mother
        '5737716d-7eb3-4724-85d2-7baa4652ae93': [170.3], // Adult Height Prediction (K-R)
        'face72bd-d260-44a3-b55c-3bae9e3d18dc': [82.5], // % Adult Height Prediction
        '5737716d-7eb3-4724-85d2-7baa4652XXXX': [12], // Bio Age (K-R)
        '0c58cad6-8a6f-402f-b30e-b00e06871f14': ['on-time'], // Development (K-R) // Development Maturation Timing
        '105b2d29-1469-4d27-bdc0-9d9b6c44b915': [0.4702948141263], // Development (K-R) Z-score
        '491e8645-9273-4ef4-be2d-5d09363ac088': [-0.6], // Chrono vs Bio Age
        '197bbf37-7db5-40c4-9675-0dec23339b8e': ['pre-PHV'], // Growth Phase
        'f7529cf9-ba05-4570-bedd-6213ee3b6ec8': ['174.21-190.22'] // Adult Height Prediction 90% CI
      },
      heightEvolutionData: [
        {
          values: [124.3],
          id: '50e1212d-8bcf-4b37-961f-fbdf3782b73c',
          testDateTime: '2024-08-10',
          divValue: 1.8000000000000114,
          divDays: 133,
          growthYear: 4.939849624060181,
          alertLevel: 1
        }
      ],
      weightEvolutionData: [
        {
          values: [42],
          id: 'd8cb6244-4971-467f-9c39-26dd90acba49',
          testDateTime: '2024-08-09',
          divValue: 2.8999999999999986,
          divDays: 133,
          growthYear: 7.95864661654135,
          alertLevel: 0
        }
      ]
    },
    {
      person: {
        id: 'aefb032e-b626-4e2d-a3f3-dd935295c6bb',
        firstname: 'Isadora',
        lastname: 'Paradijsvogel',
        __typename: 'PersonV2',
        birthdate: '2009-02-26',
        gender: 'female',
        preciseAge: 11.56
      },
      preciseAge: 11.56,
      ageAtTestDate: 9.4688,

      //   predictedAdultHeight: 94.12,
      //   heightVelocity: 2.65,
      //   weightVelocity: 1.5,
      //   testdate: new Date('2023-07-27'),
      //   weekDiff: differenceInWeeks(new Date(), new Date('2023-07-27')),
      //   alert: differenceInWeeks(new Date(), new Date('2023-07-27')) > 10,
      //   testdata: ['TestDataV2']
      testItemValues: {
        '19688d86-a4e8-4cfb-966d-69bdf035f6ab': [174.3], // Body Height
        'a2a243bc-8d3e-4eb8-9837-9a3bb814488d': [60], // Body Weight
        // 'b25b2bc3-f722-4eba-b754-a343a19496f4': [170], // Body Height Father
        // 'f8a705cb-653b-46ea-be13-5a8e68386e34': [168], // Body Height Mother
        '5737716d-7eb3-4724-85d2-7baa4652ae93': [175.8657281803], // Adult Height Prediction (K-R)
        'face72bd-d260-44a3-b55c-3bae9e3d18dc': [99.10970250059478], // % Adult Height Prediction
        '5737716d-7eb3-4724-85d2-7baa4652XXXX': [17], // Bio Age (K-R)
        '0c58cad6-8a6f-402f-b30e-b00e06871f14': ['on-time'], // Development (K-R) // Development Maturation Timing
        '105b2d29-1469-4d27-bdc0-9d9b6c44b915': [0.54702948141263], // Development (K-R) Z-score
        '491e8645-9273-4ef4-be2d-5d09363ac088': [-0.6], // Chrono vs Bio Age
        '197bbf37-7db5-40c4-9675-0dec23339b8e': ['pre-PHV'], // Growth Phase
        'f7529cf9-ba05-4570-bedd-6213ee3b6ec8': ['174.21-190.22'] // Adult Height Prediction 90% CI
      },
      heightEvolutionData: [
        {
          values: [174.3],
          id: '50e1212d-8bcf-4b37-961f-fbdf3782b73c',
          testDateTime: 1679180400000,
          divValue: 1.8000000000000114,
          divDays: 133,
          growthYear: 4.939849624060181,
          alertLevel: 3
        }
      ],
      weightEvolutionData: [
        {
          values: [60],
          id: 'd8cb6244-4971-467f-9c39-26dd90acba49',
          testDateTime: 1679180400000,
          divValue: 2.8999999999999986,
          divDays: 133,
          growthYear: 7.95864661654135,
          alertLevel: 1
        }
      ]
    },
    {
      person: {
        id: '45af17c1-bf7a-4e3c-81f8-d91cbb47af1d',
        firstname: 'Zaza',
        lastname: 'Zebra',
        __typename: 'PersonV2',

        birthdate: '2008-03-22',
        gender: 'male',
        preciseAge: 23.1
      },
      preciseAge: 23.1,
      ageAtTestDate: 14.988364134154688,
      //   predictedAdultHeight: 82.15,
      //   heightVelocity: 3.5,
      //   weightVelocity: 6.88,
      //   testdate: new Date('2024-07-27'),
      //   weekDiff: differenceInWeeks(new Date(), new Date('2024-07-27')),
      //   alert: differenceInWeeks(new Date(), new Date('2024-07-27')) > 10,
      //   testdata: ['TestDataV2'],
      testItemValues: {
        '19688d86-a4e8-4cfb-966d-69bdf035f6ab': [174.3], // Body Height
        'a2a243bc-8d3e-4eb8-9837-9a3bb814488d': [60], // Body Weight
        // 'b25b2bc3-f722-4eba-b754-a343a19496f4': [170], // Body Height Father
        // 'f8a705cb-653b-46ea-be13-5a8e68386e34': [168], // Body Height Mother
        '5737716d-7eb3-4724-85d2-7baa4652ae93': [175.8657281803], // Adult Height Prediction (K-R)
        'face72bd-d260-44a3-b55c-3bae9e3d18dc': [94.10970250059478], // % Adult Height Prediction
        '5737716d-7eb3-4724-85d2-7baa4652XXXX': [17], // Bio Age (K-R)
        '0c58cad6-8a6f-402f-b30e-b00e06871f14': ['on-time'], // Development (K-R) // Development Maturation Timing
        '105b2d29-1469-4d27-bdc0-9d9b6c44b915': [0.54702948141263], // Development (K-R) Z-score
        '491e8645-9273-4ef4-be2d-5d09363ac088': [-0.6], // Chrono vs Bio Age
        '197bbf37-7db5-40c4-9675-0dec23339b8e': ['pre-PHV'], // Growth Phase
        'f7529cf9-ba05-4570-bedd-6213ee3b6ec8': ['174.21-190.22'] // Adult Height Prediction 90% CI
      },
      heightEvolutionData: [
        {
          values: [174.3],
          id: '50e1212d-8bcf-4b37-961f-fbdf3782b73c',
          testDateTime: 1679180400000,
          divValue: 1.8000000000000114,
          divDays: 133,
          growthYear: 4.939849624060181,
          alertLevel: 4
        }
      ],
      weightEvolutionData: [
        {
          values: [60],
          id: 'd8cb6244-4971-467f-9c39-26dd90acba49',
          testDateTime: 1679180400000,
          divValue: 2.8999999999999986,
          divDays: 133,
          growthYear: 7.95864661654135,
          alertLevel: 0
        }
      ]
    },
    {
      person: {
        id: '9e317600-34e3-4d3b-9dd0-ea70488b4b0a',
        firstname: 'Juan',
        lastname: 'Atkins',
        birthdate: '2012-03-21',
        gender: 'male',
        preciseAge: 12
      },
      ageAtTestDate: 12
    },
    {
      person: {
        id: '71ebf595-ba4b-4b18-bf72-f13e3f33c3ba',
        firstname: 'Juan',
        lastname: 'Atkins',
        birthdate: '2012-03-21',
        gender: 'male',
        preciseAge: 12
      },
      ageAtTestDate: 12
    },
    {
      person: {
        id: 'e30ef105-b011-4876-b477-53af6816b23f',
        firstname: 'Alice',
        lastname: 'Smith',
        birthdate: '2008-07-14',
        gender: 'female',
        preciseAge: 16
      },
      ageAtTestDate: 16
    },
    {
      person: {
        id: '4063a344-6dec-430d-8b5e-aecfd21127a7',
        firstname: 'Maria',
        lastname: 'Johnson',
        birthdate: '2010-11-05',
        gender: 'male',
        preciseAge: 13
      },
      ageAtTestDate: 13
    },
    {
      person: {
        id: '1cb0e9dd-06ce-45fd-b438-ca6c45ac7aaf',
        firstname: 'Tom',
        lastname: 'Williams',
        birthdate: '2013-09-12',
        gender: 'female',
        preciseAge: 10
      },
      ageAtTestDate: 10
    },
    {
      person: {
        id: '514a6e05-e1a0-4cba-9016-7bec7a8cde57',
        firstname: 'Sophia',
        lastname: 'Brown',
        birthdate: '2009-01-22',
        gender: 'male',
        preciseAge: 15
      },
      ageAtTestDate: 15
    },
    {
      person: {
        id: 'd612659b-fe15-45a4-b346-ffbf78fc5b1f',
        firstname: 'Liam',
        lastname: 'Jones',
        birthdate: '2014-05-03',
        gender: 'female',
        preciseAge: 10
      },
      ageAtTestDate: 10
    },
    {
      person: {
        id: '115d1262-8590-4f0d-9664-218cf22a48de',
        firstname: 'Olivia',
        lastname: 'Garcia',
        birthdate: '2011-08-30',
        gender: 'male',
        preciseAge: 12
      },
      ageAtTestDate: 12
    },
    {
      person: {
        id: '57187780-1981-4f5f-825a-8256bc173fb5',
        firstname: 'Noah',
        lastname: 'Miller',
        birthdate: '2015-12-18',
        gender: 'female',
        preciseAge: 8
      },
      ageAtTestDate: 8
    },
    {
      person: {
        id: 'b7e278ae-2fe8-45e9-8ce0-78e96e45a202',
        firstname: 'Emma',
        lastname: 'Davis',
        birthdate: '2010-10-07',
        gender: 'male',
        preciseAge: 13
      },
      ageAtTestDate: 13
    },
    {
      person: {
        id: '2d602c87-9d09-4ac7-a5e8-f93554323580',
        firstname: 'James',
        lastname: 'Rodriguez',
        birthdate: '2008-04-16',
        gender: 'female',
        preciseAge: 16
      },
      ageAtTestDate: 16
    },
    {
      person: {
        id: '733bb697-51cb-4b23-802b-3a87a7ed40cf',
        firstname: 'Ava',
        lastname: 'Martinez',
        birthdate: '2012-06-25',
        gender: 'male',
        preciseAge: 12
      },
      ageAtTestDate: 12
    },
    {
      person: {
        id: '699ef627-6c8c-4e7d-a5ae-168f4f466ed7',
        firstname: 'Lucas',
        lastname: 'Hernandez',
        birthdate: '2013-02-19',
        gender: 'female',
        preciseAge: 11
      },
      ageAtTestDate: 11
    },
    {
      person: {
        id: 'db58b3af-325e-43f1-bf17-fe3be1c001f5',
        firstname: 'Mia',
        lastname: 'Lopez',
        birthdate: '2009-12-11',
        gender: 'male',
        preciseAge: 14
      },
      ageAtTestDate: 14
    },
    {
      person: {
        id: 'bf9c837f-aae3-4d4f-b4fe-695a15cf9c20',
        firstname: 'Mason',
        lastname: 'Gonzalez',
        birthdate: '2014-07-28',
        gender: 'female',
        preciseAge: 10
      },
      ageAtTestDate: 10
    },
    {
      person: {
        id: 'e5dbb623-746f-4832-a3a4-081f6eabf2d3',
        firstname: 'Isabella',
        lastname: 'Wilson',
        birthdate: '2008-03-30',
        gender: 'male',
        preciseAge: 16
      },
      ageAtTestDate: 16
    },
    {
      person: {
        id: 'ec798deb-c365-4845-b72e-ae127157a309',
        firstname: 'Ethan',
        lastname: 'Anderson',
        birthdate: '2011-01-05',
        gender: 'female',
        preciseAge: 13
      },
      ageAtTestDate: 13
    },
    {
      person: {
        id: '9c4d9388-3604-4204-9fcd-daa33bdda718',
        firstname: 'Charlotte',
        lastname: 'Thomas',
        birthdate: '2013-10-22',
        gender: 'male',
        preciseAge: 10
      },
      ageAtTestDate: 10
    },
    {
      person: {
        id: 'fd30d14a-9c94-4f6e-945f-bc98131b96ef',
        firstname: 'Logan',
        lastname: 'Taylor',
        birthdate: '2010-02-15',
        gender: 'female',
        preciseAge: 14
      },
      ageAtTestDate: 14
    },
    {
      person: {
        id: '4377fe2f-b5f5-4263-96c6-a6228f1477d2',
        firstname: 'Amelia',
        lastname: 'Moore',
        birthdate: '2015-08-07',
        gender: 'male',
        preciseAge: 9
      },
      ageAtTestDate: 9
    },
    {
      person: {
        id: '4ae1a4d7-1e53-4e77-8ca1-f6c560bbb0bc',
        firstname: 'Henry',
        lastname: 'Jackson',
        birthdate: '2009-09-27',
        gender: 'female',
        preciseAge: 14
      },
      ageAtTestDate: 14
    }
  ];
   */
  const getPAHLevel = growthPhase => {
    switch (true) {
      case growthPhase.toLowerCase().indexOf('pre') >= 0:
        return 3;

      case growthPhase.toLowerCase().indexOf('start') >= 0:
        return 1;

      case growthPhase.toLowerCase().indexOf('circa') >= 0:
        return 0;

      case growthPhase.toLowerCase().indexOf('post') >= 0:
        return 4;

      default:
        return 2;
    }
  };

  const getValue = testItemValues => {
    return testItemValues && Object.keys(testItemValues).length > 0
      ? testItemValues[0]
      : false;
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchGrowthDataByGroup({
        variables: {
          entityId: groupId
        },
        fetchPolicy: 'network-only'
      });

      if (data?.getGrowthDataOfGroup2) {
        const growthData = data.getGrowthDataOfGroup2;

        // console.log('growthpanel', growthData);

        usersArray.current = growthData.map(d => {
          return {
            id: d.person.id,
            label: `${d.person.firstname} ${d.person.lastname}`,
            alertOldData:
              d.heightEvolutionData.length > 0 &&
              (differenceInWeeks(
                new Date(),
                new Date(
                  d.heightEvolutionData[
                    d.heightEvolutionData.length - 1
                  ].testDateTime
                )
              ) > 10 ||
                differenceInWeeks(
                  new Date(),
                  new Date(
                    d.weightEvolutionData[
                      d.weightEvolutionData.length - 1
                    ].testDateTime
                  )
                ) > 10)
          };
        });

        usersArray.current = sort(usersArray.current, {
          keys: [{ key: 'label' }]
        });

        setAthletes(usersArray.current);
        // console.log(usersArray.current);

        setCols(Object.values(GrowthTrackerFullColumns));

        // console.log('growthData', growthData);
        // return an object with all data rows with the user id as a key
        setRows(
          // @ts-ignore
          growthData.reduce((acc, d) => {
            acc[d.person.id] = {
              preciseAge: {
                value: d.preciseAge ? d.preciseAge : false,
                unit: 'yearShort'
              },
              bodyHeight: {
                value: getValue(
                  d.testItemValues['19688d86-a4e8-4cfb-966d-69bdf035f6ab']
                ),
                unit: 'cm'
              },
              bodyWeight: {
                value: getValue(
                  d.testItemValues['a2a243bc-8d3e-4eb8-9837-9a3bb814488d']
                ),
                unit: 'kg'
              },
              adultHeightPrediction: {
                value: getValue(
                  d.testItemValues['5737716d-7eb3-4724-85d2-7baa4652ae93']
                ),
                unit: 'cm'
              },
              pah90Ci: {
                value: getValue(
                  d.testItemValues['f7529cf9-ba05-4570-bedd-6213ee3b6ec8']
                ),
                unit: 'cm'
              },
              predictedAdultHeight: {
                value: getValue(
                  d.testItemValues['face72bd-d260-44a3-b55c-3bae9e3d18dc']
                ),
                unit: '%',
                alertLevel: !!getValue(
                  d.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
                )
                  ? getPAHLevel(
                      d.testItemValues[
                        '197bbf37-7db5-40c4-9675-0dec23339b8e'
                      ][0]
                    )
                  : false,
                iconWarning: !!getValue(
                  d.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
                )
                  ? getPAHLevel(
                      d.testItemValues[
                        '197bbf37-7db5-40c4-9675-0dec23339b8e'
                      ][0]
                    ) <= 1
                  : false
              },
              growthPhase: {
                value: getValue(
                  d.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
                ),
                unit: '',
                alertLevel: !!getValue(
                  d.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
                )
                  ? getPAHLevel(
                      d.testItemValues[
                        '197bbf37-7db5-40c4-9675-0dec23339b8e'
                      ][0]
                    )
                  : false,
                iconWarning: !!getValue(
                  d.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
                )
                  ? getPAHLevel(
                      d.testItemValues[
                        '197bbf37-7db5-40c4-9675-0dec23339b8e'
                      ][0]
                    ) <= 1
                  : false
              },
              developmentZScore: {
                value: [
                  getValue(
                    d.testItemValues['0c58cad6-8a6f-402f-b30e-b00e06871f14']
                  ),
                  !!getValue(
                    d.testItemValues['105b2d29-1469-4d27-bdc0-9d9b6c44b915']
                  )
                    ? getValue(
                        d.testItemValues['105b2d29-1469-4d27-bdc0-9d9b6c44b915']
                      ).toFixed(2)
                    : false
                ],

                unit: ''
              },
              heightVelocity: {
                value:
                  d.heightEvolutionData &&
                  d.heightEvolutionData.length > 0 &&
                  d.heightEvolutionData[d.heightEvolutionData.length - 1]
                    .growthYear,
                unit: 'cmYearShort',
                alert:
                  d.heightEvolutionData &&
                  d.heightEvolutionData.length > 0 &&
                  differenceInWeeks(
                    new Date(),
                    new Date(
                      d.heightEvolutionData[
                        d.heightEvolutionData.length - 1
                      ].testDateTime
                    )
                  ) > 10,
                alertLevel:
                  d.heightEvolutionData &&
                  d.heightEvolutionData.length > 0 &&
                  d.heightEvolutionData[d.heightEvolutionData.length - 1]
                    .alertLevel,
                iconWarning:
                  d.heightEvolutionData &&
                  d.heightEvolutionData.length > 0 &&
                  d.heightEvolutionData[d.heightEvolutionData.length - 1]
                    .alertLevel < 2
              },
              weightVelocity: {
                value:
                  d.weightEvolutionData &&
                  d.weightEvolutionData.length > 0 &&
                  d.weightEvolutionData[d.weightEvolutionData.length - 1]
                    .growthYear,
                unit: 'kgYearShort',
                alert:
                  d.weightEvolutionData &&
                  d.weightEvolutionData.length > 0 &&
                  differenceInWeeks(
                    new Date(),
                    new Date(
                      d.weightEvolutionData[
                        d.weightEvolutionData.length - 1
                      ].testDateTime
                    )
                  ) > 10,
                alertLevel:
                  d.weightEvolutionData &&
                  d.weightEvolutionData.length > 0 &&
                  d.weightEvolutionData[d.weightEvolutionData.length - 1]
                    .alertLevel,
                iconWarning:
                  d.weightEvolutionData &&
                  d.weightEvolutionData.length > 0 &&
                  d.weightEvolutionData[d.weightEvolutionData.length - 1]
                    .alertLevel < 2
              },
              bioAge: {
                value: getValue(
                  d.testItemValues['5737716d-7eb3-4724-85d2-7baa4652XXXX']
                ),
                unit: 'yearShort'
              },
              chronoVsBio: {
                value: getValue(
                  d.testItemValues['491e8645-9273-4ef4-be2d-5d09363ac088']
                ),
                unit: 'yearLong'
              }
            };
            return acc;
          }, {})
        );
      }
    };

    if (groupId) {
      fetchData().catch(error => {
        Sentry.captureException(error);
      });
    }
  }, [groupId, location]);

  useEffect(() => {
    if (athletes.length > 0) {
      updateRowData({
        testCols: selectedHeaders,
        sortIndex
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [athletes.length, testItems, JSON.stringify(selectedHeaders), sortIndex]);

  const onChangeMethod = method => {
    setMethod(method);
  };

  const onChangeTestSet = testSetId => {
    if (!testSetId) {
      setTestItems(testItemsArray.current);
      return;
    }

    const filteredTestItems = [
      ...testItemsArray.current.filter(ti => ti.testSetIds.includes(testSetId))
    ];

    if (!filteredTestItems.length) {
      resetSelectedHeaders();
    }
    const newTestCols = selectedHeaders.map(col => {
      const testItem = filteredTestItems.find(ti => ti.id === col.id);
      if (!testItem || !testItem?.testSetIds.includes(testSetId)) {
        return { id: null, sortDirection: SORT_TYPES.DOWN };
      } else {
        return col;
      }
    });
    setSelectedHeaders([...newTestCols]);
    setTestItems(filteredTestItems);
  };

  const onChangeAthleteCol = athletes => {
    if (athletes.length > 0) {
      const filteredAthletes = [...usersArray.current].filter(u =>
        athletes.find(a => a.value === u.id)
      );
      setAthletes(filteredAthletes);
    } else {
      setAthletes([...usersArray.current]);
    }
  };

  const updateRowData = ({ testCols, type, sortIndex }) => {
    /*const rows = getDataRows({
      athletes,
      testCols
    });*/
    /*const allRows = getDataRows({
      athletes: usersArray.current,
      testCols
    });

    setRows(rows);*/
    /*if (sortIndex === -1 || !testCols?.[sortIndex]?.id) {
      setAthletes(
        sort([...athletes], {
          keys: [{ key: 'label', desc: athletesSortDirection }]
        })
      );
    } else {
      const sortedRows = sortResultRowsOnCol(
        rows,
        sortIndex,
        testCols?.[sortIndex],
        type
      );
      setAthletes(sortByArray(athletes, Object.keys(sortedRows)));
    }*/
  };

  const onClickCell = (
    colIndex,
    user,
    testItem,
    laterality,
    average = null
  ) => {
    const sessionGrowth = user.growth?.[testItem.id];
    let data = [];

    const values = sessionGrowth?.reduce(
      (item, session) => {
        const percentage = session?.benchmarked?.[laterality]?.percentage;
        const result = session?.result?.[laterality]?.[0];

        if (result) {
          if (average) {
            item.avg = average;
          }

          if (result < item.min) item.min = Number(result);
          if (result > item.max) item.max = Number(result);

          if (session.testDate || session.sessionDate) {
            const date = session.testDate
              ? format(session.testDate, 'dd/LL/yyyy')
              : format(session.sessionDate, 'dd/LL/yyyy');
            const sortDate = session.testDate
              ? format(session.testDate, 'yyyy-LL-dd')
              : format(session.sessionDate, 'yyyy-LL-dd');

            //
            // testSessionId: ID
            // const url = `sessions/${rootEntityId}/${testSessionId}/sporter/${user.id}`;

            data.push({
              x: `${sortDate}`,
              y: testItem.input_type === 'select_with_options' ? 0 : result,
              result,
              date: date,
              sortDate,
              unit: session.unit,
              percentile: percentage,
              inputType: testItem.input_type
              //   url: url
            });
          }
        }
        return item;
      },
      {
        min: 0,
        max: 0,
        avg: null
      }
    );

    data = sort(data, {
      keys: [{ key: 'sortDate', dataType: SORT_DATA_TYPES.DATE_STRING }]
    });

    /* Under normal circumstances we will never take 2 equal test on the same
     ** day and the use both of them. So we'll remove the first test
     ** for now: */
    data = data.filter((value, index, array) => {
      return (
        array.findLastIndex(value2 => {
          return value2.date === value.date;
        }) === index
      );
    });

    if (data.length === 0) return;

    const dataObj = {
      title: user.label,
      subTitle: testItem.title,
      laterality,
      chartData: {
        ...values,
        data: [
          {
            id: testItem.id,
            data: [...data]
          }
        ]
      }
    };

    setSidePanelData(dataObj);
  };

  const onCloseSidePanel = () => setSidePanelData(null);

  const onSort = (sortIndex, sortDirection) => {
    const newTestCols = [...selectedHeaders];
    newTestCols[sortIndex].sortDirection = sortDirection;

    setSelectedHeaders([...newTestCols]);
    setSortIndex(sortIndex);

    updateRowData({ testCols: selectedHeaders, sortIndex });
  };

  const onSortAthletes = () => {
    const newAthletes = sort([...athletes], {
      keys: [{ key: 'label', desc: !athletesSortDirection }]
    });

    if (sortIndex > -1) {
      selectedHeaders[sortIndex].sortDirection = SORT_TYPES.DOWN;
      setSortIndex(-1);
    }
    setSelectedHeaders([...selectedHeaders]);
    setAthletesSortDirection(!athletesSortDirection);
    setAthletes(newAthletes);
  };

  return (
    <GrowthPanelContext.Provider
      value={{
        cols,
        rows,
        athletes,
        usersArray,
        testItems,
        testSets,
        onChangeAthleteCol,
        onClickCell,
        onChangeTestSet,
        sidePanelData,
        onCloseSidePanel,
        onSort,
        onSortAthletes,
        sortIndex,
        athletesSortDirection,
        error,
        method,
        onChangeMethod
      }}
    >
      {children}
    </GrowthPanelContext.Provider>
  );
};

function useGrowthPanelContext() {
  const context = useContext(GrowthPanelContext);
  if (context === undefined) {
    throw new Error(
      'The GrowthPanelContext hook must be used within a GrowthPanelContext.Provider'
    );
  }
  return context;
}

export { GrowthPanelContextProvider, useGrowthPanelContext };
